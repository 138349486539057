export default {
  // 作业方式
  calcCardImg (item) {
    const orderType = item.orderType || ''
    // ordernature==4 显示升级的图片
    if (item.orderNature === 4) {
      return require('@/asset/orderIcon/icon_task-upgrade.png')
    }
    switch (orderType) {
      // 公区-巡检 蓝色图标
      case 8:
        return require('@/asset/orderIcon/icon_xunjian task.png')
      // 公区-保养  绿色图标
      case 10:
        return require('@/asset/orderIcon/icon_baoyang task.png')
      // 设备工单
      case 3:
      case 6:
        return require('@/asset/orderIcon/icon_task-problem.png')
      // 公区工单
      case 1:
      case 4:
        return require('@/asset/orderIcon/icon_gongqu task.png')
      default:
        return ''
    }
  }
}
