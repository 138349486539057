/* 执行工单处理 */
import {
  getOrderCacheOfflineApiHandler,
  saveOrderCacheByNewDetail,
  saveOrderCacheOfflineApiHandler
} from '@/plugins/offlineMixin/offlineApiHandlers/OrderInfoOfflineApiHandlers'
import {
  getAllOfflineImgKeysInPatroItem
} from '@/plugins/offlineMixin/onlineSubmitDataWrappers/OrderSubmitWrapper'
import store from '@/store'
import moment from 'moment'
import {
  getOssParams, tryUploadImgByAppOrH5
} from '@/plugins/offlineMixin/utils/offlineImgTool'
import http from '@/api/base/index'
import api from '@/api/index'
import { Toast } from 'vant'
import { OFFLINE_REQUEST_SUCCESS } from '@/plugins/offlineMixin/utils/StaticConstantCode'
import { cloneDeep } from 'lodash'
import mobile from '@/utils/mobile'
/**
 *
 * @param {*} params
 */
const setPatroItemOfflineApiHandler = async ({ params }) => {
  return new Promise(async (resolve, reject) => {
    const { patroItem, orderDetail } = params
    console.log('setPatroItemOfflineApiHandler patroItem, orderDetail: ', patroItem, orderDetail)
    const order_cache = await getOrderCacheOfflineApiHandler({
      params: { id: orderDetail.model.id }
    })
    patroItem.temporaryStorage = 1
    if (patroItem.offlineSubmit === 1) { // 完成，非暂存
      order_cache.isToBeUpload = 1
      delete patroItem.temporaryStorage
      const { route_list } = order_cache.orderData.detail.model
      console.log('route_list: ', route_list)
      order_cache.orderData.detail.buttonInfo = route_list.find(i => i.buttonType === 2 && i.curTemplateNodeCode === 'chulizhong') || {
        buttonName: '完成'
      }
      console.log('order_cache.orderData.detail: ', order_cache.orderData.detail)
    }
    const detail = order_cache.orderData.detail
    const targetPatroIdx = detail.orderTaskItemExtModelList.findIndex(i => i.itemId === patroItem.itemId)
    detail.orderTaskItemExtModelList[targetPatroIdx] = patroItem
    await saveOrderCacheOfflineApiHandler({ params: order_cache })
    resolve({ code: OFFLINE_REQUEST_SUCCESS })
  })
}

// 按照后台要求组装数据
const setProblemItem = async ({ params }) => {
  let obj = params
  console.log('obj: ', cloneDeep(obj))
  let { orderDetail, patroItem, problemIndex, problemBackPhone } = obj
  let problemItem = patroItem.options[problemIndex]
  let nanoidRandom = Math.ceil(Math.random() * 10000)
  // 如果有pinkongProblorderDetailProblemIdemReq这个字段就往字段里面push
  let fmProblemList
  if (obj.orderType === 3) {
    fmProblemList = _fmWrapProblemList(problemItem, obj.problemList, nanoidRandom, obj.grade)
  }
  console.log('fmProblemList: ', fmProblemList)
  const newProblemRecord = {
    itemId: patroItem.itemId,
    checkItemId: problemItem.code,
    checkItemName: problemItem.name,
    addressId: obj.addressId,
    addressType: obj.reallyType,
    address: obj.address,
    remark: obj.remark,
    problemList: fmProblemList || obj.problemList,
    attachmentList: obj.attachmentList,
    createTime: moment().format('YYYY-MM-DD HH:mm:ss'),
    id: nanoidRandom,
    profession: obj.profession,
    grade: obj.grade
  }
  // 添加要关闭的字段
  if (obj.closeOrderNos && obj.closeOrderNos.length > 0) {
    newProblemRecord.close = true
    newProblemRecord.closeOrderNos = obj.closeOrderNos
  }
  if (orderDetail.pinkongProblemReq) {
    orderDetail.pinkongProblemReq.problemRecordList.push(newProblemRecord)
  } else {
    // 否则就添加这个字段数据
    let newObj = {}
    newObj.projectCode = obj.projectCode
    newObj.projectName = obj.projectName
    newObj.orderType = obj.orderType
    newObj.dataSource = orderDetail.model.data_source
    newObj.relationOrderNo = obj.relationOrderNo
    newObj.problemRecordList = []
    newObj.problemRecordList.push(newProblemRecord)
    orderDetail.pinkongProblemReq = newObj
  }
  if (store.getters.getIsCJ) orderDetail.pinkongProblemReq.orderNature = 6 // 承接查验模块创建问题工单，需要带上orderNature = 6
  let data = []
  // 本地生成的问题列表数据
  if (obj.orderType === 3) { // 创建自查设施设备的问题工单
    orderDetail.pinkongProblemReq.hasPackage = 1
    data = data.concat(fmProblemList)
  } else if (obj.orderType === 1 || obj.orderType === 17) { // 创建自查公区的问题工单
    obj.problemList.forEach(item => {
      data.push({
        orderNo: '待生成工单编号',
        nodeName: '未提交',
        createName: `${store.getters.getUserInfo.name}(${store.getters.getUserInfo.username})`,
        createTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        processLimit: item.rectifyLimit,
        handlerName: item.rectifyPeopls.name,
        grade: JSON.stringify([JSON.parse(obj.grade)]),
        gradeName: JSON.parse(obj.grade).value,
        checkItemId: problemItem.code,
        remark: `${item.parentName}/${item.problemName}「${item.questLabel.join('|')}」「${obj.remark}」`,
        id: nanoidRandom,
        problemId: item.problemId
      })
    })
  }

  if (problemBackPhone) { // 如果是通过单选异常后创建的问题记录，需要把问题记录的图片导入到对应的检查项中
    patroItem.options[problemIndex].typeOptions.forEach(item => {
      // 如果当前检查项里面配置了图片就回显问题的图片到检查项去
      if (item.type === 7) {
        if (item.val && item.val.length > 0) {
          item.val = [...item.val, ...obj.attachmentList]
        } else {
          item.val = [...obj.attachmentList]
        }
      }
    })
  }
  patroItem.questionList = [...data, ...patroItem.questionList || []]
  const patroItemId = patroItem.itemId
  const targetPatroItemIDX = orderDetail.orderTaskItemExtModelList.findIndex(i => i.itemId === patroItemId)
  console.log('targetPatroItemIDX: ', targetPatroItemIDX)
  orderDetail.orderTaskItemExtModelList[targetPatroItemIDX] = patroItem
  await saveOrderCacheByNewDetail(orderDetail)
}
const _fmWrapProblemList = (problemItem, problemList, nanoidRandom, grade) => {
  let fmProblemList = problemList.reduce((arr, item) => {
    const index = arr.findIndex(fmItem => fmItem.problemId === item.problemId)
    if (index === -1) {
      arr.push({
        orderNo: '待生成工单编号',
        nodeName: '未提交',
        createName: store.getters.getUserInfo.name,
        createTime: moment().format('YYYY-MM-DD HH:mm:ss'),
        processLimit: item.rectifyLimit,
        grade: JSON.stringify([JSON.parse(grade)]),
        gradeName: JSON.parse(grade).value,
        checkItemId: problemItem.code || null,
        mainPartItemReqs: [{
          mainPartCode: item.mainPartCode,
          mainPartName: item.mainPartName,
          questionLabelItemReqs: [{
            questLabelId: item.questLabelId,
            questLabel: item.questLabelName,
            breakDownCauseId: item.breakDownCauseId
          }]
        }],
        remark: [{ mainPartName: item.mainPartName, questLabels: [item.questLabelName] }],
        id: nanoidRandom,
        problemId: item.problemId
      })
    } else {
      const mainPartIndex = arr[index].remark.findIndex(remarkItem => remarkItem.mainPartName === item.mainPartName)
      if (mainPartIndex === -1) {
        arr[index].remark.push({ mainPartName: item.mainPartName, questLabels: [item.questLabelName] })
        arr[index].mainPartItemReqs.push({
          mainPartCode: item.mainPartCode,
          mainPartName: item.mainPartName,
          questionLabelItemReqs: [{
            questLabelId: item.questLabelId,
            questLabel: item.questLabelName,
            breakDownCauseId: item.breakDownCauseId
          }],
          breakDownCauseId: item.breakDownCauseId
        })
      } else {
        arr[index].remark[mainPartIndex].questLabels.push(item.questLabelName)
        arr[index].mainPartItemReqs[mainPartIndex].questionLabelItemReqs.push({
          questLabelId: item.questLabelId,
          questLabel: item.questLabelName,
          breakDownCauseId: item.breakDownCauseId
        })
      }
    }
    return arr
  }, [])

  fmProblemList.forEach(problemItem => { // 将展示的数据转换成字符串显示，仅利用于执行工单页的内容显示
    const remark = problemItem.remark
    let newRemarkLabel = ''
    remark.forEach(mainPartItem => {
      newRemarkLabel += `主要部件「${mainPartItem.mainPartName}」故障原因「`
      newRemarkLabel += mainPartItem.questLabels.join('丨')
      newRemarkLabel += '」'
    })
    problemItem.remark = newRemarkLabel
  })
  return fmProblemList
}
// 显示问题分级数据

/**
 * 删除问题项
 * @param {*} param0
 * @returns
 */
const deleteProblem = async ({ params }) => {
  return new Promise(async (resolve) => {
    const { index, item, orderDetail, orderId } = params
    console.log('deleteProblem index, item: ', index, item)
    console.log('deleteProblem orderId: ', orderId)
    const patroItemId = localStorage.getItem('patroItemId') * 1
    console.log('deleteProblem patroItemId: ', typeof patroItemId, patroItemId)
    console.log('deleteProblem1 orderDetail: ', cloneDeep(orderDetail))
    const allProblemId = []
    if (orderDetail.pinkongProblemReq && orderDetail.pinkongProblemReq.problemRecordList) {
      orderDetail.pinkongProblemReq.problemRecordList.forEach((problemItem, idx) => {
        if (problemItem.id === item.id) {
          problemItem.problemList.forEach((list, zIndex) => {
            if (list.problemId === item.problemId) {
              console.log('找到对应的问题项进行删除', 'idx:', idx)
              orderDetail.pinkongProblemReq.problemRecordList[idx].problemList.splice(zIndex, 1)
              // 如果problemList是空数组，就不要这个problemRecord
              if (orderDetail.pinkongProblemReq.problemRecordList[idx].problemList.length === 0) {
                orderDetail.pinkongProblemReq.problemRecordList.splice(idx, 1)
              }
            } else {
              allProblemId.push(list.problemId)
            }
          })
        }
      })
    }
    // 如果problemRecordList为空数组，就不要这个pinkongProblemReq
    if (orderDetail.pinkongProblemReq.problemRecordList.length === 0) {
      delete orderDetail.pinkongProblemReq
    }
    orderDetail.orderTaskItemExtModelList.forEach((item) => {
      if (item.itemId === patroItemId) {
        item.questionList.splice(index, 1)
      }
    })
    console.log('deleteProblem2 orderDetail: ', cloneDeep(orderDetail))
    await saveOrderCacheByNewDetail(orderDetail)
    resolve()
  })
}
/**
 * 问题项在线提交
 * @param {*} params
 * @returns
 */
const submitProblem = async ({ params }) => {
  return new Promise(async resolve => {
    try {
      const { index, item, orderId } = params
      console.log('index, item: ', index, item)
      const ossParams = await getOssParams()
      console.log('submitProblem orderId: ', orderId)
      const patroItemId = localStorage.getItem('patroItemId') * 1
      console.log('submitProblem patroItemId: ', patroItemId)
      const order_cache = await getOrderCacheOfflineApiHandler({
        params: { id: orderId }
      })
      const detail = order_cache.orderData.detail
      const problemRecordIndex = detail.pinkongProblemReq.problemRecordList.findIndex(problemItem => problemItem.id === item.id)
      const problemRecord = detail.pinkongProblemReq.problemRecordList[problemRecordIndex]
      const recordItemIndex = problemRecord.problemList.findIndex(list => list.problemId === item.problemId)
      const recordItem = problemRecord.problemList[recordItemIndex]
      if (problemRecord.addressType && (problemRecord.addressType * 1 === 1 || problemRecord.addressType * 1 === 4)) {
        recordItem.rectifyPeopls = Object.keys(recordItem.rectifyPeopls).length > 0 ? JSON.stringify(recordItem.rectifyPeopls) : undefined
      }
      if (recordItem.questLabel && Array.isArray(recordItem.questLabel)) {
        recordItem.questLabel = JSON.stringify(recordItem.questLabel)
      } else {
        recordItem.questLabel = JSON.stringify([])
      }
      const attachmentList = problemRecord.attachmentList
      const form = {
        ...detail.pinkongProblemReq,
        problemRecordList: [
          { ...problemRecord, problemList: [recordItem], attachmentList: [...attachmentList] }
        ]
      }
      let imgList = []
      let imgUploadTost = Toast.loading({
        message: '上传图片中...',
        forbidClick: true
      })
      for (let imgKey of form.problemRecordList[0].attachmentList) {
        const res = await tryUploadImgByAppOrH5(ossParams, imgKey)
        imgList.push(res)
      }
      form.problemRecordList[0].attachmentList = imgList
      if (store.getters.getIsCJ) form.orderNature = 6 // 承接查验模块创建问题工单，需要带上orderNature = 6
      imgUploadTost.clear()
      console.log('submitProblem form: ', form)
      const res = await http.post(api.pinkongProblemCreate, form)
      const orderNo = res[0].orderNos[0]
      Toast.success('添加成功')
      detail.orderTaskItemExtModelList.forEach((item) => {
        if (item.itemId === patroItemId) {
          item.questionList[index].orderNo = orderNo
          item.questionList[index].template_node_code = '待处理'
          item.questionList[index].nodeName = '已提交'
        }
      })
      problemRecord.problemList.splice(recordItemIndex, 1)
      if (problemRecord.problemList.length === 0) {
        detail.pinkongProblemReq.problemRecordList.splice(problemRecordIndex, 1)
      }
      if (detail.pinkongProblemReq.problemRecordList.length === 0) {
        delete detail.pinkongProblemReq
      }
      await saveOrderCacheOfflineApiHandler({ params: order_cache })
      resolve()
    } catch (error) {
      console.log('submitProblem error: ', error)
    }
  })
}

const handleOrderAfterServerSubmitTaskHandler = async ({ params }) => {
  return new Promise(async resolve => {
    Toast.clear()
    const { orderId, patroItemId } = params
    console.log('orderId, patroItemId: ', orderId, patroItemId)
    const order_cache = await getOrderCacheOfflineApiHandler({
      params: { id: orderId }
    })
    const detail = order_cache.orderData.detail
    console.log('detail: ', detail)
    // 1' 删除任务项和问题记录里的图片
    const totalOfflineImgKeys = getAllOfflineImgKeysInPatroItem(detail, patroItemId)
    console.log('totalOfflineImgKeys: ', totalOfflineImgKeys)
    await mobile.deleteImgsInCache(totalOfflineImgKeys)
    // 2' 删除任务记录 给标志
    for (const taskItem of detail.orderTaskItemExtModelList) {
      if (taskItem.itemId === patroItemId) taskItem.isServerSubmited = 1
    }
    // 3' 删除任务记录
    if (detail.pinkongProblemReq && detail.pinkongProblemReq.problemRecordList) {
      detail.pinkongProblemReq.problemRecordList = detail.pinkongProblemReq.problemRecordList.filter(i => {
        return i.itemId !== patroItemId
      })
    }
    console.log('detail: ', detail)
    await saveOrderCacheOfflineApiHandler({ params: order_cache })
    resolve('ok')
  })
}

export {
  setPatroItemOfflineApiHandler,
  setProblemItem,
  deleteProblem,
  submitProblem,
  _fmWrapProblemList,
  handleOrderAfterServerSubmitTaskHandler
}
