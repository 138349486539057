// 扫码
import api from '@/api'
import mobile from '@/utils/mobile'
import store from '@/store'
import { apiOffline } from '@/plugins/offlineMixin/apiOfflineGetway'
import { getAllScanCodeData } from '@/plugins/offlineMixin/offlineApiHandlers/scanCodeOfflineProcessingHandlers'
const scanConfig = {
  'SB_code': { // 设备编码类型
    types: ['2', '3']
  },
  'DW_code': { // 点位编码类型
    types: ['1']
  }
}

export default {
  jump: async function (callback, projectCodes, codeType = 'SB_code') {
    const code = await mobile.getQRCodeInfo()
    const obj = {
      types: scanConfig[codeType].types,
      qrCode: code,
      isEnterFM: store.getters.getIsFM || store.getters.getIsZG ? 1 : 0
    }
    projectCodes ? obj.projectCodes = [projectCodes] : obj.projectCodes = []
    apiOffline({
      methods: 'post',
      api: api.getScanQrCode,
      params: obj,
      offlineApiHandlers: getAllScanCodeData
    }).then(res => {
      callback(res)
    })
  }
}
