import mobile from '@/utils/mobile'
import store from '@/store'
import moment from 'moment'

import {
  mergeServerOrderAndCacheOrder
} from '@/plugins/offlineMixin/utils/offlineUtil'
import { cloneDeep } from 'lodash'
import { Notify, Toast } from 'vant'
import { UPDATE_RESET_FIELDS_BY_MODEL } from '@/plugins/offlineMixin/utils/StaticConstantCode'

/**
 * -------工单 取----------
 * 获取APP缓存工单数据 输出APP数据库的工单数据 {orderId:6789,orderData:`...`}
 * @param {*} id 工单id
 * @returns
 */

const getOrderCacheOfflineApiHandler = async ({ params }) => {
  return new Promise(async resolve => {
    let { id } = params
    typeof id === 'string' && (id = parseInt(id))
    const order_cache = await mobile.getOrder({
      'orderId': id * 1
    })
    if (order_cache && order_cache.orderData && typeof order_cache.orderData === 'string') {
      order_cache.orderData = JSON.parse(order_cache.orderData)
    }
    if (order_cache && order_cache.orderDataModel && typeof order_cache.orderDataModel === 'string') {
      order_cache.orderDataModel = JSON.parse(order_cache.orderDataModel)
    }
    if (order_cache && order_cache.standardData && typeof order_cache.standardData === 'string') {
      order_cache.standardData = JSON.parse(order_cache.standardData)
    }
    resolve(order_cache || null)
  })
}
/**
 * -------工单 取detail----------
 * @param {*} param0
 * @returns
 */
const getOrderDetailOfflineApiHandler = async ({ params }) => {
  return new Promise(async resolve => {
    let { id } = params
    const order_cache = await getOrderCacheOfflineApiHandler({
      params: { id }
    })
    if (order_cache.orderData && order_cache.orderData.detail) {
      resolve(order_cache.orderData.detail)
    } else {
      resolve(null)
    }
  })
}

/**
 * -------工单 存----------
 * 保存order_cache到APP
 * @param {*} param0
 * @returns
 */
const saveOrderCacheOfflineApiHandler = async ({ params: order_cache }) => {
  return new Promise(async resolve => {
    // 将需要序列化的字段统一在这里序列化
    console.log('order_cache_clone: ', order_cache)
    const order_cache_clone = cloneDeep(order_cache)
    const { orderData, standardData } = order_cache_clone
    // 存储orderDataModel
    order_cache_clone.orderDataModel = JSON.stringify(getOrderDataModel(orderData) || undefined)
    if (orderData && typeof orderData === 'object') { // orderData传json字符串
      order_cache_clone.orderData = JSON.stringify(orderData)
    }
    if (standardData && typeof standardData === 'object') { // standradData传json字符串
      order_cache_clone.standardData = JSON.stringify(standardData)
    }
    await mobile.saveOrder(order_cache_clone)
    resolve('ok')
  })
}
/**
 * 保存工单（只对工单详情 detail 更改）
 * @param {*} detail 工单详情 detail {model:{...},...}
 */

const saveOrderCacheByNewDetail = async (detail) => {
  const order_cache = await getOrderCacheOfflineApiHandler({
    params: { id: detail.model.id }
  })
  order_cache.orderData.detail = detail
  await saveOrderCacheOfflineApiHandler({ params: order_cache })
}

/**
 * 删除APP缓存工单数据
 * -------工单 删----------
 * @param {*} param0
 * @returns
 */
const deleteOrderCacheOfflineApiHandler = async ({ params: orderIds }) => {
  return new Promise(async resolve => {
    console.log('删除工单 orderIds: ', orderIds)
    for (let id of orderIds) {
      typeof id === 'string' && (id = parseInt(id))
    }
    await mobile.deleteOrder({ 'orderIds': orderIds })
    const order_cache = await getOrderCacheOfflineApiHandler({
      params: { id: orderIds[0] }
    })
    console.log('删除工单后拿order_cache: ', orderIds[0], order_cache)
    resolve('ok')
  })
}
/*
  进入工单详情时，需要合并
  detail : {model:{...},{...}}
*/
const saveServerOrderDetailOfflineApiHandler = async ({ params: detail }) => {
  return new Promise(async resolve => {
    console.log('>>>>>>在线时合并在线工单和待上传工单/离线列表工单')
    console.log('服务器工单: ', cloneDeep(detail))
    await saveOrderCacheAfterMergeHandler(detail.model.id, { detail: detail })
    resolve('ok')
  })
}

/**
  * 新的工单和缓存工单合并***
    根据id取工单，没取到说明APP没有工单缓存，直接存一份
    取到的话，进行合并，再保存一份
    isDownload 0/1 是否是下载的离线包里的工单
    isToBeUpload 0/1 是否是待上传
    buttonInfo:`{}` 待上传需要的按钮信息 来源routeList
 * @param {*} orderId
 * @param {*} newOrderDetail  来源1、 进工单{detail:{model:...}}  2、离线包 {....,detail:{model:...},...}
 * @param {*} orderAttr
 */
async function saveOrderCacheAfterMergeHandler (orderId, newOrderDetail, orderAttr = {}) {
  const orderAttribute = {
    standardId: null, // 标准id 普通工单没有
    standardData: null, // 标准数据 普通工单没有
    isDownload: 0, // 是否是离线包的
    isToBeUpload: 0, // 是否是待上传,
    buttonInfo: null
  }
  // const pageTypeStr = store.getters.getPageType + ''
  const order_cache = await getOrderCacheOfflineApiHandler({
    params: { id: orderId }
  })
  console.log('order_cache: ', cloneDeep(order_cache))
  if (order_cache && order_cache.orderData) {
    // 合并detail 工单详情的字段
    mergeServerOrderAndCacheOrder(order_cache.orderData.detail, newOrderDetail.detail)
    // 也要更新与detail同级信息存储的信息，不然会导致首页显示不准确
    updateDataServerOrderAndCacheOrder(order_cache.orderData, newOrderDetail)
    // 处理pageType 如果当前pageType不在已有pageType里面就加入
    if (order_cache.pageType.indexOf(createPageTypeNote(store.getters.getPageType)) < 0) {
      console.log('不在当前的pageType', order_cache)
      order_cache.pageType += createPageTypeNote(store.getters.getPageType)
    }

    // 如果有多个pageType 则传给APP的是 'T2,T3,'
    Object.assign(order_cache, orderAttr)
    await saveOrderCacheOfflineApiHandler({ params: order_cache })
  } else {
    // 是新需要存进缓存的工单
    const newOrderCache = {
      orderId: orderId,
      orderData: newOrderDetail,
      pageType: createPageTypeNote(store.getters.getPageType),
      ...Object.assign(orderAttribute, orderAttr)
    }
    await saveOrderCacheOfflineApiHandler({ params: newOrderCache })
  }
}
// 创建pageType标识符，用于app匹配
const createPageTypeNote = (pageType) => {
  return `T${pageType},`
}
// 获取字段展示另存一份
const getOrderDataModel = (orderData) => {
  const orderDataModel = cloneDeep(orderData)
  orderDataModel.detail = {
    'model': {
      ...orderData.detail.model
    }
  }
  return orderDataModel
}

/**
 * 更新工单信息 , 与detail同级别
 * @param {*} orderData
 * @param {*} detail
 */
const updateDataServerOrderAndCacheOrder = (orderData, newOrderDetail) => {
  if (orderData && newOrderDetail) {
    const orderDataKeys = Object.keys(newOrderDetail)
    for (const dataKey of orderDataKeys) {
      if (dataKey !== 'detail') {
        orderData[dataKey] = newOrderDetail[dataKey]
      }
    }
    if (newOrderDetail.detail.model) {
      const updateKeys = Object.keys(UPDATE_RESET_FIELDS_BY_MODEL)
      for (const field of updateKeys) {
        const updateValue = newOrderDetail.detail.model[UPDATE_RESET_FIELDS_BY_MODEL[field]]
        updateValue && (orderData[field] = updateValue)
      }
    }
  }
}

/*
  获取首页下载的离线包-当前模块下的--所有的工单列表
  @params: pageType 页面类型
 */
const getOrderListOfflineApiHandler = async () => {
  return new Promise(async resolve => {
    const toast = Toast.loading({
      message: '正在获取离线工单列表...',
      forbidClick: true,
      duration: 0
    })
    try {
      // 请求的参数
      const listParams = {
        pageType: createPageTypeNote(store.getters.getPageType), // 哪个模块
        isDownload: 1, // 离线包
        isToBeUpload: -1,
        page: 0,
        limit: 10
      }
      let orderListAll = []
      let orderListPart = []
      do {
        orderListPart = await mobile.showOrderList(listParams)
        ++listParams.page
        orderListAll = orderListAll.concat(orderListPart)
      } while (orderListPart && orderListPart.length > 0)
      console.log('>>>>>>>>>从APP取的orderListAll', cloneDeep(orderListAll))
      if (orderListAll && orderListAll.length > 0) {
        const transToJsonOrderList = transFieldToJson(orderListAll)
        console.log('将orderData和standradData反序列化: ', cloneDeep(transToJsonOrderList))
        const filterOrderList = await deleteInvalidOrdersFromOrderList(transToJsonOrderList)
        console.log('删掉已经过了计划结束时间的工单', cloneDeep(filterOrderList))
        // 将所有工单根据standradId 组装成标准工单树结构
        // const formatOrderList = await formatOrderListByStandrad(filterOrderList)
        // console.log('formatOrderList: ', cloneDeep(formatOrderList))
        resolve(filterOrderList)
      } else {
        // 缓存数据为空一定要返回空，才能在弱网的时候走离线
        resolve([])
      }
    } catch (error) {
      console.error('getOrderListOfflineApiHandler', error)
    } finally {
      toast.clear()
    }
  })
}

/**
 * 工单列表 反序列化
 * @param {*} orderListAll
 * @returns
 */
const transFieldToJson = (orderListAll) => {
  for (const order_cache of orderListAll) {
    // if (order_cache.orderData && typeof order_cache.orderData === 'string') {
    //   order_cache.orderData = JSON.parse(order_cache.orderData)
    // }
    if (order_cache.orderDataModel && typeof order_cache.orderDataModel === 'string') {
      order_cache.orderData = JSON.parse(order_cache.orderDataModel)
      delete order_cache.orderDataModel
    }
    if (order_cache.standardData && typeof order_cache.standardData === 'string') {
      order_cache.standardData = JSON.parse(order_cache.standardData)
    }
  }
  return orderListAll
}

/*
  根据orderList每项的standradId的组装成标准树工单结构
*/
const formatOrderListByStandrad = (orderListAll) => {
  return new Promise(async resolve => {
    const formatOrderList = orderListAll.reduce((arr, order_cache) => {
      if (order_cache.standardId && order_cache.standardData) { // 是否是标准工单
        const targetStandrad = arr.find(i => (i.standardId * 1 === order_cache.standardId * 1) && (i.projectCode === order_cache.standardData.projectCode)) // 标准id和项目code要对得上
        if (targetStandrad) {
          targetStandrad.orders.push(order_cache.orderData)
          targetStandrad.taskNum++
          order_cache.orderData.ismature && targetStandrad.matureNum++
          // 用于首页排序, 选出orders里面最早开始时间的赋值给standrad
          if (moment(order_cache.orderData.planStart).isBefore(targetStandrad.planStart)) {
            targetStandrad.planStart = order_cache.orderData.planStart
            targetStandrad.planEnd = order_cache.orderData.planEnd
          } else if (moment(order_cache.orderData.planStart).isSame(targetStandrad.planStart) && moment(order_cache.orderData.planEnd).isBefore(targetStandrad.planEnd)) {
            targetStandrad.planStart = order_cache.orderData.planStart
            targetStandrad.planEnd = order_cache.orderData.planEnd
          }
        } else {
          const standrad = {
            ...order_cache.standardData,
            taskNum: 0,
            matureNum: 0,
            orders: [order_cache.orderData],
            planStart: order_cache.orderData.planStart, // 用于首页排序
            planEnd: order_cache.orderData.planEnd // 用于首页排序
          }
          standrad.taskNum++
          order_cache.orderData.ismature && standrad.matureNum++
          arr.push(standrad)
        }
      } else {
        order_cache.orderData.planStart = order_cache.orderData.createTime
        order_cache.orderData.planEnd = order_cache.orderData.planEndTime
        arr.push(order_cache.orderData)
      }
      return arr
    }, [])
    // const orderCacheListAfterSort = sortOrderCacheListByPlanTime(formatOrderList)
    // 外部排序
    formatOrderList.sort(function (a, b) {
      const a_start = moment(a.planStart).valueOf()
      const b_start = moment(b.planStart).valueOf()
      const a_end = moment(a.planEnd).valueOf()
      const b_end = moment(b.planEnd).valueOf()
      return a_start !== b_start ? a_start - b_start : a_end - b_end
    })
    // 任务工单内部排序
    for (const order of formatOrderList) {
      if (order.standardId && order.orders.length > 0) {
        order.orders.sort(function (a, b) {
          const planStartCut = moment(a.planStart).valueOf() - moment(b.planStart).valueOf()
          if (planStartCut !== 0) {
            return planStartCut
          } else {
            return moment(a.planEnd).valueOf() - moment(b.planEnd).valueOf()
          }
        })
      }
    }
    resolve(formatOrderList)
  })
}
/*
  遍历全部工单，删除过期工单
  删除掉不在计划处理日期之内的工单
  并且过滤掉处理人不是当前用户的工单(不删除数据库数据)
*/
const deleteInvalidOrdersFromOrderList = (orderListAll) => {
  return new Promise(async (resolve, reject) => {
    const name = store.getters.getUserInfo.name
    console.log('筛选时name: ', name)
    const validOrderCacheList = []
    for (const order_cache of orderListAll) {
      if (
        store.getters.getOrderTypeConf.taskOrderTypes.includes(order_cache.orderData.detail.model.order_type) &&
        (moment().isAfter(order_cache.orderData.detail.model.plan_end, 'day') || moment().isBefore(order_cache.orderData.detail.model.plan_start, 'day'))
      ) {
        console.log('有过期或者未到计划开始的任务工单，删除', order_cache)
        await deleteOrderCacheOfflineApiHandler({ params: [order_cache.orderId] })
      } else {
        const handler_name = order_cache.orderData.detail.model.handler_name
        if (handler_name && (handler_name.indexOf(name) > -1)) {
          validOrderCacheList.push(order_cache)
        }
      }
    }
    resolve(validOrderCacheList)
  })
}
/*
  下载离线工单数据包后单个单个保存到app本地数据库中 √
*/
const saveOrderAfterDownloadOrderPackageHandler = async ({ params }) => {
  const orderList = params
  console.log('下载的离线包 存储前: ', cloneDeep(orderList))
  // 如果没有可处理的工单，直接删除APP缓存
  if (!orderList || orderList.length === 0) {
    Notify({ type: 'warning', message: '离线包为空' })
    return
  }

  /*
    遍历orderList：有id的是普通工单 没有id的是任务工单
  */
  const allOrderIds = []
  for (const item of orderList) {
    if (item.id) { // 普通工单直接保存
      await saveOrderCacheAfterMergeHandler(item.id, item, {
        isDownload: 1
      })
      allOrderIds.push(item.id)
    } else if (item.orders && item.orders.length > 0) {
      const { orders, ...standardData } = item
      for (const order of orders) {
        // 如果是FM工单,把tasklist的qrcode拿出来单独存一份，目的：只保留model，和扫码查询
        if (store.getters.getOrderTypeConf.taskOrderTypes_FM.includes(order.detail.model.order_type)) {
          order.qrCodes = order.detail.task_list.map(item => item.qrCode)
        }
        await saveOrderCacheAfterMergeHandler(order.id, order, {
          isDownload: 1,
          standardId: item.standardId,
          standardData: cloneDeep(standardData)
        })
        allOrderIds.push(order.id)
      }
    }
  }
  console.log('下载的工单离线包的所有id', allOrderIds)
  console.log('下载的工单离线包工单个数', allOrderIds.length)

  await deleteOrderCacheIfNotExitInOrderPackage(allOrderIds)

  //
  console.log('-----保存完成------')
}

/**
 *
 */
const deleteOrderCacheIfNotExitInOrderPackage = async (orderIds) => {
  // 先获取所有的工单列表
  const allOrderCacheList = await getOrderListOfflineApiHandler()
  console.log('删除不在离线包里的工单缓存，获取所有工单列表: ', allOrderCacheList)
  for (const order_cache of allOrderCacheList) {
    if (!orderIds.includes(order_cache.orderId)) {
      console.log('当前工单不在离线包里，删除！！！！', order_cache.orderId)
      await deleteOrderCacheOfflineApiHandler({ params: [order_cache.orderId] })
    }
  }
}

/**
 * 将分数调整装在详情里
 */
const setScoreAdjustsHandler = async ({ params }) => {
  console.log('params: ', params)
  const { scoreAdjust, orderId, itemIndex } = params
  return new Promise(async resolve => {
    const order_cache = await getOrderCacheOfflineApiHandler({
      params: { id: orderId }
    })
    const detail = order_cache.orderData.detail
    const taskItem = detail.orderTaskItemExtModelList[itemIndex]
    scoreAdjust.itemName = taskItem.itemName
    if (!taskItem.scoreAdjusts) {
      taskItem.scoreAdjusts = []
    }
    scoreAdjust.checkScoreAdjusts.forEach(adjustOption => {
      const adjustTypeOtion = taskItem.options[adjustOption.optionIndex].typeOptions.find(typeOption => typeOption.type === 10)
      adjustTypeOtion.val = adjustOption.adjustScore
      delete adjustOption.optionIndex
    })
    taskItem.scoreAdjusts.push(scoreAdjust)
    // 算总得分
    let finalScore = 0
    taskItem.options.forEach((option) => {
      option.typeOptions.forEach((typeOption) => {
        // 将打分转为数字
        if (typeOption.type === 10) {
          if (typeOption.val || typeOption.val === 0) {
            const score = typeOption.val * 1
            if (!isNaN(score)) {
              finalScore += score
            }
          }
        }
      })
    })
    taskItem.finalScore = finalScore
    await saveOrderCacheOfflineApiHandler({ params: order_cache })

    resolve(detail.orderTaskItemExtModelList)
  })
}
/**
 * 将任务项标准不适用调整装在详情里
 * @param {*} param0
 * @returns
 */
const setGradeStatusHandler = async ({ params }) => {
  console.log('setGradeStatusHandler params: ', params)
  const { taskIndex, orderId } = params
  return new Promise(async resolve => {
    const order_cache = await getOrderCacheOfflineApiHandler({
      params: { id: orderId }
    })
    const detail = order_cache.orderData.detail
    // 如果已执行后再操作标准不适用需清空执行结果
    detail.orderTaskItemExtModelList[taskIndex].options.forEach(option => {
      option.typeOptions.forEach((typeOption) => {
        typeOption.val = null
      })
    })
    // if (detail.pinkongProblemReq && detail.pinkongProblemReq.problemRecordList.length > 0) {
    //   const itemId = detail.orderTaskItemExtModelList[taskIndex].itemId
    //   detail.pinkongProblemReq.problemRecordList = detail.pinkongProblemReq.problemRecordList.filter(record => record.itemId !== itemId)
    //   if (detail.pinkongProblemReq.problemRecordList.length === 0) {
    //     delete detail.pinkongProblemReq
    //   }
    // }
    detail.orderTaskItemExtModelList[taskIndex].gradeStatus = 2
    detail.orderTaskItemExtModelList[taskIndex].status = 0
    detail.orderTaskItemExtModelList[taskIndex].finalScore = null
    detail.orderTaskItemExtModelList[taskIndex].totalScore = null
    detail.orderTaskItemExtModelList[taskIndex].finishTime = moment().format('YYYY-MM-DD HH:mm:ss')
    await saveOrderCacheOfflineApiHandler({ params: order_cache })
    resolve(detail.orderTaskItemExtModelList)
  })
}

export {
  getOrderCacheOfflineApiHandler,
  getOrderDetailOfflineApiHandler,
  saveOrderCacheOfflineApiHandler,
  saveServerOrderDetailOfflineApiHandler,
  getOrderListOfflineApiHandler,
  saveOrderAfterDownloadOrderPackageHandler,
  setGradeStatusHandler,
  setScoreAdjustsHandler,
  deleteOrderCacheOfflineApiHandler,
  saveOrderCacheAfterMergeHandler,
  saveOrderCacheByNewDetail,
  formatOrderListByStandrad
}
