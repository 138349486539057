var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("van-nav-bar", {
    staticClass: "nav",
    class: { blue: _vm.blue },
    attrs: { fixed: "", "z-index": "999", placeholder: true },
    scopedSlots: _vm._u(
      [
        {
          key: "title",
          fn: function () {
            return [
              _c("span", { staticClass: "nav-title-text" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "left",
          fn: function () {
            return [
              _c("div", {
                staticClass: "icon-back",
                on: { click: _vm.handleBack },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "right",
          fn: function () {
            return [_vm._t("right")]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }