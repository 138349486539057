import mobile from '@/utils/mobile'
import store from '@/store'

const getFilterOrderListSaveKey = () => { // 过滤后的orderList
  return 'filterOrderListSaveKey-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}

const getHomeEquipmentTreeSaveKey = () => { // 设施设备tree
  return 'homeEquipmentTree-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}

const getHomeEquipmentTreeGetGroupSaveKey = () => { // 设施设备的树,根据项目加载组团
  return 'homeEquipmentTreeGetGroup-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}

const getFilterApiParamsSaveKey = () => { // 选择的code qrCode的 key
  return 'filterApiParamsSaveKey-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}

const getOfflineAreaDataSaveKey = () => { //  根据分类+组团+项目编码查询空间资产信息
  return 'offlineAreaData-' + store.getters.getUserInfo.username
}

const setHomeEquipmentTree = async (obj) => { // 保存所有设施设备tree
  console.log('obj.homeEquipmentTree 保存所有设施设备tree: ', obj.homeEquipmentTree)
  await mobile.saveData({
    key: getHomeEquipmentTreeSaveKey(),
    value: obj.homeEquipmentTree
  })
}

const setHomeEquipmentTreeGetGroup = async (obj) => { // 设置所有组团列表
  await mobile.saveData({
    key: getHomeEquipmentTreeGetGroupSaveKey(),
    value: obj.homeEquipmentTreeGetGroup
  })
}

const saveFilterApiParamsOfflineApiHandler = async (obj) => { // 存储选择的code qrCode
  console.log('obj666FilterApiParams: ', obj)
  console.log('getFilterApiParamsSaveKey(): ', getFilterApiParamsSaveKey())
  await mobile.saveData({
    key: getFilterApiParamsSaveKey(),
    value: obj
  })
}

// const saveFilterOrderListOfflineApiHandler = async (obj) => { // 保存过滤后的orderList
//   console.log('objorderList: ', obj)
//   console.log('getFilterOrderListSaveKey(): ', getFilterOrderListSaveKey())
//   await mobile.saveData({
//     key: getFilterOrderListSaveKey(),
//     value: obj
//   })
// }

const saveOfflineAreaDataOfflineApiHandler = async (obj) => { // 离线缓存 根据分类+组团+项目编码查询空间资产信息
  await mobile.saveData({
    key: getOfflineAreaDataSaveKey(),
    value: obj.value
  })
}

export {
  getFilterOrderListSaveKey,
  getHomeEquipmentTreeSaveKey,
  getHomeEquipmentTreeGetGroupSaveKey,
  getFilterApiParamsSaveKey,
  getOfflineAreaDataSaveKey,
  setHomeEquipmentTree,
  setHomeEquipmentTreeGetGroup,
  // saveFilterOrderListOfflineApiHandler,
  saveFilterApiParamsOfflineApiHandler,
  saveOfflineAreaDataOfflineApiHandler
}
