import api from '@/utils/http'
export default {
  get: (url, params = {}, showLoading) => {
    return new Promise((resolve, reject) => {
      api.api({
        method: 'get',
        url,
        params,
        showLoading,
        // 成功
        cb: (res) => {
          resolve(res)
        },
        // 失败
        cbCodeFail: (fail) => {
          reject(fail)
        },
        // 其他错误
        errorCb: (cbNet) => {
          reject(cbNet)
        }
      })
    })
  },
  post: (url, params = {}, showLoading) => {
    return new Promise((resolve, reject) => {
      api.api({
        method: 'post',
        url,
        params,
        showLoading,
        // 成功
        cb: (res) => {
          resolve(res)
        },
        // 失败
        cbCodeFail: (fail) => {
          reject(fail)
        },
        // 其他错误
        errorCb: (cbNet) => {
          reject(cbNet)
        }
      })
    })
  }
}
