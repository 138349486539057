import mobile from '@/utils/mobile'
import { cloneDeep } from 'lodash'
import store from '@/store'
import moment from 'moment'
import {
  getOrderListOfflineApiHandler,
  formatOrderListByStandrad
} from '@/plugins/offlineMixin/offlineApiHandlers/OrderInfoOfflineApiHandlers'

const getSearchProjectCodesOfflineApiHandler = async () => {
  return new Promise(async resolve => {
    try {
      const result = await mobile.getSaveData('searchProjectCodes')
      console.log('mobisearchProjectCodes', result)
      if (result && result.value) {
        resolve(cloneDeep(result.value))
      } else {
        resolve()
      }
    } catch (error) {
      console.error('getSearchProjectCodesOfflineApiHandler', error)
    }
  })
}

// 获取本地接口版本
const getOfflineInterfaceListHandler = async () => {
  return new Promise(async resolve => {
    try {
      const result = await mobile.getSaveData('offlineInterfaceList')
      console.log(result, 'offlineInterfaceList')
      if (result?.value) {
        let res = result.value
        resolve(res || [])
      } else {
        resolve()
      }
    } catch (error) {
      console.error('getOfflineInterfaceListHandler', error)
    }
  })
}
// 根据接口名获取对应离线数据
const getOfflineDataHandler = interfaceName => {
  return new Promise(async resolve => {
    try {
      const saveDataKey = interfaceName + '-' + store.getters.getUserInfo.username
      const result = await mobile.getSaveData(saveDataKey)
      resolve(result ? result.value : undefined)
      if (result) {
        let data = {}
        data[interfaceName] = result.value
        // initoffline(data)
      }
    } catch (error) {
      console.error('getOfflineDataHandler', error)
    }
  })
}

// 设置权限项目和离线version
const setOfflineInterfaceListHandler = (obj) => {
  return new Promise(async resolve => {
    let saveData = {
      key: 'offlineInterfaceList',
      value: obj.offlineInterfaceList
    }
    await mobile.saveData(saveData)
    // initoffline(obj)
    resolve('ok')
  })
}
// 设置离线数据
const setOfflineDataHandler = (obj) => {
  return new Promise(async resolve => {
    let saveData = {
      key: obj.offlineDataInterfaceName + '-' + store.getters.getUserInfo.username,
      value: obj.value
    }
    await mobile.saveData(saveData)
    const data = {}
    data[obj.offlineDataInterfaceName] = obj.value
    // initoffline(data)
    resolve('ok')
  })
}

const dashboardOfflineListHandler = async (searchParams) => {
  console.log(searchParams.params, 'searchParams')
  const allOrderList = await getOrderListOfflineApiHandler() || []
  const list = await formatOrderListByStandrad(allOrderList) || []
  console.log('首页得到全部的工单列表: ', cloneDeep(list))
  let filterList = filterOfflineList(list, searchParams.params)
  console.log('filterList: ', cloneDeep(filterList))
  return {
    records: cloneDeep(filterList)
  }
}
const filterOfflineList = (list, searchParams) => {
  // 工单类型/时间段筛选显示的列表数据
  const filterCondition = (orderItem, standartItem) => {
    try {
      let isMatch = true // 假设匹配
      // 根据选择的类型过滤工单，找出要过滤掉的场景 意为  FM or ZG 时
      if ((searchParams.isFM || searchParams.isZG || searchParams.isCJ)) {
        if (searchParams.orderTypeSelects.length > 0) {
          if (!searchParams.orderTypeSelects.some(orderTypeSelect => orderItem.orderTypeSelects.includes(orderTypeSelect))) {
            isMatch = false
          }
        }
      }
      // 意为 如果筛选条件中[当前时段]为"选中"，需要匹配有效工单，需注意，只有任务工单才会匹配当前时段
      if (
        searchParams.nowFlag === 1 && store.getters.getOrderTypeConf.taskOrderTypes.includes(orderItem.order_type)
      ) {
        // 当前时段(任务工单才执行时段过滤)
        if (
          !(
            moment(orderItem.plan_end, 'YYYY-MM-DD HH:mm:ss').valueOf() >=
              moment().valueOf() &&
            moment(orderItem.plan_start, 'YYYY-MM-DD HH:mm:ss').valueOf() <=
              moment().valueOf()
          )
        ) {
          if (standartItem) {
            if (orderItem && orderItem.ismature) {
              // 过期重计
              standartItem.matureNum -= 1
            }
            standartItem.taskNum -= 1 // 任务重计
          }
          isMatch = false
        }
      }
      if (searchParams.isZG) {
        // 意为 【问题整改】，需要匹配[我处理/我创建/全部]
        if (searchParams.dataScope === 2 && !(orderItem.create_by === store.getters.getUserInfo.id * 1)) {
          isMatch = false
        }
        // 意为【问题整改】，需要匹配项目
        if (searchParams.projectCodes && searchParams.projectCodes.length > 0 && !searchParams.projectCodes.includes(orderItem.project_code)) {
          isMatch = false
        }
        // 意为【问题整改】，只需要显示【处理中】的工单
        if (orderItem.template_node_code !== 'chulizhong') {
          isMatch = false
        }
        // 意为【问题整改】，报事人过滤
        if (searchParams.reportUserName && orderItem.reportUserName && orderItem.reportUserName.indexOf(searchParams.reportUserName) === -1) {
          isMatch = false
        }
        // 意为【问题整改】，所在位置过滤
        if (searchParams.address && orderItem.address && orderItem.address.indexOf(searchParams.address) === -1) {
          isMatch = false
        }
        // 意为【问题整改】，工单编号过滤
        if (searchParams.orderNo && orderItem.order_no && orderItem.order_no.indexOf(searchParams.orderNo) === -1) {
          isMatch = false
        }
        // 意为【问题整改】，原因大类过滤
        if (searchParams.bigCategoryCode && orderItem.big_category_code !== searchParams.bigCategoryCode) {
          isMatch = false
        }
        // 意为【问题整改】，原因细类过滤
        if (searchParams.smallCategoryCode && orderItem.small_category_code !== searchParams.smallCategoryCode) {
          isMatch = false
        }
      }
      return isMatch
    } catch (error) {
      console.error('filterCondition', error)
    }
  }
  return (list || []).reduce((parentArr, parentItem) => {
    if (parentItem.id) {
      // 普通工单判断
      if (filterCondition(parentItem.detail.model)) {
        parentArr.push(parentItem)
      }
    } else if (parentItem.orders && parentItem.orders.length > 0) {
      // 任务工单判断
      parentItem.orders = parentItem.orders.reduce((arr, item) => {
        if (filterCondition(item.detail.model, parentItem)) {
          arr.push(item)
        }
        return arr
      }, [])
      if (parentItem.orders.length > 0) {
        parentArr.push(parentItem)
      }
    }
    console.log('parentArr', parentArr)
    return parentArr
  }, [])
}

export {
  getSearchProjectCodesOfflineApiHandler,
  getOfflineInterfaceListHandler,
  getOfflineDataHandler,
  setOfflineInterfaceListHandler,
  setOfflineDataHandler,
  dashboardOfflineListHandler
}
