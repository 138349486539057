import {
  getOfflineDetail,
  initoffline,
  getAllCausesByPointSaveKey
} from '@/utils/offline.js'
import mobile from '@/utils/mobile'
import { cloneDeep } from 'lodash'

export default ({ params }) => {
  return new Promise(async resolve => {
    try {
      const { allCausesByPoint } = getOfflineDetail()
      if (!allCausesByPoint) {
        const saveDataKey = getAllCausesByPointSaveKey()
        const result = await mobile.getSaveData(saveDataKey)
        if (result && result.key === saveDataKey) {
          let val = result.value
          initoffline({ allCausesByPoint: val })
          resolve(cloneDeep(filter(params, val)))
        } else {
          resolve()
        }
      } else {
        resolve(cloneDeep(filter(params, allCausesByPoint)))
      }
    } catch (error) {
      console.error('MalfCompListGuideOfflineApiHandlers', error)
    }
  })
}

const filter = (params, arr) => {
  const { pointCode } = params
  if (!pointCode) return arr
  return arr.reduce((arr, causesByPoint) => {
    const index = causesByPoint.points.findIndex(point => point.pointCode === pointCode)
    if (index > -1) {
      arr.push({ ...causesByPoint })
    }
    return arr
  }, [])
}
