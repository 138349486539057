// 记录点位勾选
const pointStorage = {
  state: {
    pointList: []
  },
  mutations: {
    setPointList (state, e) {
      state.pointList = e
    }
  }
}

export default pointStorage
