// import mobile from '@/utils/mobile'
import { uniq } from 'lodash'
import {
  getOrderListOfflineApiHandler,
  formatOrderListByStandrad
} from '@/plugins/offlineMixin/offlineApiHandlers/OrderInfoOfflineApiHandlers'
import store from '@/store'

/*
  CodeOrderList 取离线时扫码的工单
*/
const getCodeListOffline = async ({ params }) => {
  return new Promise(async resolve => {
    try {
      const isFM = store.getters.getIsFM
      const isPK = store.getters.getIsPK
      const isZG = store.getters.getIsZG
      const isCJ = store.getters.getIsCJ
      const { codes = [], qrCode = [] } = params
      console.log('codes: ', codes)
      console.log('qrCode: ', qrCode)
      const selectArr = uniq(codes.concat(qrCode))
      console.log('selectArr: ', selectArr)
      const allOrderList = await getOrderListOfflineApiHandler() || [] // 获取离线列表
      const list = await formatOrderListByStandrad(allOrderList) || []
      console.log('list: ', list)
      const codeList = list.reduce((arr, order) => {
        // 非标准下的工单
        if (order.id) {
          if (selectArr.includes(order.detail.model.addressQrCode) && !order.isHidden) {
            arr.push(order)
          }
        } else if (order.orders && order.orders.length > 0) {
          // 标准下的工单
          console.log('isFM,isPK,isZG,isCJ', isFM, isPK, isZG, isCJ)
          const filterStandandOrders = order.orders.reduce((array, standOrder) => {
            // FM
            if ((isFM || isCJ) && standOrder.qrCodes.some(task => selectArr.includes(task))) {
              array.push(standOrder)
            }
            // 品控 / 问题整改
            if ((isPK || isZG) && standOrder.pointCodes.some(pointCode => selectArr.includes(pointCode))) {
              array.push(standOrder)
            }
            return array
          }, [])
          if (filterStandandOrders.length > 0) {
            order.orders = filterStandandOrders
            order.taskNum = filterStandandOrders.length
            order.matureNum = filterStandandOrders.filter(standOrder => standOrder.ismature).length
            arr.push(order)
          }
        }
        return arr
      }, [])
      console.log('codeList: ', codeList)
      resolve({ records: codeList })
    } catch (error) {
      console.error('getCacheCodeHandler', error)
    }
  })
}
const getCodeListOfflineByVoice = async ({ params }) => {
  return new Promise(async resolve => {
    try {
      const isCJ = store.getters.getIsCJ
      const { voiceContent } = params
      console.log('voiceContent: ', voiceContent)
      const allOrderList = await getOrderListOfflineApiHandler() || [] // 获取离线列表
      const list = await formatOrderListByStandrad(allOrderList) || []
      console.log('list: ', list)
      const codeList = list.reduce((arr, order) => {
        // 非标准下的工单
        if (order.id) {
          if (order.detail.model.addressName.indexOf(voiceContent) > -1 && !order.isHidden) {
            arr.push(order)
          }
        } else if (order.orders && order.orders.length > 0) {
          if (order.standardName.indexOf(voiceContent) > -1) {
            arr.push(order)
          } else {
            // 标准下的工单
            const filterStandandOrders = order.orders.reduce((array, standOrder) => {
              // 承接查验
              if (isCJ && standOrder.detail.task_list.some(task => task.itemName.indexOf(voiceContent) > -1)) {
                array.push(standOrder)
              }
              return array
            }, [])
            if (filterStandandOrders.length > 0) {
              order.orders = filterStandandOrders
              order.taskNum = filterStandandOrders.length
              order.matureNum = filterStandandOrders.filter(standOrder => standOrder.ismature).length
              arr.push(order)
            }
          }
        }
        return arr
      }, [])
      console.log('codeList: ', codeList)
      resolve({ records: codeList })
    } catch (error) {
      console.error('getCacheCodeHandler', error)
    }
  })
}

export {
  getCodeListOffline,
  getCodeListOfflineByVoice
}
