import {
  initoffline,
  getAllMalfunctionGuideListSaveKey,
  getOfflineDetail
} from '@/utils/offline.js'
import mobile from '@/utils/mobile'
import { cloneDeep } from 'lodash'

export default ({ params }) => {
  console.log('params---AAA---: ', params)
  return new Promise(async resolve => {
    try {
      const { allMalfunctionGuideList } = getOfflineDetail()
      if (!allMalfunctionGuideList) {
        const saveDataKey = getAllMalfunctionGuideListSaveKey()
        const result = await mobile.getSaveData(saveDataKey)
        if (result && result.key === saveDataKey) {
          let val = result.value
          initoffline({ allMalfunctionGuideList: val })
          resolve(filter(params, val))
        } else {
          resolve()
        }
      } else {
        resolve(filter(params, allMalfunctionGuideList))
      }
    } catch (error) {
      console.error('MalfListGuideOfflineApiHandlers', error)
    }
  })
}

const filter = (params, arr) => {
  const { pointCode, mainPart } = params
  if (!pointCode) return cloneDeep(arr)
  const newArr = arr.reduce((arr, guide) => {
    if (guide.pointCode === pointCode && guide.mainPart === mainPart) {
      arr.push({ ...guide })
    }
    return arr
  }, [])
  return cloneDeep(newArr)
}
