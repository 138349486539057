var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c(
        "keep-alive",
        [_vm.$route.meta.keepAlive ? _c("router-view") : _vm._e()],
        1
      ),
      !_vm.$route.meta.keepAlive
        ? _c("router-view", { key: _vm.$route.fullPath })
        : _vm._e(),
      _c(
        "van-notify",
        {
          staticClass: "offline-notify",
          attrs: { type: "danger", background: "#FF4949" },
          model: {
            value: _vm.offlineShow,
            callback: function ($$v) {
              _vm.offlineShow = $$v
            },
            expression: "offlineShow",
          },
        },
        [
          _c("van-icon", { attrs: { size: "14", name: "warning-o" } }),
          _c("span", [_vm._v("当前网络不可用，请检查您的网络设置")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }