const getters = {
  getDetail: state => state.order.detail,
  getPatroItemId: state => state.order.patroItemId,
  getPatroItem: state => state.order.patroItem,
  getUserInfo: state => state.user.userInfo,
  getTwUserCode: state => state.user.twUserCode,
  getRouter: state => state.historyRoute.router,
  // system
  getIsOffline: state => state.system.offline,
  getOfflineStatus: state => state.system.offlineStatus,
  getPageType: state => state.system.pageType,
  getIsFM: state => state.system.isFM,
  getIsPK: state => state.system.isPK,
  getIsZG: state => state.system.isZG,
  getIsCJ: state => state.system.isCJ,
  getIsJK: state => state.system.isJK,
  getOrderTypeConf: state => state.order.orderTypeConf,
  getOrderNatureDic: state => state.order.orderNatureDic
}
export default getters
