var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", {
    staticClass: "microIcon",
    class: { black: _vm.black },
    style: _vm.styleProp,
    on: { click: _vm.iconClick },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }