import mobile from '@/utils/mobile'
import { cloneDeep } from 'lodash'

/**
 * 查询待上传的工单id列表
 * @returns {Promise<unknown>}
 */
const getWaitingUploadListOfflineApiHandler = async () => {
  return new Promise(async resolve => {
    try {
      // 请求的参数
      let orderListAll = []
      let orderListPart = []
      const listParams = {
        isToBeUpload: 1,
        page: 0,
        limit: 10
      }
      do {
        orderListPart = await mobile.getOrderList(listParams)
        console.log('listParams.page', listParams.page)
        console.log('orderListPart: ', orderListPart)
        ++listParams.page
        orderListAll = orderListAll.concat(orderListPart)
      } while (orderListPart && orderListPart.length > 0)
      // 反序列化
      for (const order_cache of orderListAll) {
        order_cache.orderData = JSON.parse(order_cache.orderData)
      }
      // 根据finishTime排序
      orderListAll.sort(function (a, b) {
        return a.orderData.offlineSubmitFinishTime - b.orderData.offlineSubmitFinishTime
      })
      console.log('所有的待上传工单orderListAll: ', cloneDeep(orderListAll))
      resolve(orderListAll)
    } catch (error) {
      console.error('getWaitingUploadListOfflineApiHandler', error)
    }
  })
}

export {
  getWaitingUploadListOfflineApiHandler
}
