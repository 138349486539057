// 离线的处理或者关于离线有限的一些处理
import mobile from './mobile'
import store from '../store'
let _initOfflineDetail = {
  _content: '', // 执行环境
  orderList: [], // 工单列表
  pointList: [], // 点位列表
  offlinePointList: [], // 离线点位列表数据
  offlineList: [], // 离线提交缓存数据
  orderDetail: {}, // 工单详情
  patroItem: {}, // 任务项
  patroIndex: 0, // 修改的任务项index
  problemItem: {}, // 检查项问题
  problemIndex: 0, // 第几个检查项
  offlineProblemList: [],
  orderId: '', // 订单ID
  filterApi: '', // 在过滤页面需要掉的接口
  filterApiParams: {}, // 在过滤页面需要过滤的接口
  filterOrderList: [], // 通过点位或者扫码过滤的列表数据
  code: '',
  projectInfo: {},
  problemBackPhone: false, // 通过单选选择异常时需要回填照片的控制器
  cacheCode: {}, // 缓存扫码的code
  historyList: [], // 尽三日操作数据列表
  historyAllList: [],
  homeEquipmentTree: undefined,
  homeEquipmentTreeGetGroup: undefined,
  homeEquipmentTreeGetLast: undefined,
  offlineInterfaceList: [], // 存储离线数据接口版本用于增量下载-->比对离线数据下载接口是否更新,
  offlineAreaData: undefined // 存储离线数据---设施设备最后一级
}
// 获取离线数据
const getOfflineDetail = () => {
  // 深度拷贝对象
  return Object.assign({}, _initOfflineDetail)
}
// 初始化数据
const initoffline = (obj) => {
  _initOfflineDetail = Object.assign(_initOfflineDetail, obj)
}
// 设置所有故障原因部件列表
const setAllCausesByPoint = async (obj) => {
  let saveData = {
    key: getAllCausesByPointSaveKey(),
    value: obj.allCausesByPoint
  }
  await mobile.saveData(saveData)
  initoffline(obj)
}
// 设置所有故障指导列表
const setAllMalfunctionGuideList = async (obj) => {
  let saveData = {
    key: getAllMalfunctionGuideListSaveKey(),
    value: obj.allMalfunctionGuideList
  }
  await mobile.saveData(saveData)
  initoffline(obj)
}

// 离线修改详情的关注
const setDetailStar = (orderId, status) => {
  let { orderList } = _initOfflineDetail
  let orderDetail = orderList.find(item => item.id === orderId)
  orderDetail && (orderDetail.isattention = status)
}

const getPointListSaveKey = () => {
  return 'pointList-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}

const getAllCausesByPointSaveKey = () => {
  return 'allCausesByPoint-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}

const getAllMalfunctionGuideListSaveKey = () => {
  return 'allMalfunctionGuideList-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}

const getHomeEquipmentTreeSaveKey = () => {
  return 'homeEquipmentTree-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}

const getHomeEquipmentTreeGetGroupSaveKey = () => {
  return 'homeEquipmentTreeGetGroup-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}

const getAllDictionarySaveKey = () => {
  return 'dictionaryAll-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}

const getOfflineAreaDataSaveKey = () => {
  return 'offlineAreaData-' + store.getters.getUserInfo.username
}

const getOrderDataSaveKey = (orderId) => {
  return orderId + '-' + store.getters.getUserInfo.username
}

export {
  initoffline,
  getOfflineDetail,
  setDetailStar,
  setAllCausesByPoint,
  setAllMalfunctionGuideList,
  getPointListSaveKey,
  getAllCausesByPointSaveKey,
  getAllMalfunctionGuideListSaveKey,
  getHomeEquipmentTreeSaveKey,
  getHomeEquipmentTreeGetGroupSaveKey,
  getAllDictionarySaveKey,
  getOfflineAreaDataSaveKey,
  getOrderDataSaveKey
}
