<template v-cloak>
  <div class="upload-wrapper">
    <van-field name="attachmentList"
               class="form-upload"
               :required="required"
               :label="`图片(可上传${maxCount}张)`">
      <template #input>
        <van-uploader v-model="fileList"
                      multiple
                      :before-read="uploadBefore"
                      :after-read="uplaodAfter"
                      :max-count="maxCount"
                      :before-delete="delteAfter" />
      </template>
    </van-field>
    <canvas v-show="false" />
  </div>
</template>

<script type='text/ecmascript-6'>
import common from '@/utils/tools.js'
import mobile from '@/utils/mobile'
import {
  getOssParams
} from '@/plugins/offlineMixin/utils/offlineImgTool'
export default {
  name: 'VanUploadOffline',
  components: {},
  props: {
    file: {
      type: [Array, String],
      require: true,
      default: () => []
    },
    maxCount: {
      type: Number,
      default: 10
    },
    required: {
      type: Boolean,
      default: false
    }
    // offlineAble: {
    //   type: Boolean,
    //   required: true
    // }
  },
  data () {
    return {
      fileList: [],
      ossParams: {},
      copyFileList: [],
      submitFile: [],
      setIndex: 0,
      loadingCount: 0,
      uploadImg: ''
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  watch: {
    isOffline: {
      handler (val) {
        val && this._getOssParams()
      },
      immediate: true
    }
  },
  mounted () {
    this._setFileList()
  },
  methods: {
    resetData () {
      Object.assign(this.$data, this.$options.data())
    },
    _setFileList () {
      this.fileList = this.file || []
    },
    /* 获取ali-oss信息 */
    async _getOssParams () {
      const ossParams = await getOssParams()
      this.ossParams = ossParams
    },
    uploadBefore (fileList) {
      if (fileList.length + this.fileList.length > this.maxCount) {
        this.$toast(`文件最多上传${this.maxCount}个`)
        return false
      } else {
        return true
      }
    },
    uplaodAfter (fileList) {
      if (fileList && fileList.length > 0) {
        for (let item of fileList) {
          if (item.file.size > 15 * 1024 * 1024) {
            this.$toast(`该文件${item.file.name}超过15M，上传失败`)
            break
          } else {
            this.getCancas(item)
          }
        }
      } else if (fileList && fileList.file) {
        if (fileList.file.size > 15 * 1024 * 1024) {
          this.$toast(`该文件超过15M，上传失败`)
          return
        }
        this.getCancas(fileList)
      }
    },
    getCancas (item) {
      const uploadToast = this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0
      })
      try {
        this.loadingCount++
        const file = item.file
        let imgName = item.file.name
        let imgType = item.file.type
        let that = this
        const reader = new FileReader()
        const img = new Image()
        // 缩放图片需要的canvas
        const canvas = document.createElement('canvas')
        const context = canvas.getContext('2d')
        reader.readAsDataURL(file)
        // base64地址图片加载完毕后
        img.onload = function () {
        // 图片原始尺寸
          const originWidth = this['width']
          const originHeight = this['height']
          // 最大尺寸限制
          const maxWidth = 750
          const maxHeight = 400
          // 目标尺寸
          let targetWidth = originWidth
          let targetHeight = originHeight
          // 图片尺寸超过750x400的限制
          // if (originWidth > maxWidth || originHeight > maxHeight) {
          //   if (originWidth / originHeight > maxWidth / maxHeight) {
          //   // 更宽，按照宽度限定尺寸
          //     targetWidth = maxWidth
          //     targetHeight = Math.round(maxWidth * (originHeight / originWidth))
          //   } else {
          //     targetHeight = maxHeight
          //     targetWidth = Math.round(maxHeight * (originWidth / originHeight))
          //   }
          // }
          if (targetWidth > maxWidth || targetHeight > maxHeight) {
            targetWidth = targetWidth / 2
            targetHeight = targetHeight / 2
          }
          // canvas对图片进行缩放
          canvas.width = targetWidth
          canvas.height = targetHeight
          // 清除画布
          context.clearRect(0, 0, targetWidth, targetHeight)
          // 图片压缩
          context.drawImage(img, 0, 0, targetWidth, targetHeight)
          // canvas转为blob并上传
          // * 很多PC、手机浏览器不支持toBlob方法，如Safari*
          // 不推荐使用toBlob方法
          // canvas.toBlob(function($Blob) {
          // })
          const dataUrl = canvas.toDataURL('image/jpeg')
          // 区分在线和离线
          if (that.isOffline) {
            let blob = that.dataURLtoBlob(dataUrl)
            let file = new window.File([blob], imgName, { type: imgType })
            that.uploadfile(file)
          } else {
            if (that.offlineAble) {
              that.uploadOfflineFile(dataUrl)
            } else {
              that.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
              that.loadingCount--
              if (that.loadingCount === 0) {
                uploadToast.clear()
              }
            }
          }
        }
        reader.onload = function (e) {
          img.src = e.target['result']
        }
      } catch (error) {
        console.log('error: ', error)
      } finally {
        uploadToast.clear()
      }
    },
    readFileAsDataURL (file, callback) {
      let a = new FileReader()
      a.readAsDataURL(file)
      a.onload = function (e) {
        callback(e.target.result)
      }
    },
    // 将base64转换为blob
    dataURLtoBlob (dataurl) {
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    // 将blob转换为file
    blobToFile: function (theBlob, fileName) {
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
    uploadOfflineFile (base64Str) {
      mobile.saveDataBase64(base64Str, (imageKey) => {
        this.loadingCount--
        if (this.loadingCount === 0) {
          this.$toast.clear()
        }
        this.submitFile.push({
          offlineImageKey: imageKey
        })
        this.$emit('update:file', this.submitFile)
        this.$emit('change')
      })
    },
    uploadfile (file) {
      try {
        common.attachUpload(this.ossParams, { file: file }, (e) => {
          let { url, name, fileSize, fileType } = e
          this.submitFile.push({
            file,
            url,
            name,
            fileSize,
            fileType,
            content: '',
            status: '',
            datetime: new Date()
          })
          this.loadingCount--
          if (this.loadingCount === 0) {
            this.$toast.clear()
          }
          this.$emit('update:file', this.submitFile)
          this.$emit('change')
        })
      } catch (error) {
        console.log('error: ', error)
      }
    },
    delteAfter (file, { index }) {
      this.submitFile.splice(index, 1)
      if (this.submitFile.length === 0) {
        this.$emit('update:file', '')
      } else {
        this.$emit('update:file', this.submitFile)
      }
      this.$emit('change')
      return true
    }
  }
}
</script>
<style  lang='less' scoped>
.upload-wrapper {
  .form-upload {
    &.van-cell {
      /deep/ .van-cell__title {
        font-size: 14px;
        color: #8B8F9A;
        margin-bottom: 6px;
      }
      padding: 0;
      &:after {
        border-bottom: none;
      }
    }
    .upload-list-box {
      width: 100%;
      overflow: hidden;
    }
    .uplaod-list-wrapper {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + 15px);
      .upload-list {
        width: 48px;
        height: 48px;
        margin-right: 15px;
        margin-bottom: 10px;
        position: relative;
        border-radius: 4px;
        .upload-img {
          width: 100%;
          height: 100%;
        }
        .delete-icon {
          display: block;
          width: 14px;
          height: 14px;
          position: absolute;
          right: -7px;
          top: -7px;
          background: url("../asset/icon_minus_blue@2x.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .upload-bg {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        background-color: #f7f8fa;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        margin-bottom: 10px;
        .van-icon-plus {
          font-size: 24px;
          color: #dcdee0;
        }
      }
    }
  }
}
</style>
