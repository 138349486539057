// 自动引入当前目录以及子文件夹下的所有js，达到分文件管理，自动引入的效果
const context = require.context('./', true, /.js$/)
context.keys().reduce((module, modulesPath) => {
  const moduleName = modulesPath.replace(/^.\/(.*)\.js/, '$1')
  if (moduleName !== 'index') {
    const value = context(modulesPath)
    module[moduleName] = value
  }
  return module
}, {})
