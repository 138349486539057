// 上传封装组件
// import MyUpload from './MyUpload.vue'
import VanUploadOffline from './VanUploadOffline.vue'
// import OfflineUpload from './OfflineUpload.vue'

import CommonUpload from './CommonUpload.vue'
import MyTextarea from './Textarea.vue'
import MyNav from '@/components/NavHeader'
import NoData from '@/components/NoData'
import microText from '@/components/microText.vue'
var vueplugin = {}
vueplugin.install = function (Vue) {
  // Vue.component(MyUpload.name, MyUpload)
  Vue.component(VanUploadOffline.name, VanUploadOffline)
  // Vue.component(OfflineUpload.name, OfflineUpload)
  /* 2023-02-02 将所有的拍照组件都改为APP实现 */
  Vue.component(CommonUpload.name, CommonUpload)
  Vue.component(MyTextarea.name, MyTextarea)
  Vue.component(NoData.name, NoData)
  Vue.component(MyNav.name, MyNav)
  Vue.component(microText.name, microText)
}

export default vueplugin
