import Vue from 'vue'

Vue.prototype.$EB = new Vue()
Vue.prototype.$EBT = {
  ORDER_UPGRADE: '工单升级',
  COMP_MALF_REASON: '部件故障原因',
  EquipClass: '设备分类',
  FacilityClass: '设施分类',
  ProjectList: '所在项目',
  equipmentFilter: '设施设备列表',
  reportForward: '转发设施设备',
  NO_NETWORK_WARN: '无网提示',
  GET_DASHBOARD_LIST: '重新获取首页列表数据',
  ORDER_SPOTCHECK_REFRESH: '抽查工单列表刷新'
}
