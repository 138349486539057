// import { cloneDeep } from 'lodash'
import mobile from '@/utils/mobile'
import store from '@/store'

const getAllDictionarySaveKey = () => {
  return 'dictionaryAll-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}

const getDictionaryAllOfflineHandler = () => {
  return new Promise(async resolve => {
    try {
      const result = await mobile.getSaveData(getAllDictionarySaveKey())
      if (result && result.key === getAllDictionarySaveKey() && result.value) {
        resolve(result.value)
      } else {
        resolve([])
      }
    } catch (error) {
      console.error('getDictionaryAllOfflineHandler', error)
    }
  })
}

const setDictionaryAll = async (obj) => {
  let saveData = {
    key: getAllDictionarySaveKey(),
    value: obj.dictionaryAll
  }
  await mobile.saveData(saveData)
}

export { setDictionaryAll, getDictionaryAllOfflineHandler }
