var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-wrapper" },
    [
      _c("van-field", {
        attrs: {
          clearable: "",
          type: "textarea",
          placeholder: _vm.placeholder,
          autosize: { maxHeight: 63, minHeight: 63 },
        },
        on: { blur: _vm.textBlur },
        model: {
          value: _vm.val,
          callback: function ($$v) {
            _vm.val = $$v
          },
          expression: "val",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }