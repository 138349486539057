<template>
  <div class="default-wrapper">
    <p class="default-bg" />
    <p class="tex">{{tex}}</p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'NoData',
  props: {
    tex: {
      type: String,
      default: '暂无数据'
    }
  }
}
</script>

<style lang="less" scoped>
.default-wrapper {
  margin-top: 145px;
  text-align: center;
  .default-bg {
    width: 142px;
    height: 100px;
    background: url("../asset/default-list-bg.png") no-repeat;
    background-size: 100% 100%;
    margin: 0 auto;
  }
  .tex {
    margin-top: 32px;
    margin-bottom: 0;
    font-size: 16px;
    color: #12141c;
    .param {
      color: #3366fe;
    }
  }
}
</style>
