import {
  // deleteOrderInOfflineListOfAllPage,
  saveOrderCacheOfflineApiHandler,
  deleteOrderCacheOfflineApiHandler,
  getOrderCacheOfflineApiHandler } from '@/plugins/offlineMixin/offlineApiHandlers/OrderInfoOfflineApiHandlers'
import store from '@/store'
import { Dialog, Toast } from 'vant'
import mobile from '@/utils/mobile'
import {
// deleteWaitingUploadListOfflineApiHandler,
} from '@/plugins/offlineMixin/offlineApiHandlers/WaitingUploadOfflineApiHandlers'
import { OFFLINE_REQUEST_SUCCESS, OSS_UPLOAD_ERRORCODE } from '@/plugins/offlineMixin/utils/StaticConstantCode'
// import { apiOffline } from '@/plugins/offlineMixin/apiOfflineGetway'

import { cloneDeep } from 'lodash'

const questionOrderSubmitOfflineApiHandler = async ({ params }) => {
  return new Promise(async resolve => {
    // const detail = cloneDeep(store.getters.getDetail)
    const order_cache = await getOrderCacheOfflineApiHandler({
      params: { id: store.getters.getDetail.model.id }
    })
    const detail = order_cache.orderData.detail
    console.log('detail: ', detail)
    console.log('>>>>params: ', params)
    const { buttonInfo, ...form } = params
    detail.buttonInfo = buttonInfo
    detail.form = form // 流转的信息存到detail里面
    order_cache.orderData.offlineSubmitFinishTime = new Date().getTime()
    order_cache.isToBeUpload = 1
    // 保存
    await saveOrderCacheOfflineApiHandler({ params: order_cache })
    resolve({ code: OFFLINE_REQUEST_SUCCESS })
  })
}
/*
  离线提交任务工单
*/
const standardOrderSubmitOfflineApiHandler = async ({ params }) => {
  console.log('standardOrderSubmitOfflineApiHandler: ')
  return new Promise(async resolve => {
    console.log('离线提交任务工单>>>>params: ', params)
    const { orderId, buttonInfo } = params
    const order_cache = await getOrderCacheOfflineApiHandler({
      params: { id: orderId }
    })
    order_cache.isToBeUpload = 1
    order_cache.orderData.detail.buttonInfo = buttonInfo
    order_cache.orderData.offlineSubmitFinishTime = new Date().getTime()
    console.log('离线提交任务工单 order_cache: ', cloneDeep(order_cache))
    // 存入缓存
    await saveOrderCacheOfflineApiHandler({ params: order_cache })

    resolve({ code: OFFLINE_REQUEST_SUCCESS })
  })
}

/**
 * @param orderDetailList  detail结构的list
 * @returns {Promise<unknown>}
 */
const cleanOrderAfterServerSubmitHandler = async ({ params: submitOrderList }) => {
  console.log('!!!submitOrderList: ', submitOrderList)
  return new Promise(async resolve => {
    // const { idList, isComplete = true, orderType } = params
    // console.log('>>>>>cleanOrderAfterServerSubmitHandler, idList: ', idList)
    // const { submitOrderList } = params
    for (const order of submitOrderList) {
      const { orderId, isComplete = true, orderType } = order
      // 完成/流转的工单 / 普通工单  删除近三天数据 删除整个工单数据
      if (isComplete || store.getters.getOrderTypeConf.normalOrderTypes.includes(orderType)) {
        await mobile.deleteRecentOrder({ orderIds: [orderId] })
        await deleteOrderCacheOfflineApiHandler({ params: [orderId] })
      } else { // 任务工单且部分提交的
        // 如果只是批量提交任务项，需要剔除所有任务项的offlineSubmit标志
        const order_cache = await getOrderCacheOfflineApiHandler({
          params: { id: orderId }
        })
        const detail = order_cache.orderData.detail
        if (detail.orderTaskItemExtModelList.length > 0) {
          for (const taskItem of detail.orderTaskItemExtModelList) {
            if (taskItem.offlineSubmit === 1) taskItem.isServerSubmited = 1
            delete taskItem.offlineSubmit
          }
        }
        await saveOrderCacheOfflineApiHandler({ params: order_cache })
      }
    }
    resolve('ok')
  })
}
/**
 * @param orderDetailList  detail结构的list
 * @returns {Promise<unknown>}
 */

const cleanOrderAfterReCirculation = async ({ params: idList }) => {
  return new Promise(async resolve => {
    console.log('>>>>>cleanOrderAfterReCirculation, idList: ', idList)
    // 删除近三天
    await mobile.deleteRecentOrder({ orderIds: idList })
    // 删除APP缓存数据
    await deleteOrderCacheOfflineApiHandler({ params: idList })
    resolve('ok')
  })
}

/**
 * @param orderDetailList  detail结构的list 离线提交成功后
 * @returns {Promise<unknown>}
 */
const cleanOrderAfterOfflineSubmitHandler = async ({ params }) => {
  return new Promise(async resolve => {
    // 删除近三天记录
    Toast.clear()
    Dialog.alert({
      title: '提示',
      message: '您的网络状态不好，工单已保存到离线数据，请在网络情况好的状态下进行上传数据。',
      confirmButtonColor: '#3366FE',
      confirmButtonText: '知道了'
    }).then(() => {
      resolve('ok')
    })
  })
}

const catchOssUploadErrorHandler = ({ params }) => {
  const { errorCode, buttonInfo, func } = params
  if (errorCode && OSS_UPLOAD_ERRORCODE.includes(errorCode)) {
    Dialog.confirm({
      title: '提示',
      message: '您的网络状态不好或网络中断，上传图片失败。请在网络情况较好的情况下进行重试，或保存到离线待上传列表中（在网络情况较好的情况下批量上传）。',
      confirmButtonColor: '#3366FE',
      confirmButtonText: '存入待上传列表',
      showCancelButton: true,
      cancelButtonText: '重试',
      cancelButtonColor: '#3366FE',
      closeOnClickOverlay: true,
      beforeClose: (action, done) => beforeClose(action, done, buttonInfo, func)
    })
  }
}
function beforeClose (action, done, buttonInfo, func) {
  console.log('action: ', action)
  if (action === 'confirm') {
    func(buttonInfo, true)
    done()
  } else if (action === 'cancel') {
    func(buttonInfo)
    done()
  } else {
    done()
  }
}

export {
  questionOrderSubmitOfflineApiHandler,
  cleanOrderAfterServerSubmitHandler,
  cleanOrderAfterOfflineSubmitHandler,
  standardOrderSubmitOfflineApiHandler,
  cleanOrderAfterReCirculation,
  catchOssUploadErrorHandler
}
