<template>
  <van-nav-bar
    class="nav"
    :class="{ blue: blue }"
    fixed
    z-index="999"
    :placeholder="true"
  >
    <template v-slot:title>
      <span class="nav-title-text">{{ title }}</span>
    </template>
    <template v-slot:left>
      <div class="icon-back" @click="handleBack"></div>
    </template>
    <template v-slot:right>
      <slot name="right"></slot>
    </template>
  </van-nav-bar>
</template>

<script>
export default {
  name: 'MyNav',
  data () {
    return {}
  },
  props: {
    title: {
      type: String,
      default: '工单详情'
    },
    selfBack: {
      type: Boolean,
      default: false
    },
    blue: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  methods: {
    handleBack () {
      // 自定义返回
      if (this.selfBack) {
        this.$emit('back')
      } else {
        // 默认返回
        this.$router.back()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.nav {
  &.blue {
    ::v-deep .van-nav-bar {
      background-color: #3366fe;
      .icon-back {
        background: url("../asset/icon_back_white.png") no-repeat;
        background-size: cover;
      }
      .nav-title-text {
        color: #ffffff;
      }
    }
  }
  .nav-title-text {
    font-size: 18px;
    font-weight: 600;
    color: #222222;
  }
  .icon-back {
    height: 24px;
    width: 24px;
    background: url("../asset/icon_back_black@2x.png") no-repeat;
    background-size: cover;
  }
}
</style>
