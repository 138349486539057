import Vue from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import http from './api/base/index'
import Storage from 'store'
import VueClipboard from 'vue-clipboard2'
import { removeToken } from '@/utils/auth'
import './utils/module_use'
import 'windi.css'
import 'normalize.css'
import '../src/utils/mobile.less'
import '../src/utils/vantui_replace.less'
import 'vant/lib/icon/local.css'// 引用字体css
import '@/asset/iconfont/iconfont.css'
import VConsole from 'vconsole'
import moment from './utils/DYMomentUtils'
import api from '@/api/index'
import globalFilters from '@/utils/globalFilters'
import '@/plugins'
import scanCod from '@/utils/scanCode' // 扫码
import SlsReporter, { wpkglobalerrorPlugin, wpkperformancePlugin, wpkflowPlugin } from 'sls-wpk-reporter'// 监控
import wpkinterfacePlugin from 'sls-wpk-reporter/src/plugins/interface' // api监控
import { apiOffline } from '@/plugins/offlineMixin/apiOfflineGetway'
import mobile from '@/utils/mobile'

import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { Tree } from 'element-ui'
import { Swipe, SwipeItem, Image as VanImage } from 'vant'

// 自定义组件
import components from '@/components/Index'
import voiceInputButton from 'voice-input-button2'

// 手机上显示console.log插件
// let platform = !/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
if (process.env.NODE_ENV === 'development') {
  const vConsole = new VConsole()
  Vue.use(vConsole)
}
removeToken()
mobile.getPlatformInfo() // 获取设备类型 Android IOS

Vue.component(Tree.name, Tree)
Vue.use(components)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(VanImage)
Vue.use(VueClipboard)
Vue.use(VueAwesomeSwiper)
Vue.use(voiceInputButton, {
  appId: '9b714ea8', // 您申请的语音听写服务应用的ID
  apiKey: '0efd0227f76254b59aa86f20abf4f645', // 您开通的语音听写服务的 apiKey
  apiSecret: 'Y2ZlNjZhODE4YTdkNzM2MzZmOTIzM2Iz', // 您开通的语音听写服务的 apiSecret
  color: '#ffffff', // 按钮图标的颜色
  tipPosition: 'top' // 提示条位置
})
Vue.prototype.$storage = Storage
Vue.prototype.$http = http
Vue.prototype.$moment = moment
Vue.config.productionTip = false
Vue.prototype.$api = api
Vue.prototype.$scanCod = scanCod
Vue.prototype.$apiOffline = apiOffline

Object.keys(globalFilters).forEach(filterKey => {
  Vue.filter(filterKey, globalFilters[filterKey])
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
// 阿里监控 配置文档 https://help.aliyun.com/document_detail/300009.htm?spm=a2c4g.11186623.0.0.ef615be904DjHd#task-2104358
const wpk = new SlsReporter({
  bid: 'sls-bd5508fa521efa1f0d',
  project: 'dywu-mobile-m-platform',
  endpoint: 'cn-chengdu.log.aliyuncs.com',
  slsParams: {},
  rel: '1.12.0',
  spa: true,
  plugins: [
    [wpkperformancePlugin], [wpkflowPlugin], [wpkinterfacePlugin],
    [wpkglobalerrorPlugin, { jsErr: true, resErr: true }]
  ]
})
wpk.install() // 初始化。
// wpk.diagnose()// 诊断
