import mobile from '@/utils/mobile'
import { cloneDeep } from 'lodash'
import store from '@/store'
const getAllScanCodeDataSaveKey = () => {
  return 'scanCodeDataAll-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}

const getAllScanCodeData = ({ params }) => {
  return new Promise(async resolve => {
    try {
      const saveDataKey = getAllScanCodeDataSaveKey()
      const result = await mobile.getSaveData(saveDataKey)
      if (result && result.key === saveDataKey) {
        let val = result.value
        resolve(filter(params, val))
      } else {
        resolve()
      }
    } catch (error) {
      console.error('scanCodeOfflineProcessingHandlers', error)
    }
  })
}
// 设置扫码获取信息
const setScanCodeDataAll = async (obj) => {
  return new Promise(async resolve => {
    console.log('setScanCodeDataAll: ', obj)
    let saveData = {
      key: getAllScanCodeDataSaveKey(),
      value: obj.scanCodeDataAll
    }
    await mobile.saveData(saveData)
    resolve('ok')
  })
}

const filter = (params, arr) => {
  // isEnterFM  1为FM  0为承查
  const { qrCode, types, isEnterFM = 1 } = params
  if (!qrCode) return cloneDeep(arr)
  const newArr = arr.reduce((arr, scanCodeQrCode) => {
    const index = scanCodeQrCode.pointTreePointResps.findIndex(point => {
      if (types.includes('2')) { // 是设施设备
        return point.qrCode === qrCode && types.includes(point.type) && isEnterFM === point.isEnterFM
      } else { // 是点位
        return point.qrCode === qrCode && types.includes(point.type)
      }
    })
    if (index > -1) {
      const { pointTreePointResps, ...moreVal } = scanCodeQrCode
      arr.push({ ...moreVal, pointTreePointResps: [pointTreePointResps[index]] })
    }
    return arr
  }, [])
  return cloneDeep(newArr)
}

export {
  getAllScanCodeData,
  setScanCodeDataAll
}
