import api from '@/api/index'
import http from '@/api/base/index'
import system from './system'
import { cloneDeep } from 'lodash'

const dataCache = {
  state: {
    breakDownDataPackage: null // 主要部件故障原因页面所有详情数据
  },
  mutations: {
    SET_BreakDownDataPackage: (state, data) => {
      console.log('模块', system.state.isCJ)
      console.log('模块', system.state.isFM)
      console.log('模块', system.state.isZG)
      /*
      2、东E管（设备管理、问题整改）发起的问题获取【所属模块】FM；
      3、东E管（承接查验）发起的问题获取【所属模块】承查；
      */
      try {
        const model = system.state.isCJ ? '承查' : 'FM'
        const { facilityProblemRecordDataResp = [], equipmentProblemRecordDataResp = [] } = data
        data.facilityProblemRecordDataResp = saveProblemRecordByModel(facilityProblemRecordDataResp, model)
        data.equipmentProblemRecordDataResp = saveProblemRecordByModel(equipmentProblemRecordDataResp, model)
        console.log('data: ', cloneDeep(data))
        state.breakDownDataPackage = data
      } catch (error) {
        console.error(error)
      }
    }
  },
  actions: {
    GetBreakDownDataPackage ({ commit }) {
      http.get(api.getBreakDownDataPackageReq, { dataVersion: 0 }, false).then((res) => {
        commit('SET_BreakDownDataPackage', res)
      })
    }
  }
}
function saveProblemRecordByModel (array, model) {
  const arrayClone = cloneDeep(array)
  return arrayClone.reduce((arr, item) => {
    const { breakDownPartDataRespList = [] } = item
    const breakDownPartDataRespListFilter = breakDownPartDataRespList.reduce((b_arr, it) => {
      const { breakDownCauseItemDataResps = [] } = it
      const breakDownCauseItemDataRespsFilter = breakDownCauseItemDataResps.filter(i => i.model === model)
      it.breakDownCauseItemDataResps = breakDownCauseItemDataRespsFilter
      if (breakDownCauseItemDataRespsFilter.length > 0) {
        it.breakDownCauseItemDataResps = breakDownCauseItemDataRespsFilter
        b_arr.push(it)
      }
      return b_arr
    }, [])
    item.breakDownPartDataRespList = breakDownPartDataRespListFilter
    if (breakDownPartDataRespListFilter.length > 0) {
      arr.push(item)
    }
    return arr
  }, [])
}
export default dataCache
