import { Toast } from 'vant'
let loadingRequestCount = 0 // 接口请求的次数
let showLoading = (text) => {
  if (loadingRequestCount === 0) {
    Toast.loading({
      forbidClick: true,
      message: text || '',
      duration: 0
    })
  }
  loadingRequestCount++
}
let hideLoading = () => {
  if (loadingRequestCount < 0) return
  loadingRequestCount--
  if (loadingRequestCount === 0) {
    Toast.clear()
  }
}
export default {
  showLoading,
  hideLoading
}
