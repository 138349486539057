import mobile from '@/utils/mobile'
import { cloneDeep } from 'lodash'
import store from '@/store'
import { getHomeEquipmentTreeSaveKey, getHomeEquipmentTreeGetGroupSaveKey, getOfflineAreaDataSaveKey } from '@/plugins/offlineMixin/offlineApiHandlers/FifterEquipment/KeepDataOfflineApiHandlers'

const getEquipCategoryOfflineApiHandler = async (params) => { // 获取设施设备tree
  console.log('getEquipCategoryOfflineApiHandler: ', params)
  return new Promise(async resolve => {
    try {
      const saveDataKey = getHomeEquipmentTreeSaveKey()
      const result = await mobile.getSaveData(saveDataKey)
      if (result && result.key === saveDataKey) {
        let val = result.value
        console.log('getEquipCategoryOfflineApiHandler val: ', val)
        if (params.params.projectCodes) {
          let codes = params.params.projectCodes.split(',')
          let tree = cloneDeep(val)
          // 树对象数组 匹配 数组
          removeTreeListItem(tree, codes)
          console.log('过滤后', tree)
          resolve(cloneDeep(tree))
        } else {
          resolve(cloneDeep(val))
        }
      } else {
        resolve()
      }
    } catch (error) {
      console.error('getEquipCategoryOfflineApiHandler', error)
    }
  })
}

const getGroupOfflineApiHandlers = async ({ params }) => { // 获取 设施设备的树,根据项目加载组团
  console.log('getGroupOfflineApiHandlers: ')
  return new Promise(async resolve => {
    try {
      const saveDataKey = getHomeEquipmentTreeGetGroupSaveKey()
      const result = await mobile.getSaveData(saveDataKey)
      console.log('result', result, params)
      if (result && result.key === saveDataKey) {
        let code = params.categoryCode
        let val = result.value
        let filterList = []
        groupFilter(params, val).forEach(i => {
          console.log(i.categoryCodes, code)
          if (i.categoryCodes.includes(code)) { filterList.push(i) }
        })
        console.log('过滤后', filterList)
        resolve(filterList)
      } else {
        resolve()
      }
    } catch (error) {
      console.error('getGroupOfflineApiHandlers', error)
    }
  })
}

const groupFilter = (params, arr) => {
  const { projectCodes } = params
  if (!projectCodes) return cloneDeep(arr)
  const newArr = arr.reduce((arr, item) => {
    if (!projectCodes || item.projectCode === projectCodes) {
      arr.push({ ...item })
    }
    return arr
  }, [])
  return cloneDeep(newArr)
}

const getOfflineAreaDataOfflineApiHandler = async ({ params }) => { // 获取根据分类+组团+项目编码查询空间资产信息
  console.log('getOfflineAreaDataOfflineApiHandler: ')
  return new Promise(async resolve => {
    try {
      const result = await mobile.getSaveData(getOfflineAreaDataSaveKey())
      if (result && result.value) {
        console.log('result---offlineAreaData: ', result, params)
        let val = result.value
        // 整理格式
        resolve(areaDataFilter(params, mergeFacility(val)))
        console.log('过滤后', areaDataFilter(params, mergeFacility(val)))
      } else {
        resolve()
      }
    } catch (error) {
      console.error('getOfflineAreaDataOfflineApiHandler', error)
    }
  })
}

const areaDataFilter = (params, arr) => { // 根据选择的categoryCode groupCode projectCodes 过滤工单
  const { categoryCode, groupCode, projectCodes } = params
  console.log('categoryCode, groupCode, projectCodes: ', categoryCode, groupCode, projectCodes)
  if (!categoryCode && !groupCode && !projectCodes) return cloneDeep(arr)
  const newArr = arr.reduce((arr, item) => {
    if ((!projectCodes || projectCodes.length === 0 || projectCodes.indexOf(item.projectCode) > -1) && (!categoryCode || item.categoryCode === categoryCode) && (!groupCode || item.projectGroupCode === groupCode)) {
      // 对FM/整改  和 承接查验 做区分
      console.log('item: ', item)
      console.log('item.isEnterFM : ', item.isEnterFM)
      const isFM = (store.getters.getIsFM || store.getters.getIsZG) && item.isEnterFM === 1
      console.log('isFM: ', isFM)
      const isCJ = store.getters.getIsCJ && item.isEnterFM === 0
      console.log('isCJ: ', isCJ)
      if (isFM || isCJ) {
        arr.push({ ...item })
      }
    }
    return arr
  }, [])
  return cloneDeep(newArr)
}

const mergeFacility = (arr) => {
  if (!arr) return []
  const newArr = arr.reduce((arr, item) => {
    if (item.list.length > 0) {
      item.list.forEach(fac => {
        arr.push(fac)
      })
    }
    return arr
  }, [])
  return cloneDeep(newArr)
}

// 树过滤 参数：(树,选中的id,过滤字段)
const removeTreeListItem = (treeList, ids) => {
  for (let i = 0; i < treeList.length; i++) {
    // 判断有没有交集
    let isComm = []
    isComm = ids.filter((x) => treeList[i].projectCodes.indexOf(x) !== -1)
    if (isComm.length === 0) {
      treeList.splice(i, 1)
      i--
    }
    if (treeList[i]) {
      if (treeList[i].children) {
        removeTreeListItem(treeList[i].children, ids)
      }
    }
  }
}
export {
  getEquipCategoryOfflineApiHandler,
  getGroupOfflineApiHandlers,
  getOfflineAreaDataOfflineApiHandler
}
