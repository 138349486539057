import storage from 'store'
import { Toast } from 'vant'
import isEmpty from 'lodash/isEmpty'
import { ResponseCodeEnums } from '@/utils/MobileInteractConstant'
const mobile = {
  ISLOCAL: false,
  demoToken: function () {
    console.log('demoToken---------本地调试')
    const token = storage.get('token')
    if (!token) {
      storage.set('token', '2ae7bd76-e4e1-45aa-9a48-56070517d223')
    }
    mobile.ISLOCAL = true
  },
  platform: 'windowBrowser',
  /* 获取设备平台信息 */
  getPlatformInfo: function () {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { // 判断iPhone|iPad|iPod|iOS
      mobile.platform = 'IOS'
    } else if (/(Android)/i.test(navigator.userAgent)) { // 判断Android
      mobile.platform = 'Android'
    } else { // pc
      mobile.platform = 'pc'
    }
  },
  getPlatform: function () {
    return mobile.platform
  },
  /* 获取用户Token */
  getToken: function () {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        mobile.demoToken()
        resolve()
      }
      window.getToken = function (res) {
        storage.set('token', res)
        resolve()
      }
      if (mobile.platform === 'Android') {
        window.android.getToken()
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.getToken.postMessage({ 'status': 'OK!' })
      }
    })
  },
  /* 用于退出webview */
  backWeb: function () {
    if (!window.android && !window.webkit) {
      throw new Error('jsmethod 加载异常')
    }
    window.backWeb = function (res) {
    }
    if (mobile.platform === 'Android') {
      window.android.backWeb()
    } else if (mobile.platform === 'IOS') {
      window.webkit.messageHandlers.backWeb.postMessage({ 'status': 'OK!' })
    }
  },
  /* 调用相机 */
  getPicData: function (cb, name) {
    try {
      if (!window.android && !window.webkit) {
        throw new Error('jsmethod 加载异常')
      }
      if (mobile.platform === 'Android') {
        window.getPicData = res => {
          cb(res)
        }
        window.android.openPic(1, name)
      } else if (mobile.platform === 'IOS') {
        window.getPicData = res => {
          cb(res)
        }
        window.webkit.messageHandlers.openPic.postMessage({
          project: name,
          mode: 1
        })
      } else {
        return false
      }
    } catch (e) {
      console.log('----------phone error:', e.message)
    }
  },
  /*  */
  editPicture: function (params) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      window.editPictureResult = (res) => {
        const result = handleAppResponse(res)
        resolve(result)
      }
      // 传个空字符串，不然会报错 Not enough arguments
      const voiceInputPostBody = {
        cmdType: 'editPicture',
        params,
        callbackMethod: 'editPictureResult'
      }
      console.log('voiceInputPostBody: ', voiceInputPostBody)
      const editPictucePostBodyJsonString = JSON.stringify(voiceInputPostBody)
      if (mobile.platform === 'Android') {
        window.android.deg(editPictucePostBodyJsonString)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.deg.postMessage(editPictucePostBodyJsonString)
      }
    })
  },
  /* 扫码功能 */
  getQRCodeInfo: function () {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      window.getQRCodeInfo = (res) => {
        const match = res.trim().match(/scene=([A-Z|a-z|0-9|-]*)/) // 适配小程序参数码
        console.log('扫码结果：', res.trim(), ', 匹配结果：', match)
        if (match && match[1]) {
          resolve(match[1])
        } else {
          resolve(res.trim()) // 需要去掉头尾空格
        }
      }
      if (mobile.platform === 'Android') {
        window.android.openQR()
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.openQR.postMessage(1)
      } else {
        reject(new Error(`设备类型未知:${mobile.platform}`))
      }
    })
  },
  // 存储数据
  saveData: function (obj) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      let data = JSON.stringify(obj)
      if (mobile.platform === 'Android') {
        window.android.saveData(data)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.saveData.postMessage(data)
      } else {
        reject(new Error(`设备类型未知:${mobile.platform}`))
      }
      window.saveDataSuccess = () => {
        resolve()
      }
    })
  },
  // 获取数据、
  getSaveData: function (key) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      window.getSaveData = (res) => {
        // 如果取值不为空但是value为空 或者 空数组空对象 就直接删掉这个key的缓存
        if (res && (res.key === key) && (!res.value || isEmpty(res.value))) { // 当值为空时就删掉
          this.deleteSaveData(key)
          res = null
        }
        resolve(res)
      }
      if (mobile.platform === 'Android') {
        window.android.getSaveData(key)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.getSaveData.postMessage(key)
      } else {
        reject(new Error(`设备类型未知:${mobile.platform}`))
      }
    })
  },
  // 删除保存数据
  deleteSaveData: function (key) {
    if (!window.android && !window.webkit) {
      return
    }
    if (mobile.platform === 'Android') {
      window.android.deleteData(key)
    } else if (mobile.platform === 'IOS') {
      window.webkit.messageHandlers.deleteData.postMessage(key)
    }
  },
  // 获取网络情况
  getNetState: function (cb) {
    // return new Promise((resolve) => {
    if (!window.android && !window.webkit) {
      const res = 2
      cb && cb(res)
      return
    }
    if (mobile.platform === 'Android') {
      window.android.getNetState()
      window.returnNetState = (res) => {
        console.log(`receiveNetState from mobile android:${res}`)
        cb(mobile._transformNet(res))
      }
    } else if (mobile.platform === 'IOS') {
      window.webkit.messageHandlers.getNetState.postMessage(1)
      window.returnNetState = (res) => {
        console.log(`receiveNetState from mobile ios:${res}`)
        cb(mobile._transformNet(res))
      }
    }
    // })
  },
  _transformNet: function (netState) {
    if (netState === 'a' || netState === 'false') {
      return 0
    }
    if (netState === 'b') {
      return 1
    }
    if (netState === 'c' || netState === 'true') {
      return 2
    }
    if (Number(netState) === 0 || Number(netState) === 1 || Number(netState) === 2) {
      return Number(netState)
    }
    return 2
  },
  // 通过链接获取base64图片,用于下载离线包中的图片缓存
  getOssConversionBase64: function (url, cb) {
    if (!window.android && !window.webkit) {
      cb && cb()
      return
    }
    if (mobile.platform === 'Android') {
      window.setImageBase64 = (res) => {
        let result = `data:image/png;base64,${res}`
        cb(result)
      }
      window.android.getImageBase64(url)
    } else if (mobile.platform === 'IOS') {
      window.setImageBase64 = (res) => {
        let result = `data:image/png;base64,${res}`
        cb(result)
      }
      window.webkit.messageHandlers.getImageBase64.postMessage(url)
    }
  },
  saveDataBase64 (data, cb) {
    const base64 = data.split('data:image/jpeg;base64,')[1]
    if (mobile.platform === 'Android') {
      window.saveDataBase64 = (key) => {
        cb(key)
      }
      window.android.saveDataBase64(base64)
    } else if (mobile.platform === 'IOS') {
      window.saveDataBase64 = (key) => {
        cb(key)
      }
      window.webkit.messageHandlers.saveDataBase64.postMessage(base64)
    }
  },
  getCurrentProject: function () {
    return new Promise((resolve, reject) => {
      if (mobile.platform === 'Android') {
        window.getCurrentProject = (result) => {
          resolve(JSON.parse(result))
        }
        window.android.getCurrentProject()
      } else if (mobile.platform === 'IOS') {
        window.getCurrentProject = (result) => {
          resolve(JSON.parse(result))
        }
        window.webkit.messageHandlers.getCurrentProject.postMessage(1)
      }
    })
  },
  // key获取base64图片，用于拍照返回的key获取图片
  getSaveDataBase64: function (key) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      window.getSaveDataBase64 = (res) => {
        let result = `data:image/png;base64,${res}`
        resolve(result)
      }
      if (mobile.platform === 'Android') {
        window.android.getSaveDataBase64(key)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.getSaveDataBase64.postMessage(key)
      }
    })
  },
  // key获取base64图片，用于拍照返回的key获取图片
  pushNative: function (obj) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      window.pushNativeResult = (res) => {
        console.log('pushNative res: ', res)
        resolve()
      }
      const objString = JSON.stringify(obj)
      console.log('objString: ', objString)
      if (mobile.platform === 'Android') {
        window.android.pushNative(objString)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.pushNative.postMessage(objString)
      }
    })
  },
  // 语音
  voiceInput: function (cb) {
    window.voiceInputResult = (res) => {
      const result = handleAppResponse(res)
      cb(result)
    }
    // 传个空字符串，不然会报错 Not enough arguments
    const voiceInputPostBody = {
      cmdType: 'openVoiceInput',
      params: '',
      callbackMethod: 'voiceInputResult'
    }
    const voiceInputPostBodyJsonString = JSON.stringify(voiceInputPostBody)
    if (mobile.platform === 'Android') {
      window.android.deg(voiceInputPostBodyJsonString)
    } else if (mobile.platform === 'IOS') {
      window.webkit.messageHandlers.deg.postMessage(voiceInputPostBodyJsonString)
    }
  },
  openCommonPic: function (params) {
    return new Promise((resolve, reject) => {
      window.getPicDatas = (res) => {
        const result = handleAppResponse(res)
        resolve(result)
      }

      const picPostBody = {
        cmdType: 'takePhoto', // APP指定值
        params,
        callbackMethod: 'getPicDatas'
      }
      const picPostBodyString = JSON.stringify(picPostBody)
      if (mobile.platform === 'Android') {
        window.android.deg(picPostBodyString)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.deg.postMessage(picPostBodyString)
      }
    })
  },
  uploadBase64ByAppRequestWithFixFileName: function (params) {
    return new Promise((resolve) => {
      // app 回调 h5 js method
      window.uploadBase64Result = (response) => {
        const result = handleAppResponse(response)
        resolve({ name: 'test.jpeg', url: result.url, fileType: 'jpeg', content: result.url })
      }
      // 调用app js method
      let data = JSON.stringify(params)
      if (mobile.platform === 'Android') {
        window.android.uploadBase64ByApp(data)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.uploadBase64ByApp.postMessage(data)
      }
    })
  },
  deleteImgsInCache: function (list) {
    try {
      // 调用app js method
      if (!list) return
      let data = typeof list === 'string' ? list : JSON.stringify(list)
      if (JSON.parse(data).length === 0) return
      if (mobile.platform === 'Android') {
        window.android.deleteImgsInCache(data)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.deleteImgsInCache.postMessage(data)
      }
      return Promise.resolve()
    } catch (e) {
      return Promise.resolve()
    }
  },
  /* -----根据orderId删除相关图片--- */
  delImgsByOrderId: function (params = {}) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      typeof params.orderId === 'string' && (params.orderId = params.orderId * 1)
      window.delImgsByOrderIdRes = (res) => {
        const result = handleAppResponse(res)
        resolve(result)
      }
      const requestBody = {
        cmdType: 'delImgsByOrderId',
        params,
        callbackMethod: 'delImgsByOrderIdRes'
      }
      const requestBodyJsonString = JSON.stringify(requestBody)
      if (mobile.platform === 'Android') {
        window.android.deg(requestBodyJsonString)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.deg.postMessage(requestBodyJsonString)
      }
    })
  },
  /* -----工单表接口--start--- */
  // ⼯单列表
  getOrderList: function (params) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      window.getOrderListRes = (res) => {
        const result = handleAppResponse(res)
        resolve(result)
      }
      const requestBody = {
        cmdType: 'getOrderList',
        params,
        callbackMethod: 'getOrderListRes'
      }
      const requestBodyJsonString = JSON.stringify(requestBody)
      if (mobile.platform === 'Android') {
        window.android.deg(requestBodyJsonString)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.deg.postMessage(requestBodyJsonString)
      }
    })
  },
  showOrderList: function (params) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      window.showOrderListRes = (res) => {
        const result = handleAppResponse(res)
        resolve(result)
      }
      const requestBody = {
        cmdType: 'showOrderList',
        params,
        callbackMethod: 'showOrderListRes'
      }
      const requestBodyJsonString = JSON.stringify(requestBody)
      if (mobile.platform === 'Android') {
        window.android.deg(requestBodyJsonString)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.deg.postMessage(requestBodyJsonString)
      }
    })
  },
  // ⼯单详情
  getOrder: function (params) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      window.getOrderRes = (res) => {
        const result = handleAppResponse(res)
        resolve(result)
      }
      const requestBody = {
        cmdType: 'getOrder',
        params,
        callbackMethod: 'getOrderRes'
      }
      const requestBodyJsonString = JSON.stringify(requestBody)
      if (mobile.platform === 'Android') {
        window.android.deg(requestBodyJsonString)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.deg.postMessage(requestBodyJsonString)
      }
    })
  },
  // 删除⼯单
  deleteOrder: function (params) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      window.deleteOrderRes = (res) => {
        const result = handleAppResponse(res)
        resolve(result)
      }
      // 将params里的orderIds转为数字
      for (let orderId of params.orderIds) {
        typeof orderId === 'string' && (orderId = orderId * 1)
      }
      const requestBody = {
        cmdType: 'deleteOrder',
        params,
        callbackMethod: 'deleteOrderRes'
      }
      const requestBodyJsonString = JSON.stringify(requestBody)
      if (mobile.platform === 'Android') {
        window.android.deg(requestBodyJsonString)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.deg.postMessage(requestBodyJsonString)
      }
    })
  },
  // 保存⼯单
  saveOrder: function (params) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      window.saveOrderRes = (res) => {
        const result = handleAppResponse(res)
        resolve(result)
      }
      const requestBody = {
        cmdType: 'saveOrder',
        params,
        callbackMethod: 'saveOrderRes'
      }
      const requestBodyJsonString = JSON.stringify(requestBody)
      if (mobile.platform === 'Android') {
        window.android.deg(requestBodyJsonString)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.deg.postMessage(requestBodyJsonString)
      }
    })
  },
  /* -----工单表接口--end--- */
  /* -----近三天表--start--- */
  // 近三天列表
  getRecentOrderList: function (params) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      window.getRecentOrderListRes = (res) => {
        const result = handleAppResponse(res)
        resolve(result)
      }
      const requestBody = {
        cmdType: 'getRecentOrderList',
        params,
        callbackMethod: 'getRecentOrderListRes'
      }
      const requestBodyJsonString = JSON.stringify(requestBody)
      if (mobile.platform === 'Android') {
        window.android.deg(requestBodyJsonString)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.deg.postMessage(requestBodyJsonString)
      }
    })
  },
  // 保存近三天工单数据
  saveRecentOrder: function (params = {}) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      window.saveRecentOrderRes = (res) => {
        const result = handleAppResponse(res)
        resolve(result)
      }
      const requestBody = {
        cmdType: 'saveRecentOrder',
        params,
        callbackMethod: 'saveRecentOrderRes'
      }
      const requestBodyJsonString = JSON.stringify(requestBody)
      if (mobile.platform === 'Android') {
        window.android.deg(requestBodyJsonString)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.deg.postMessage(requestBodyJsonString)
      }
    })
  },
  // 保存近三天工单数据
  deleteRecentOrder: function (params = {}) {
    return new Promise((resolve, reject) => {
      if (!window.android && !window.webkit) {
        reject(new Error('不是移动设备'))
      }
      for (let orderId of params.orderIds) {
        typeof orderId === 'string' && (orderId = orderId * 1)
      }
      window.deleteRecentOrderRes = (res) => {
        const result = handleAppResponse(res)
        resolve(result)
      }
      const requestBody = {
        cmdType: 'deleteRecentOrder',
        params,
        callbackMethod: 'deleteRecentOrderRes'
      }
      const requestBodyJsonString = JSON.stringify(requestBody)
      if (mobile.platform === 'Android') {
        window.android.deg(requestBodyJsonString)
      } else if (mobile.platform === 'IOS') {
        window.webkit.messageHandlers.deg.postMessage(requestBodyJsonString)
      }
    })
  },
  // 给app颜色看看 背景色,文字色 注意进制码需要大写字母
  setHeaderBackColor: function (BgColor, textColor) {
    console.log('BgColor, textColor: ', BgColor, textColor)
    console.log('mobile.platform: ', mobile.platform)
    try {
      if (mobile.platform === 'Android') {
        window.android.headerBackColor(BgColor, textColor)
      } else if (mobile.platform === 'IOS') {
        console.log('window.webkit: ', window.webkit)
        if (window.webkit) {
          window.webkit.messageHandlers.headerBackColor.postMessage(BgColor)
        }
      }
    } catch (error) {
      console.error('error: ', error)
    }
  }
  /* -----近三天表--end--- */
}

function handleAppResponse (res) {
  const objRes = typeof res === 'object' ? res : JSON.parse(res)
  if (objRes.code === ResponseCodeEnums.success) {
    return objRes.result
  } else {
    Toast.fail(objRes.error)
    throw new Error(res)
  }
}
export default mobile
