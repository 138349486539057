// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./iconfont.woff2?t=1663310897858");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./iconfont.woff?t=1663310897858");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./iconfont.ttf?t=1663310897858");
exports = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "@font-face{font-family:iconfont;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\")}.iconfont{font-family:iconfont!important;font-size:4.27vw;font-style:normal;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;content:\"viewport-units-buggyfill; font-size: 4.27vw\"}.icon-arrow-down:before{content:\"\\e621\"}", "",{"version":3,"sources":["iconfont.css","<no source>"],"names":[],"mappings":"AAAA,WACE,oBAAuB,CACvB,6KAGF,CAEA,UACE,8BAAkC,CAClC,gBAAe,CACf,iBAAkB,CAClB,kCAAmC,CACnC,iCAAkC,CCZpC,qDDaA,CAEA,wBACE,eACF","file":"iconfont.css","sourcesContent":["@font-face {\n  font-family: \"iconfont\"; /* Project id 3652182 */\n  src: url('iconfont.woff2?t=1663310897858') format('woff2'),\n       url('iconfont.woff?t=1663310897858') format('woff'),\n       url('iconfont.ttf?t=1663310897858') format('truetype');\n}\n\n.iconfont {\n  font-family: \"iconfont\" !important;\n  font-size: 16px;\n  font-style: normal;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\n.icon-arrow-down:before {\n  content: \"\\e621\";\n}\n\n",null]}]);
// Exports
module.exports = exports;
