
import { cloneDeep } from 'lodash'
// import tools from '@/utils/tools.js'
/**
 * 更新日期：2022-10-25
 * 【————————order_type————————】
 * 【1】自查公区
 * 【2】自查房间
 * 【3】自查设施设备
 * 【4】客户公区
 * 【5】客户房间
 * 【6】客户设施设备
 * 【7】品控任务
 * 【8】设备巡检任务
 * -----废除----【9】升级工单
 * 【10】设备保养任务
 * 【11】巡逻巡更
 * 【12】设施设备告警
 * 【13】监控告警
 * 【14】品控工单
 * 【15】承接查验任务工单
 * 【16】远程监控任务工单
 * 【17】远程监控问题工单
 *
 * 【————————order_nature————————】
 * 【0】常规工单
 * 【1】投诉工单
 * 【2】差评工单
 * 【3】快速工单
 * 【4】升级工单
 * 【5】品检工单
 * 【6】承接查验工单
 *
 * 【————————detail.task_type————————】
 * 【————————taskItem.taskType————————】
 * 【1】设施设备
 * 【2】点位
 * 【3】标准
 * 【4】标准&点位
 * 【5】动线
 * 【6】分类&标准
 *
 */
const order = {
  state: {
    /* 工单类型配置 */
    orderTypeConf: {
      taskOrderTypes: [7, 8, 10, 15, 16], // 任务工单 意为 系统排程生成的工单 / 标准下的工单
      normalOrderTypes: [1, 2, 3, 4, 5, 6, 11, 12, 13, 14, 17], // 普通工单 意为 用户生成的工单
      equipmentOrderTypes: [3, 6], // 设施设备工单（能升级）
      publicAreaOrderTypes: [1, 4], // 公区工单
      /*
        设备任务工单：
        1、扫码进入执行页取qrCode
      */
      taskOrderTypes_FM: [8, 10, 15], // 设施设备任务工单
      /*
        设备任务工单：
        1、扫码进入执行页取itemCode
      */
      taskOrderTypes_PK: [7] // 品控任务工单
    },
    /* 工单性质配置 */
    orderNatureDic: [
      { order_nature: 0, tagColor: '#BE45F4' },
      { order_nature: 1, tagColor: '#F44A45' },
      { order_nature: 2, tagColor: '#FD8D37' },
      { order_nature: 3, tagColor: '#28C469' },
      { order_nature: 4, tagColor: '#FD8D37' },
      { order_nature: 5, tagColor: '#FD8D37' },
      { order_nature: 6, tagColor: '#BE45F4' }
    ],
    cacheOrderNodeCodes: ['yijiedan', 'chulizhong'],
    /* 工单配置end */
    detail: {},
    patroItem: {}
  },
  getters: {
  },
  mutations: {
    setDetail (state, detail) {
      state.detail = cloneDeep(detail)
      console.log('order detail: ', state.detail)
    },
    setPatroItem (state, patroItem) {
      state.patroItem = patroItem
      console.log('vuex 任务项patroItem: ', state.patroItem)
    }
  },
  actions: {
  }

}

export default order
