var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-wrapper" },
    [
      _c("van-field", {
        staticClass: "form-upload",
        attrs: {
          name: "attachmentList",
          required: _vm.required,
          label: `${_vm.text}(可上传${_vm.maxCount}张)`,
          disabled: _vm.disabled,
        },
        scopedSlots: _vm._u([
          {
            key: "input",
            fn: function () {
              return [
                _c("div", { staticClass: "upload-list-box" }, [
                  _c(
                    "div",
                    { staticClass: "uplaod-list-wrapper" },
                    [
                      _vm._l(_vm.imgList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "upload-list" },
                          [
                            _c("img", {
                              staticClass: "upload-img",
                              attrs: { src: item },
                              on: {
                                click: function ($event) {
                                  return _vm.imgPreview(index)
                                },
                              },
                            }),
                            _c("i", {
                              staticClass: "delete-icon",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.deleteFile(index, item)
                                },
                              },
                            }),
                          ]
                        )
                      }),
                      !_vm.file || _vm.file.length < _vm.maxCount
                        ? _c(
                            "p",
                            {
                              staticClass: "upload-bg",
                              on: { click: _vm.uploadApp },
                            },
                            [_c("van-icon", { attrs: { name: "plus" } })],
                            1
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }