const dashBoardStore = {
  state: {
    dashBoardRefreshMixin: false
  },
  mutations: {
    changeDashBoardRefreshMixin (state, bool = true) {
      console.log('changeDashBoardRefreshMixin: ', bool)
      state.dashBoardRefreshMixin = bool
    }
  }
}
export default dashBoardStore
