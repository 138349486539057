import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import mobile from '@/utils/mobile'
import { getToken } from '@/utils/auth'
import { isEmpty } from 'lodash'
import tools from '@/utils/tools'

Vue.use(VueRouter)
// 解决重复点击导航路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const commonQuery = function () {
  return store.getters.getPageType ? { pageType: store.getters.getPageType } : {}
}
/*
  meta:{
    keepAlive:true // 需要页面缓存，没有或为false则不缓存
  }
*/
const routes = [
  {
    path: '/',
    redirect: '/Dashboard'
  },
  /** ****  工单中心 start *****/
  {
    path: '/Dashboard',
    name: 'Dashboard',
    meta: { title: '品控任务', keepAlive: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/Dashboard')
  },
  {
    path: '/OrderCirculation',
    name: 'OrderCirculation',
    meta: { title: '工单流转' },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/OrderCirculation')
  },
  {
    path: '/RemoteMonitorOrderRecheck',
    name: 'RemoteMonitorOrderRecheck',
    meta: { title: '工单复核' },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/RemoteMonitorOrderRecheck')
  },
  {
    path: '/OrderDetail',
    name: 'OrderDetail',
    meta: { title: '工单详情', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/OrderDetail')
  },
  {
    path: '/OrderUrge',
    name: 'OrderUrge',
    meta: { title: '工单催办' },
    component: () => import('@/pages/OrderUrge')
  },
  {
    path: '/Evaluation',
    name: 'Evaluation',
    meta: { title: '工单评价' },
    component: () => import('@/pages/Evaluation')
  },
  {
    path: '/MalfunctionGuide',
    name: 'MalfunctionGuide',
    meta: { title: '故障指导' },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/MalfunctionGuide')
  },
  {
    /* 操作工单时的问题记录 */
    path: '/problemRecord',
    name: 'problemRecord',
    meta: { title: '问题记录', auth: true, keepAlive: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/problemRecord')
  },
  {
    path: '/SampleResultsList',
    name: 'SampleResultsList',
    meta: { title: '抽查结果记录' },
    component: () => import('@/pages/SampleResultsList')
  },
  {
    path: '/SampleResultsDetails',
    name: 'SampleResultsDetails',
    meta: { title: '抽查结果详情' },
    component: () => import('@/pages/SampleResultsDetails')
  },
  {
    path: '/reportForward',
    name: 'ReportForward',
    meta: { title: '转发设施设备', auth: true },
    component: () => import('@/pages/ReportForward')
  },
  /** ****  工单中心 end *****/
  /* 预制二维码---strat */
  {
    path: '/QRCodeScan',
    name: 'QRCodeScan',
    meta: { title: '设备数据', auth: true },
    component: () => import(/* webpackChunkName: 'QRCodeScan' */'@/pages/preMadeQRCode/scan')
  },
  {
    path: '/signIn',
    name: 'signIn',
    meta: { title: '扫码签到', auth: true },
    component: () => import('@/pages/preMadeQRCode/signIn/signInSuccess.vue')
  },
  {
    path: '/QRCodeScan/QRCodeRegister',
    name: 'QRCodeRegister',
    meta: { title: '设施/设备台账', auth: true },
    component: () => import(/* webpackChunkName: 'QRCodeScan' */'@/pages/preMadeQRCode/register')
  },
  {
    path: '/QRCodeScan/QRCodeNoRegister',
    name: 'QRCodeNoRegister',
    meta: { title: '设施/设备台账', auth: true, keepAlive: true },
    component: () => import(/* webpackChunkName: 'QRCodeScan' */'@/pages/preMadeQRCode/noRegister')
  },
  {
    path: '/QRCodeScan/QRCodeNoRegister/equipList',
    name: 'equipList',
    meta: { title: '设施分类列表', auth: true },
    component: () => import(/* webpackChunkName: 'QRCodeScan' */'@/pages/preMadeQRCode/component/equipList')
  },
  {
    path: '/QRCodeScan/QRCodeNoRegister/facilityList',
    name: 'facilityList',
    meta: { title: '设施分类列表', auth: true },
    component: () => import(/* webpackChunkName: 'QRCodeScan' */'@/pages/preMadeQRCode/component/facilityList')
  },
  {
    path: '/QRCodeScan/QRCodeNoRegister/projectList',
    name: 'projectList',
    meta: { title: '所在项目列表', auth: true },
    component: () => import(/* webpackChunkName: 'QRCodeScan' */'@/pages/preMadeQRCode/component/projectList')
  },
  {
    path: '/QRCodeScan/tobeUpload',
    name: 'tobeUpload',
    meta: { title: '待上传离线设备数据', auth: true },
    component: () => import(/* webpackChunkName: 'QRCodeScan' */'@/pages/preMadeQRCode/component/tobeUpload/index.vue')
  },
  /* 预制二维码---end */
  {
    path: '/CodeOrderList',
    name: 'CodeOrderList',
    meta: { title: '品质任务查询结果', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/CodeOrderList')
  },
  {
    path: '/PatrolList',
    name: 'PatrolList',
    meta: { title: '执行工单', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/PatrolList')
  },
  {
    path: '/ResultDetails',
    name: 'ResultDetails',
    meta: { title: '任务抽查结果详情', auth: true },
    component: () => import(/* webpackChunkName: '' */'@/pages/PatrolList/components/ResultDetails.vue')
  },
  {
    path: '/CreateProblemRecord',
    name: 'CreateProblemRecord',
    meta: { title: '问题记录', auth: true, keepAlive: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/CreateProblemRecord/index')
  },
  {
    path: '/processingList',
    name: 'processingList',
    meta: { title: '处理中工单', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/processingList/index')
  },
  {
    path: '/OfflineList',
    name: 'OfflineList',
    meta: { title: '待上传离线任务列表', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/OfflineList/index')
  },
  {
    path: '/HistoryRecord',
    name: 'HistoryRecord',
    meta: { title: '近3天处理记录', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/HistoryRecord/index')
  },
  {
    path: '/PointList',
    name: 'PointList',
    meta: { title: '点位列表', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/PointList/index')
  },
  {
    path: '/orderCheck',
    name: 'orderCheck',
    meta: { title: '工单审核', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/ReportOrder/OrderCheck/index')
  },
  {
    path: '/OrderCheckDetail',
    name: 'OrderCheckDetail',
    meta: { title: '审核详情', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/ReportOrder/OrderCheckDetail/index')
  },
  {
    path: '/OrderChargeDetail',
    name: 'OrderChargeDetail',
    meta: { title: '收费详情', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/ReportOrder/OrderCharge/detail/index')
  },
  {
    path: '/OrderChargeAdd',
    name: 'OrderChargeAdd',
    meta: { title: '收费详情', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/ReportOrder/OrderCharge/add/index')
  },
  {
    path: '/ReportOrderPool',
    name: 'ReportOrderPool',
    meta: { title: '工单池', auth: true, keepAlive: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/ReportOrder/ReportOrderPool/index')
  },
  {
    path: '/ReportOrderDetail',
    name: 'ReportOrderDetail',
    meta: { title: '工单详情', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/ReportOrder/ReportOrderDetail/index')
  },
  {
    path: '/ReportTousuList',
    name: 'ReportTousuList',
    meta: { title: '投诉还原详情', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/ReportOrder/ReportTousuList/index')
  },
  {
    path: '/ReportTousuAdd',
    name: 'ReportTousuAdd',
    meta: { title: '新增投诉还原', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/ReportOrder/ReportTousuAdd/index')
  },
  {
    path: '/CreateOrder',
    name: 'CreateOrder',
    meta: { title: '创建工单', auth: true, keepAlive: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/ReportOrder/CreateOrder/index')
  },
  {
    path: '/OrderSpotCheck',
    name: 'OrderSpotCheck',
    meta: { title: '工单抽查', auth: true, keepAlive: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/OrderSpotCheck/index')
  },
  {
    path: '/OrderSpotCheckDetail',
    name: 'OrderSpotCheckDetail',
    meta: { title: '工单抽查详情', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/OrderSpotCheck/detail/index')
  },
  {
    path: '/OrderSpotCheckHandle',
    name: 'OrderSpotCheckHandle',
    meta: { title: '工单抽查处理', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/OrderSpotCheck/handle/index')
  },
  {
    path: '/noAuth',
    name: 'noAuth',
    meta: { title: '无权限', auth: true },
    component: () => import(/* webpackChunkName: 'dashboard' */'@/pages/noAuth/index')
  }
]

const router = new VueRouter({
  routes
})

// 修改底层方法
const transitionTo = router.history.transitionTo // 临时存储底层方法
router.history.transitionTo = function (location, onComplete, onAbort) {
  location = typeof location === 'object' ? { ...location, query: { ...location.query, ...commonQuery() } } : { path: location, query: commonQuery() }
  transitionTo.call(router.history, location, onComplete, onAbort)
}

router.beforeEach(async (to, from, next) => {
  // 将router跳转记录保存在vuex
  store.commit('setRouter', {
    toName: to.name,
    fromName: from.name
  })
  /* 路由发生变化修改页面title */
  if (to.meta.title) document.title = to.meta.title
  if (!store.getters.getPageType) {
    const pageType = tools.getQueryString('pageType')
    store.commit('setPageType', pageType) // 设置项目类型
  }
  // next()
  const token = getToken()
  if (token) {
    if (isEmpty(store.getters.getUserInfo)) {
      store.dispatch('GetUserInfo').then(() => {
        next()
      })
    } else {
      next()
    }
  } else {
    mobile.getToken().then(() => {
      store.dispatch('GetUserInfo').then(() => {
        next()
      })
    })
  }
})

export default router
