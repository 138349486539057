// 取消axios的请求的函数封装
const _initData = {
  cancelList: []
}
const getCancelList = () => {}
/**
 * 向取消列表里面添加记录
 * @param {Object} obj {key: 'xxxxx',f: 'fn()'}
 */
const pushCancel = obj => {
  let { cancelList } = _initData
  if (cancelList.length > 0) {
    let isOld = cancelList.some((item) => {
      return item.key === obj.key
    })
    if (isOld) {
      cancelList.forEach((item, index) => {
        // 如果存在相同的就去掉相同的之后再push
        if (item.key === obj.key) {
          cancelList.splice(index, 1)
          cancelList.push(obj)
        }
      })
    } else {
      cancelList.push(obj)
    }
  } else {
    cancelList.push(obj)
  }
}
/**
 * 向取消列表里面删除记录
 * @param {String} key 'xxx'
 */
const removeCancel = key => {
  let { cancelList } = _initData
  let index = cancelList.findIndex(item => item.key === key)
  cancelList.splice(index, 1)
}
/**
 * 执行取消数组里面的接口进行取消
 */
const deleteAllCancel = () => {
  let { cancelList } = _initData
  for (let i = 0; i < cancelList.length; i++) {
    let item = cancelList[i]
    // 执行取消的方法
    item.f()
    cancelList.splice(i, 1)
    i--
  }
}
export {
  pushCancel,
  getCancelList,
  removeCancel,
  deleteAllCancel
}
