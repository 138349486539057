
const dashboardMixin = {
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    isDashBoardPage () {
      return this.$route.name === 'Dashboard'
    },
    dashBoardRefreshMixin () {
      return this.$store.state.dashBoardStore.dashBoardRefreshMixin
    }
  },
  watch: {
    isOffline (newVal) {
      console.log('dashboardMixin: ', newVal, this.isDashBoardPage)
      !this.isDashBoardPage && this.$store.commit('changeDashBoardRefreshMixin') // 当前不在bashBoard时，如果网络状态变化,改变dashBoardRefreshMixin
    }
  }
}
export default dashboardMixin
