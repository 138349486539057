const OFFLINE_REQUEST_SUCCESS = 1000
const ONLINE_REQUEST_SUCCESS = 200

/**
 * 暂存字段
 * @type {string[]}
 */
const TEMPORARY_STORAGE_FIELDS = [
  'orderTaskItemExtModelList',
  'problemItemResps',
  'pinkongProblemReq',
  'form',
  'buttonInfo',
  'finalScore',
  'totalScore'
]

const UPDATE_RESET_FIELDS_BY_MODEL = {
  'planEnd': 'plan_end',
  'planStart': 'plan_start',
  'handlerName': 'handler_name',
  'templateNodeName': 'node_name',
  'orderStatusName': 'node_name'
}

const OSS_UPLOAD_ERRORCODE = ['ConnectionTimeoutError', 'RequestError']

export {
  OFFLINE_REQUEST_SUCCESS,
  ONLINE_REQUEST_SUCCESS,
  TEMPORARY_STORAGE_FIELDS,
  OSS_UPLOAD_ERRORCODE,
  UPDATE_RESET_FIELDS_BY_MODEL
}
