import mobile from '@/utils/mobile'
import store from '@/store'
import { cloneDeep } from 'lodash'
const dashBoardProjectInfoSaveKey = 'projectInfo'
const reportQueryFormSaveKey = 'reportQueryForm'
const createProblemProjectInfoSaveKey = 'createProblemProjectInfo'

// 设置首页离线选择的project数据
const setProjectInfoHandler = async (obj) => {
  console.log('setPJPARMAS', obj)
  if (!obj || !obj.params || !obj.params.checkList) return
  if (obj.params.checkList && obj.params.checkList.length > 0) {
    let saveData = {
      key: dashBoardProjectInfoSaveKey,
      value: obj.params
    }
    await mobile.saveData(saveData)
  } else {
    delProjectInfoHandler()
  }
}

// 工单池记忆参数
const setReportQueryFormHandler = async (obj) => {
  let saveData = {
    key: reportQueryFormSaveKey,
    value: obj.params
  }
  console.log('setReportQueryFormHandler: ', saveData)
  await mobile.saveData(saveData)
}
/*
  获取工单池记忆参数
 */
const getReportQueryFormHandler = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await mobile.getSaveData(reportQueryFormSaveKey)
      console.log('getReportQueryFormHandler', result)
      if (result && result.value) {
        resolve(cloneDeep(result.value))
      } else {
        resolve()
      }
    } catch (error) {
      reject(new Error('获取本地项目参数错误'))
    }
  })
}
/*
  获取首页缓存项目数据
 */
const getProjectInfoHandler = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await mobile.getSaveData(dashBoardProjectInfoSaveKey)
      console.log('projectInfo', result)
      if (result && result.value) {
        const res = _setLocalProjectInfo(result.value)
        console.log('res: ', res)
        resolve(cloneDeep(res))
      } else {
        resolve()
      }
    } catch (error) {
      reject(new Error('获取本地项目参数错误'))
    }
  })
}
/* 删除首页缓存项目数据 */
const delProjectInfoHandler = () => {
  try {
    mobile.deleteSaveData(dashBoardProjectInfoSaveKey)
  } catch (error) {
    console.error('delProjectInfoHandler', error)
  }
}

// ================创建问题记录选择的project数据==================

// 设置创建问题记录选择的project数据
const setCreateProblemProjectInfoHandler = async (obj) => {
  console.log('setPJPARMAS', obj)
  if (!obj || !obj.params || !obj.params.checkList) return
  if (obj.params.checkList && obj.params.checkList.length > 0) {
    let saveData = {
      key: createProblemProjectInfoSaveKey,
      value: obj.params
    }
    await mobile.saveData(saveData)
  } else {
    delCreateProblemProjectInfoHandler()
  }
}
/*
  获取创建问题记录缓存项目数据
 */
const getCreateProblemProjectInfoHandler = async () => {
  return new Promise(async (resolve) => {
    try {
      const result = await mobile.getSaveData(createProblemProjectInfoSaveKey)
      console.log('projectInfo', result)
      if (result && result.value) {
        const res = _setLocalProjectInfo(result.value)
        console.log('res: ', res)
        resolve(cloneDeep(res))
      } else {
        resolve()
      }
    } catch (error) {
      console.error('getCreateProblemProjectInfoHandler', error)
    }
  })
}
/* 删除创建问题记录缓存项目数据 */
const delCreateProblemProjectInfoHandler = () => {
  try {
    mobile.deleteSaveData(createProblemProjectInfoSaveKey)
  } catch (error) {
    console.error('delCreateProblemProjectInfoHandler', error)
  }
}

/**
 * 根据登录用户的项目权限过滤
 * @param {*} res
 * @returns
 */
const _setLocalProjectInfo = (res) => {
  let checkList = res.checkList || []
  let routerList = res.routerList || []
  let checkCode = res.checkCode
  let userInfo = store.getters.getUserInfo
  if (userInfo.attr3) {
    for (let i = 0; i < checkList.length; i++) {
      if (!userInfo?.attr3.includes(checkList[i].deptCode)) {
        checkList.splice(i--, 1)
      }
    }
    res.routerList = checkList.length > 0 ? routerList : []
    res.checkCode = checkList.length > 0 ? checkCode : ''
  } else {
    res.routerList = []
    res.checkList = []
    res.checkCode = ''
    res.searchValue = ''
  }
  console.log('setPJINFO', res)
  return res
}

export { getReportQueryFormHandler, setReportQueryFormHandler, setProjectInfoHandler, delProjectInfoHandler, getProjectInfoHandler, setCreateProblemProjectInfoHandler, getCreateProblemProjectInfoHandler, delCreateProblemProjectInfoHandler }
