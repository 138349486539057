<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"/>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"/>
    <!-- 无网提示 -->
     <van-notify
        class="offline-notify"
        v-model="offlineShow"
        type="danger"
        background="#FF4949"
      >
      <van-icon size="14" name="warning-o" />
      <span>当前网络不可用，请检查您的网络设置</span>
    </van-notify>
  </div>
</template>
<script>
import mobile from '@/utils/mobile'

export default {
  data () {
    return {
      offlineShow: false
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    },
    offlineStatus () {
      return this.$store.getters.getOfflineStatus
    },
    pageType () {
      return this.$store.getters.getPageType
    }
  },
  beforeDestroy () {
    this.$EB.$off(this.$EBT.NO_NETWORK_WARN) // 注销总线bus
  },
  created () {
    if (this.processEnv !== 'production') {
      setInterval(() => {
        console.log(`isOffline:${this.isOffline},offlineStatus:${this.offlineStatus},pageType:${this.pageType}`)
      }, 3000)
    }
  },
  async mounted () {
    /* 区分品控 FM 问题整改 */
    this.$store.commit('setIsDashboard', window.location.href.indexOf('dashboard') > 0) // 是否是品控/设施设备/问题整改
    // await mobile.getToken() // 获取APP的token
    // await this.getUserInfo() // 获取用户信息
    /* ----获取手机网络信号 */
    mobile.getNetState(res => {
      this.$store.dispatch('NetStateHandler', res)
    }, this)
    window.returnNetSignal = (res) => {
      const netState = mobile._transformNet(res)
      this.$store.dispatch('NetStateHandler', netState)
    }
    /* 获取手机网络信号---- */
    this.$EB.$on(this.$EBT.NO_NETWORK_WARN, this.showNoNetworkNotify) // 注册总线bus 无网提示
  },
  methods: {
    showNoNetworkNotify () { // 无网提示
      this.offlineShow = true
      const noNetworkTimer = setTimeout(() => {
        this.offlineShow = false
        clearTimeout(noNetworkTimer)
      }, 1000)
    }
  }
}
</script>
<style lang="less">
.offline-notify {
  height: 40px;
  padding: 0 12px;
  justify-content: normal;
  font-size: 14px;
  z-index: 2020 !important;
  .van-icon {
    margin-right: 8px;
  }
}
</style>
