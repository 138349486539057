import mobile from '@/utils/mobile'
import { cloneDeep } from 'lodash'
import store from '@/store'

const getCodeListSaveKey = () => {
  return 'CodeListSaveKey-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}

const GetCodeListOfflineApiHandlers = async ({ params }) => {
  console.log('params1111: ', params)
  return new Promise(async resolve => {
    try {
      const saveDataKey = getCodeListSaveKey()
      const result = await mobile.getSaveData(saveDataKey)
      if (result && result.key === saveDataKey) {
        let val = result.value
        resolve(cloneDeep(val))
      } else {
        resolve()
      }
    } catch (error) {
      console.error('GetCodeListOfflineApiHandlers', error)
    }
  })
}

const saveCodeListOfflineApiHandler = async (obj) => {
  await mobile.saveData({
    key: getCodeListSaveKey(),
    value: obj.records
  })
}

export {
  getCodeListSaveKey,
  GetCodeListOfflineApiHandlers,
  saveCodeListOfflineApiHandler
}
