import mobile from '@/utils/mobile'

const categoryTreeSaveKey = 'categoryTree'
const bigCategoryTreeSaveKey = 'bigCategoryTree'

/**
 * 取原因类型
 * @returns
 */
const getCategoryTreeHandler = async () => {
  return new Promise(async resolve => {
    try {
      const result = await mobile.getSaveData(categoryTreeSaveKey)
      console.log('getCategoryTreeHandler result: ', result)
      resolve((result && result.value) ? result.value : null)
    } catch (error) {
      console.error('getCacheCodeHandler', error)
    }
  })
}

/**
 * 存原因类型
 * @returns
 */
const setCategoryTreeHandler = async (params) => {
  return new Promise(async (resolve) => {
    try {
      console.log('params: ', params)
      const { categoryTreeDataRes } = params
      console.log('categoryTreeDataRes: ', categoryTreeDataRes)
      let saveObj = {
        key: categoryTreeSaveKey,
        value: categoryTreeDataRes
      }
      await mobile.saveData(saveObj)
      resolve()
    } catch (error) {
      console.error('setCacheCodeHandler error: ', error)
    }
  })
}

/**
 * 获取原因大类下的原因细类
 * @returns
 */
const getSmallCategoryTreeHandler = async ({ params }) => {
  console.log('getSmallCategoryTreeHandler params: ', params)
  return new Promise(async resolve => {
    try {
      const { bigCategoryId } = params
      console.log('bigCategoryId: ', bigCategoryId)
      const result = await mobile.getSaveData(categoryTreeSaveKey)
      console.log('getSmallCategoryTreeHandler result: ', result)
      if (result && result.value && result.value.length > 0) {
        let targetCategory = getTargetCategory(result.value, bigCategoryId)
        if (targetCategory && targetCategory.children) {
          resolve(targetCategory.children)
        } else {
          resolve(null)
        }
      } else {
        resolve(null)
      }
    } catch (error) {
      console.error('getCacheCodeHandler', error)
    }
  })
}

function getTargetCategory (array, bigCategoryId) {
  if (!(array instanceof Array)) {
    return null
  }
  for (let i = 0; i < array.length; i++) {
    let item = array[i]
    if (item.code * 1 === bigCategoryId) {
      return item
    } else {
      if (item.children) {
        let value = getTargetCategory(item.children, bigCategoryId)
        if (value) return value
      }
    }
  }
}

/**
 * 获取原因大类
 * @returns
 */
const getBigCategoryTreeHandler = async ({ params }) => {
  return new Promise(async resolve => {
    try {
      const { search = '' } = params
      const result = await mobile.getSaveData(bigCategoryTreeSaveKey)
      console.log('getBigCategoryTreeHandler result: ', result)
      if (!search) {
        resolve((result && result.value && result.value.length > 0) ? result.value : [])
      } else {
        let getBigReasonList = []
        filterBigReasonList(search, result.value, getBigReasonList)
        resolve((result && result.value && result.value.length > 0) ? getBigReasonList : [])
      }
    } catch (error) {
      console.error('getCacheCodeHandler', error)
    }
  })
}
function filterBigReasonList (search, arr, returnArr) {
  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    if (item.name.indexOf(search) !== -1) {
      returnArr = returnArr.push(item)
    } else {
      filterBigReasonList(search, item.flowCategorySelList, returnArr)
    }
  }
}
/**
 * 存原因大类
 * @returns
 */
const setBigCategoryTreeHandler = async (params) => {
  return new Promise(async (resolve) => {
    try {
      console.log('params: ', params)
      const { bigReasonListDataRes } = params
      console.log('bigReasonListDataRes: ', bigReasonListDataRes)
      let saveObj = {
        key: bigCategoryTreeSaveKey,
        value: bigReasonListDataRes
      }
      await mobile.saveData(saveObj)
      resolve()
    } catch (error) {
      console.error('setCacheCodeHandler error: ', error)
    }
  })
}

export {
  getCategoryTreeHandler,
  setCategoryTreeHandler,
  setBigCategoryTreeHandler,
  getBigCategoryTreeHandler,
  getSmallCategoryTreeHandler
}
