import axios from 'axios'
import Vue from 'vue'
import storage from 'store'
import { Notify } from 'vant'
import { pushCancel, removeCancel } from './axiosCancel'
const NOPUSHCANCEL = ['/radish/pushMessage/list', '/radish/pushMessage/edit', '/woadmin/flow/handle/order', '/woadmin/flow/handle/orderList']
let config = {
  baseURL: process.env.VUE_APP_API_BASE_URL,
  timeout: 20 * 60 * 1000, // Timeout todo 上线需要设置成60
  headers: {
    'Content-Type': 'application/json',
    'API_SDK_TOKEN': process.env.VUE_APP_API_SDK_TOKEN,
    'API_SDK_TICKET': process.env.VUE_APP_API_SDK_TICKET,
    'X-USER-ID': '111'
  },
  withCredentials: false, // Check cross-site Access-Control

  ransformRequest: [function (data) {
    // 做任何你想要的数据转换
    return data
  }],
  validateStatus: function (status) {
    return status >= 200 && status <= 500 // default
  }
}
const _axios = axios.create(config)
_axios.interceptors.request.use(
  config => {
    // 添加取消标记
    config.cancelToken = new axios.CancelToken(cancel => {
      // 提交接口和抽查结果页面不做loading优化处理
      if (!NOPUSHCANCEL.includes(config.url)) {
        pushCancel({ key: config.url, f: cancel })
      }
    })
    const Token = storage.get('token')
    if (Token) {
      config.headers.Authorization = Token
    }
    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)
// Add a response interceptor
// axios请求拦截处理
_axios.interceptors.response.use(
  response => {
    switch (response.status) {
      case 401:
      case 402:
      case 403:
        /* 登录提示 */
        Notify({ type: 'warning', message: '登录过期,请重新登录' })
        break
      case 404:
        /* 路径错误提示 */
        break
      case 500:
        /* 服务器错误提示 */
        break
    }
    removeCancel(response.config.url)
    if (response) return response.data
  },
  error => {
    console.log('interceptors error: ', error)
    return Promise.reject(error)
  }
)
Plugin.install = function (Vue) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      get () {
        return _axios
      }
    },
    $axios: {
      get () {
        return _axios
      }
    }
  })
}
Vue.use(Plugin)

export default _axios
