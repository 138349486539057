var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "upload-wrapper" },
    [
      _c("van-field", {
        staticClass: "form-upload",
        attrs: {
          name: "attachmentList",
          required: _vm.required,
          label: `图片(可上传${_vm.maxCount}张)`,
        },
        scopedSlots: _vm._u([
          {
            key: "input",
            fn: function () {
              return [
                _c("van-uploader", {
                  attrs: {
                    multiple: "",
                    "before-read": _vm.uploadBefore,
                    "after-read": _vm.uplaodAfter,
                    "max-count": _vm.maxCount,
                    "before-delete": _vm.delteAfter,
                  },
                  model: {
                    value: _vm.fileList,
                    callback: function ($$v) {
                      _vm.fileList = $$v
                    },
                    expression: "fileList",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("canvas", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: false,
            expression: "false",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }