import mobile from '@/utils/mobile'

const preMadeQRCodeSaveKey = 'preMadeQRCode'

const getPreMadeQRCodeHandler = async () => {
  return new Promise(async resolve => {
    try {
      const result = await mobile.getSaveData(preMadeQRCodeSaveKey)
      resolve((result && result.value) ? result.value : null)
    } catch (error) {
      console.error('getPreMadeQRCodeHandler', error)
    }
  })
}

// 设置扫码的code码缓存到手机中
const setPreMadeQRCodeHandler = async (data) => {
  return new Promise(async (resolve) => {
    console.log('data: ', data)
    try {
      let saveObj = {
        key: preMadeQRCodeSaveKey,
        value: data
      }
      console.log('saveObj: ', saveObj)
      await mobile.saveData(saveObj)
      resolve('ok')
    } catch (error) {
      console.error('setPreMadeQRCodeHandler error: ', error)
    }
  })
}
// 设置扫码的code码缓存到手机中
const delPreMadeQRCodeHandler = async () => {
  return new Promise(async resolve => {
    mobile.deleteSaveData(preMadeQRCodeSaveKey)
    resolve('ok')
  })
}

export {
  getPreMadeQRCodeHandler,
  setPreMadeQRCodeHandler,
  delPreMadeQRCodeHandler
}
