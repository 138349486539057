import moment from 'moment'
/**
 * 时间戳转时分秒
 * @param {number} Timestamps 时间戳
 * @returns {string} 23:59(分)
 */
const timestampTos = (Timestamps) => {
  return moment(Timestamps).format('HH:mm')
}
/**
 * 时间戳转时分秒
 * @param {number} Timestamps 时间戳
 * @returns {string} 23:59:59(秒)
 */
const timestampToms = (Timestamps) => {
  return moment(Timestamps).format('HH:mm:ss')
}
/**
 * 时间戳转日期
 * @param Timestamps 时间戳
 * @returns {string} 2018-08-28 (日)
 */
const timestampToYMD = (Timestamps) => {
  return moment(Timestamps).format('YYYY-MM-DD')
}
/**
 * 时间戳转短时间
 * @param Timestamps 时间戳
 * @returns {string} 2018-08-28 23:59(分)
 */
const timestampToYMDHm = (Timestamps) => {
  return moment(Timestamps).format('YYYY-MM-DD HH:mm')
}
/**
 * 时间戳转长时间
 * @param Timestamps 时间戳
 * @returns {string} 2018-08-28 23:59:59(秒)
 */
const timestampToYMDHms = (Timestamps) => {
  return moment(Timestamps).format('YYYY-MM-DD HH:mm:ss')
}
/**
 * 时间转时间戳
 * @param {number} time 时间
 * @returns {string}  1534567891(秒)
 */
const timeToTimestamp = (time) => {
  return moment(time, 'YYYY-MM-DD HH:mm:ss').valueOf()
}
// 当前时间戳
const timeNow = () => {
  return moment().valueOf()
}
const formatMinutes = totalMinutes => {
  if (totalMinutes === 0 || !totalMinutes) return '0分钟'
  const day = parseInt(totalMinutes / 1440)
  const hours = parseInt((totalMinutes - day * 1440) / 60)
  const minutes = totalMinutes - day * 1440 - hours * 60
  let result = ''
  if (day > 0) result += `${day}天`
  if (hours > 0) result += `${hours}小时`
  if (minutes > 0) result += `${minutes}分钟`
  return result
}

export default {
  timestampTos,
  timestampToms,
  timestampToYMD,
  timestampToYMDHm,
  timestampToYMDHms,
  timeToTimestamp,
  timeNow,
  formatMinutes
}
