import { Notify } from 'vant'
const OSS = require('ali-oss')
const dateFormat = require('dateformat')
const common = {
  attachUpload (ossParams, file, reback) {
    let client = new OSS({
      endpoint: ossParams.endpoint,
      accessKeyId: ossParams.accessKeyId,
      accessKeySecret: ossParams.accessKeySecret,
      bucket: ossParams.bucketName
    })
    let storeAs = this.getFileName(file.file.name)
    client.put(storeAs, file.file, { mime: 'application/octet-stream' }).then(res => {
      let info = {
        name: file.file.name,
        fileSize: this.getFileSize(file.file.size),
        url: ossParams.url + '/' + res.name,
        fileType: this.getFileType(file.file.name),
        createTime: dateFormat(new Date(), 'yyyy-mm-dd HH:MM:ss')
      }
      if (reback) reback(info)
    })
  },
  offlineUpload (ossParams, file) {
    let client = new OSS({
      endpoint: ossParams.endpoint,
      accessKeyId: ossParams.accessKeyId,
      accessKeySecret: ossParams.accessKeySecret,
      bucket: ossParams.bucketName
    })
    let storeAs = this.getFileName(file.file.name)
    return new Promise((resolve, reject) => {
      client.put(storeAs, file.file, { mime: 'application/octet-stream' }).then(res => {
        let info = {
          name: file.file.name,
          fileSize: this.getFileSize(file.file.size),
          url: ossParams.url + '/' + res.name,
          fileType: this.getFileType(file.file.name),
          createTime: dateFormat(new Date(), 'yyyy-mm-dd HH:MM:ss')
        }
        resolve(info)
      }).catch(error => {
        Notify({
          type: 'warning',
          message: '图片上传失败'
        })
        reject(error)
      })
    })
  },
  getFileName (type) {
    let date = dateFormat(new Date(), 'yyyy/mm/dd/')
    return date + this.getString20() + '.' + this.getFileType(type)
  },
  getFileType (filename) {
    let arr = filename.split('.')
    return arr[arr.length - 1]
  },
  getFileSize (limit) {
    var size = ''
    if (limit < 0.1 * 1024) { // 如果小于0.1KB转化成B
      size = limit.toFixed(2) + 'B'
    } else if (limit < 0.1 * 1024 * 1024) { // 如果小于0.1MB转化成KB
      size = (limit / 1024).toFixed(2) + 'KB'
    } else if (limit < 0.1 * 1024 * 1024 * 1024) { // 如果小于0.1GB转化成MB
      size = (limit / (1024 * 1024)).toFixed(2) + 'MB'
    } else { // 其他转化成GB
      size = (limit / (1024 * 1024 * 1024)).toFixed(2) + 'GB'
    }
    var sizestr = size + ''
    var len = sizestr.indexOf('.')
    var dec = sizestr.substr(len + 1, 2)
    if (dec === '00') { // 当小数点后为00时 去掉小数部分
      return sizestr.substring(0, len) + sizestr.substr(len + 3, 2)
    }
    return sizestr
  },
  getString20 () {
    var data = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
    var result = ''
    for (var i = 0; i < 20; i++) {
      var r = Math.floor(Math.random() * 62) // 取得0-62间的随机数，目的是以此当下标取数组data里的值！
      result += data[r] // 输出20次随机数的同时，让rrr加20次，就是20位的随机字符串了。
    }
    return result
  },
  getQueryString (key) {
    console.log('window.location.href: ', window.location.href)
    if (!window.location.hash) return null
    if (!window.location.hash.split('?')[1]) return null
    var reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)', 'i')
    var r = window.location.hash.split('?')[1].match(reg)
    if (r) return r[2]
    return null
  },
  hexToRgba (hex, alpha) {
    const result = `rgba(${parseInt('0x' + hex.slice(1, 3))},${parseInt('0x' + hex.slice(3, 5))},${parseInt('0x' + hex.slice(5, 7))},${alpha})`
    console.log('result: ', result)
    return result
  },
  // 过去权限
  getUserMedia (constrains, successCallBack, errorCallBack) {
    if (navigator.mediaDevices.getUserMedia) {
      // 最新标准API
      navigator.mediaDevices.getUserMedia(constrains).then(stream => { successCallBack(stream) }).catch(err => { errorCallBack(err) })
    } else if (navigator.webkitGetUserMedia) {
      // webkit内核浏览器
      navigator.webkitGetUserMedia(constrains).then(stream => { successCallBack(stream) }).catch(err => { errorCallBack(err) })
    } else if (navigator.mozGetUserMedia) {
      // Firefox浏览器
      navigator.mozGetUserMedia(constrains).then(stream => { successCallBack(stream) }).catch(err => { errorCallBack(err) })
    } else if (navigator.getUserMedia) {
      // 旧版API
      navigator.getUserMedia(constrains).then(stream => { successCallBack(stream) }).catch(err => { errorCallBack(err) })
    }
  }
}
export default common
