import Vue from 'vue'
import VueLodash from 'vue-lodash'
// lodash想要tree shking必须要这样使用路径单独引入
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
import sortBy from 'lodash/sortBy'
import isEqual from 'lodash/isEqual'
import compact from 'lodash/compact'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import uniqBy from 'lodash/uniqBy'
import remove from 'lodash/remove'
import isEmpty from 'lodash/isEmpty'
import intersection from 'lodash/intersection'
import isArray from 'lodash/isArray'
import uniq from 'lodash/uniq'
import every from 'lodash/every'
import reverse from 'lodash/reverse'

Vue.use(VueLodash, {
  name: 'lo',
  lodash: {
    map,
    cloneDeep,
    sortBy,
    compact,
    isEqual,
    throttle,
    debounce,
    uniqBy,
    remove,
    isEmpty,
    intersection,
    isArray,
    uniq,
    every,
    reverse
  }
})
