// 记录form，返回页面时直接赋值
const pageForm = {
  state: {
    isBackFromProcessingListByIgnore: false,
    processingList: [],
    closeOrderNos: []
  },
  mutations: {
    setIsBackFromProcessingListByIgnore (state, bool) {
      console.log('setIsBackFromProcessingListByIgnore: ', bool)
      state.isBackFromProcessingListByIgnore = bool
    },
    setCloseOrderNos (state, params) {
      console.log('setCloseOrderNos params: ', params)
      state.closeOrderNos = params
    },
    setProcessingList (state, list) {
      console.log('setProcessingList: ', list)
      state.processingList = list
    }
  }
}

export default pageForm
