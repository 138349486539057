const historyRoute = {
  state: {
    router: []
  },
  mutations: {
    setRouter (state, vueRouter) {
      const { toName, fromName } = vueRouter
      let router = state.router
      let toIndex = router.indexOf(toName)
      if (toIndex === -1) {
        router.push(toName)
      } else {
        let fromIndex = router.indexOf(fromName)
        if (fromIndex !== -1) {
          if (toIndex <= fromIndex) {
            let length = fromIndex - toIndex
            router.splice(fromIndex, length)
          }
        }
      }
      state.router = router
    },
    clearRouter (state) {
      state.router = []
    }
  },
  actions: {
  }

}
export default historyRoute
