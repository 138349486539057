import { getOrderListOfflineApiHandler } from '@/plugins/offlineMixin/offlineApiHandlers/OrderInfoOfflineApiHandlers'
import { apiOffline } from '@/plugins/offlineMixin/apiOfflineGetway'
const system = {
  state: {
    pageType: null, // 项目类型 1-品控 2-FM 3-问题整改
    isPK: false, // 是否是品控
    isFM: false, // 是否是FM
    isZG: false, // 是否是问题整改
    isCJ: false, // 是否是承接查验
    isJK: false, // 是否是远程监控
    offline: true, // 在线 - true 离线 - false
    offlineStatus: 2, // 网络状态 0-离线 1-弱网 2-在线,
    isDashboard: true
  },
  mutations: {
    setPageType (state, pageType) {
      console.log('setPageType pageType: ', pageType)
      switch (pageType) {
        case ('fm'):
        case ('2'):
        case (2):
          state.isFM = true
          state.pageType = 2
          break
        case ('3'):
        case (3):
          state.isZG = true
          state.pageType = 3
          break
        case ('4'):
        case (4):
          state.isCJ = true
          state.pageType = 4
          break
        case ('5'):
        case (5):
          state.isJK = true
          state.pageType = 5
          break
        default: // 默认是品控
          state.isPK = true
          state.pageType = 1
          break
      }
      console.log('state.pageType: ', state.pageType)
    },
    setOffline (state, payload) {
      console.log('setOffline payload: ', payload)
      state.offline = payload.val
    },
    setOfflineStatus (state, payload) {
      console.log('setOfflineStatus payload: ', payload)
      state.offlineStatus = payload.val
    },
    setIsDashboard (state, isDashboard) {
      console.log('setIsDashboard: ', isDashboard)
      state.isDashboard = isDashboard
    }
  },
  actions: {
    async NetStateHandler ({ commit, state }, net) {
      console.log('NetStateHandler net: ', state.offlineStatus, net)
      if (state.offlineStatus === net) return
      commit('setOfflineStatus', { val: net }) // 设置网络状态
      if (net === 1) { // 弱网需要特殊处理
        if (state.isDashboard) {
          let status = true
          const orderList = await apiOffline({
            offlineApiHandlers: getOrderListOfflineApiHandler,
            forceOffline: true
          })
          console.log('orderList: ', orderList)
          // 如果在弱网环境有离线数据就为离线，否则为在线
          if (net === 1 && orderList?.length > 0) {
            status = false
          }
          commit('setOffline', { val: status })
        } else {
          commit('setOffline', { val: true })
        }
      } else { // 无网和在线直接设置
        commit('setOffline', { val: net !== 0 })
      }
    }
  }
}
export default system
