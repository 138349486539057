import mobile from '@/utils/mobile'
import { Dialog } from 'vant'
const saveKey = 'tobeUploadEquipment'

const getTobeUploadEquipment = async () => {
  return new Promise(async resolve => {
    try {
      const result = await mobile.getSaveData(saveKey)
      resolve((result && result.value) ? result.value : null)
    } catch (error) {
      console.error('getTobeUploadEquipment', error)
    }
  })
}

// 添加离线设备/设施登记数据
const setTobeUploadEquipment = async (form) => {
  return new Promise(async (resolve) => {
    try {
      console.log('form: ', form)
      const tobeUploadEquipment = await getTobeUploadEquipment() || []
      console.log('tobeUploadEquipment: ', tobeUploadEquipment)
      tobeUploadEquipment.push(form)
      let saveObj = {
        key: saveKey,
        value: tobeUploadEquipment
      }
      await mobile.saveData(saveObj)
      Dialog.alert({
        title: '提示',
        message: '您的网络状态不好，设施/设备登记数据已保存到离线数据，请在网络情况好的状态下进行上传数据。',
        confirmButtonColor: '#3366FE',
        confirmButtonText: '知道了'
      }).then(() => {
        resolve('ok')
      })
    } catch (error) {
      console.error('setTobeUploadEquipment error: ', error)
    }
  })
}
// 删除所有的离线设备/设施登记数据
const delTobeUploadEquipment = async () => {
  return new Promise(async resolve => {
    mobile.deleteSaveData(saveKey)
    resolve('ok')
  })
}
// 根据qrcodes list删除指定离线设备登记数据
const delTobeUploadEquipmentByQrcodes = async ({ params }) => {
  return new Promise(async resolve => {
    const { qrcodes } = params
    console.log('qrcodes: ', qrcodes)
    const data = await getTobeUploadEquipment()
    const filterData = data.reduce((arr, item) => {
      if (!qrcodes.includes(item.qrCode)) {
        arr.push(item)
      } else {
        mobile.deleteImgsInCache(item.attachementModelList)
      }
      return arr
    }, [])
    console.log('filterData: ', filterData)
    let saveObj = {
      key: saveKey,
      value: filterData
    }
    await mobile.saveData(saveObj)
    resolve(filterData)
  })
}
// 根据qrcodes 更新删除指定离线设备登记数据，重复等级
const updateTobeUploadEquipmentByQrcodes = async ({ params }) => {
  return new Promise(async resolve => {
    const { qrcodes } = params
    console.log('qrcodes: ', qrcodes)
    const data = await getTobeUploadEquipment()
    data.forEach(form => {
      if (qrcodes.includes(form.qrCode)) {
        form.repeatSubmit = true
      }
    })
    let saveObj = {
      key: saveKey,
      value: data
    }
    await mobile.saveData(saveObj)
    resolve(data)
  })
}

export {
  getTobeUploadEquipment,
  setTobeUploadEquipment,
  delTobeUploadEquipment,
  delTobeUploadEquipmentByQrcodes,
  updateTobeUploadEquipmentByQrcodes
}
