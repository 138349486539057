const api = {
  getOrg: '/woadmin/bm/department/mylist', // 查询组织
  getBigReasonList: '/woadmin/order/bigFlowCategory/tree', // 获取原因大类列表
  getSmallReasonList: '/woadmin/order/smallFlowCategory/', // 获取原因细类列表
  getOssParams: '/woadmin/order/oss/detail', // 获取上传文件oss参数
  getDeviceType: '/woadmin/bm/facility/category/list', // 查询设施设备分类
  getDeviceList: '/woadmin/bm/facility/list/', // 查询设施设备列表
  /* 工单中心 */
  attention: '/woadmin/order/attention', // 工单关注/收藏
  gethandlerUsers: '/woadmin/flow/handlerUsers', // 获取处理人
  getUpgradeHandlerUsers: '/woadmin/order/upgrade/upgradeHandlerSel', // 获取升级工单处理人
  getLabels: '/woadmin/flow/labels', // 获取问题标签
  OrderFlow: '/woadmin/flow/handle/order', // 工单流转

  // 1.20版本
  pinkongProblemCreate: '/radish/problemRecord/inner/pinkongProblemCreate', // 转发设施设备提交
  getScanQrCode: '/radish/problemRecord/findLocation', // 扫码获取设施设备code
  getDicList: '/radish/dic/list', // 获取数据字典
  /* 通用 */
  log: '/woadmin/orderdetail/log', // 工单跟进记录
  dictionaryitem: '/woadmin/common/dictionaryitem/list', // 数据字典
  // 品控中心
  activeList: '/radish/mobile/orderStatus', // 日期状态
  getOfflineList: '/radish/mobile/offline/packetFM', // 离线列表数据包
  getPointOfflineList: '/radish/radishBattleMap/downLoadPointInfo', // 点位离线列表数据包

  // 离线数据包增量下载
  findByOffline: '/radish/offline/interface/info/findByOffline', // 查询离线接口版本是否变化
  offlineAreaData: '/battlemap/bm/mongo/publicarea/offlineAreaData', // 离线增量数据下载--设施设备

  // getOrderList: '/radish/mobile/order/list', // 获取列表（FM项目之前用的）
  getOrderList: '/radish/mobile/order/listFM', // 获取列表（FM项目）
  getOrderDetail: '/radish/mobile/orderdetail/taskOrderDetail', // 工单详情
  getTypeCount: '/radish/mobile/order/count', // 每个列表状态个数
  getProblemData: '/radish/problemRecord/problemDataPackage', // 问题记录选择项数据
  submitOffline: '/woadmin/flow/handle/order', // 离线提交
  submitAllOffline: '/woadmin/flow/handle/orderList', // 离线提交
  getUserInfo: '/radish/uc/userInfo', // 获取用户信息
  getCodeList: '/radish/mobile/order/listByPoint', // 获取二维码列表

  getCodeListByVoice: '/radish/mobile/order/listByVoice', // 获取二维码列表 根据语音

  createProblemRecord: '/radish/problemRecord/inner/pinkongProblemCreate', // 提交问题工单
  getWxAppSign: '/radish/mini/sign', // 微信H5 jssdk签名

  getAuditListReq: '/radish/radishBattleMap/findPointTypes', // 获取分类列表
  findPointInfoReq: '/radish/radishBattleMap/findPointInfo', // 获取点位
  getCheckDetailsReq: '/woadmin/order/radishOrderListByCheckNo', // 获取点位

  getSamplingResultsReq: '/radish/pushMessage/list', // 获取品控任务抽查结果记录列表
  setReadReq: '/radish/pushMessage/edit', // 变更消息状态
  radishMobileInfo: '/radish/radishMobileInfo/submit', // 传浏览器信息给后端

  findListByPoint: '/radish/radishBreakdownGuide/mobile/findListByPoint', // 主要部件
  getAllCausesByPoint: '/radish/breakdownCauses/getAllCausesByPoint', // 主要部件故障列表
  getBreakDownDataPackageReq: '/radish/problemRecord/breakDownDataPackage', // 主要部件故障原因页面所有详情数据
  QRCodeSel: '/battlemap/findPublicCategoryList', // 设施设备列表
  equipTree: '/battlemap/findPublicCategoryList/3', // 设备树
  facilityTree: '/battlemap/findPublicCategoryList/2', // 设施树
  facilityBrand: '/battlemap/findFacilityCategory', // 设施详情
  projectTree: '/woadmin/bm/department/mypclist', // 所在项目树
  equipBrand: '/battlemap/findEquipmentCategory', // 设备品牌
  equipGroup: '/battlemap/point/groupSelector', // 所在组团
  QRCodeType: '/battlemap/precast/qr/type', // 二维码类型
  addEquip: '/battlemap/equipmentManage/equipmentCategoryCreateOrModify', // 设备提交
  addFacility: '/battlemap/bm/mongo/public/facility/save', // 设施提交

  // 待上传时提交
  commitFacilityAndEquipment: '/battlemap/bm/publicarea/offline/commitFacilityAndEquipment', // 设施设备待上传提交
  facilityDetail: '/battlemap/bm/mongo/public/facility/detail', // 设施详情
  equipDetail: '/battlemap/equipmentManage/detail', // 设备详情
  changeList: '/battlemap/bm/mongo/public/facility/changeHis', // 设备设施变更历史
  checkList: '/woadmin/order/findOrderRecordList', // 巡查、保养列表
  repairList: '/woadmin/order/findOrderProblemRecordList', // 维修列表
  getCode: '/battlemap/equipmentManage/create/code', // 获取设施设备编码
  createGroup: '/battlemap/bmProjectGroup/createOrModify', // 手动创建组团

  // 1.17.1 设备数据下载离线包
  facilityAndEquipmentPackage: '/battlemap/bm/publicarea/offline/facilityAndEquipmentPackage',

  homeEquipmentTree: '/battlemap/publicCategory/type/list', // 首页筛选设施设备的树
  homeEquipmentTreeGetGroup: '/battlemap/bm/mongo/project/group/list', // 首页筛选设施设备的树,根据项目加载组团
  homeEquipmentTreeGetLast: '/battlemap/bm/mongo/publicarea/findGroupProjectByPoint', // 根据分类+组团+项目编码查询空间资产信息

  orderUpgrade: '/radish/upgrade/order/create', // 升级工单
  orderBackReportSys: '/woadmin/orderdetail/fm/back', // 退回报事转发工单
  fmOrderBackReportSys: '/woadmin/orderdetail/fm/order/back', // FM工单退回

  bluewhaleConfigList: 'homepigeon/sys/gather/add', // 采集app网络信息
  homepigeonConfigList: 'homepigeon/sys/config/list', // 获取参数采集app网络信息

  /* 问题整改 */
  pointTreeByProjectCode: '/battlemap/radishMongo/inner/pointTreeByProjectCode', // 通过项目code查询点位树结果
  timeoutSelect: '/woadmin/flow/timeoutSelect',

  signInFindStandard: '/woadmin/scan/record/findStandard', // 扫码签到

  // sprint20221125
  orderFindProcessing: '/woadmin/order/find/processing',
  // sprint20230818 报事工单
  getProjectBuilds: '/woadmin/sys/build/getProjectBuilds', // 获取项目下的楼栋
  getBuildUnits: '/woadmin/sys/house/getBuildUnits', // 获取楼栋下的单元
  getUnitFloors: '/woadmin/sys/house/getUnitFloors', // 获取单元下的楼层
  getHouseList: '/woadmin/sys/house/list', // 获取房屋列表
  getMembers: '/woadmin/sys/house/member/getMembers', // 获取单元下的楼层
  createCustomReportEventOrder: '/radish/report/event/order/createCustomReportEventOrder', // 创建客户报事工单
  createSelfReportEventOrder: '/radish/report/event/order/createSelfReportEventOrder', // 创建自查报事工单
  getThreeMonthReportEventOrders: '/woadmin/order/getThreeMonthReportEventOrders', // 创建自查报事工单
  quickInputListReq: '/woadmin/flow/category/quickInputList/', // 查询工单创建时问题项关联的原因类型code的快捷输入
  houseSearchList: '/woadmin/sys/house/search/list',
  categoryTree: '/woadmin/flow/category/tree',
  checkInitAreaTree: '/battlemap/bm/checkInit/area/tree',
  checkInitAreaList: '/battlemap/bm/checkInit/area/list',
  taskItemBatchSubmit: '/woadmin/order/item/submit', // 在线批量提交任务项
  // sprint20240412 远程监控8700
  getIsCaptureSuccess: '/woadmin/order/log/capture/success', // 获取工单抓拍是否成功
  orderTaskCapture: '/woadmin/order/log/capture', // 抓取拍照
  getProblemList: '/radish/problem/list', // 问题项列表
  remoteRecheckApply: '/woadmin/order/remote/recheck/apply', // 复核提交
  getRemoteRecheckQuery: '/woadmin/order/remote/recheck/', // 查询复核工单详情
  getJKAuth: '/radish/sysRoleVisable/jk', // 查询远程监控权限

  getTwUserCode: '/woadmin/incident/user/info' // 获取天问userCode
}
export default api
