<template v-cloak>
  <div class="text-wrapper">
    <van-field
      v-model="val"
      clearable
      type="textarea"
      :placeholder="placeholder"
      :autosize="{ maxHeight: 63, minHeight: 63 }"
      @blur="textBlur"
    ></van-field>
  </div>
</template>

<script type='text/ecmascript-6'>
export default {
  name: 'MyTextarea',
  components: {},
  props: {
    value: {
      type: String,
      required: true,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入备注'
    }
  },
  data () {
    return {
      val: ''
    }
  },
  watch: {
    value: function (val) {
      this.val = val
    }
  },
  created () {},
  mounted () {
    this.val = this.value
  },
  methods: {
    textBlur () {
      this.$emit('update:value', this.val)
    }
  }
}
</script>
<style  lang='less' scoped>
.text-wrapper {
  margin-top: 12px;
  .van-field{
    padding:  0;
  }
  textarea {
    margin-top: 0;
  }
}
</style>
