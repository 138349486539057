
const OfflinePluginMixin = {
  computed: {
    isOffline () {
      console.log('>>>>>>>>>>>>>this.$store.getters.getIsOffline in mixin: ', this.$store.getters.getIsOffline)
      return this.$store.getters.getIsOffline
    },
    isActivePage () {
      return this.$route.name === this.$options.name
    }
  },
  watch: {
    isOffline (newVal, oldValue) {
      console.log('>>>>>>>>>>>>>offline newVal in mixin 1111: ', newVal, oldValue)
      if (newVal !== oldValue && this.initPage && this.isActivePage) {
        this.initPage()
      }
    }
  }
}

export default OfflinePluginMixin
