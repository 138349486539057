import mobile from '@/utils/mobile'
import moment from 'moment'
import store from '@/store'

/**
 * 保存近三日数据列表
 * @param {*} params detail.model
 */
const setHistoryListDataHandler = async ({ params }) => {
  let model = params
  if (model.template_node_code === 'yiwanjie') return
  let historyKey = ['id', 'order_no', 'node_name', 'remark']
  let newHistryRecord = {}
  historyKey.forEach(item => {
    newHistryRecord[item] = model[item]
  })
  newHistryRecord.lastChangeTime = moment().format('YYYY-MM-DD HH:mm:ss')
  const recentOrderRecord = {
    orderId: model.id,
    orderData: JSON.stringify(newHistryRecord),
    pageType: store.getters.getPageType
  }
  console.log('新存近三天记录recentOrderRecord: ', recentOrderRecord)
  await mobile.saveRecentOrder(recentOrderRecord)
}

/**
 * 获取近三天历史记录列表
 */
/**
 * 获取工单列表
 * @param {*} pageType
 * @returns
 */
const getHistoryOrderListOfflineApiHandler = async () => {
  return new Promise(async resolve => {
    try {
      const historyOrderList = await mobile.getRecentOrderList({
        pageType: store.getters.getPageType
      }) || []
      console.log('historyOrderList: ', historyOrderList)
      // 将orderData序列化
      if (historyOrderList && historyOrderList.length > 0) {
        // 遍历所有历史记录，如果有过期则需要删除对应的记录
        resolve(historyOrderList.map(record => JSON.parse(record.orderData)).filter(async orderData => {
          if (isOutThreeDay(orderData)) {
            // 近三天过期了
            console.log('近三天过期了', orderData)
            await mobile.deleteRecentOrder({ orderIds: [orderData.id] })
            return false
          } else {
            return true
          }
        }).sort((a, b) => {
          return moment(b.lastChangeTime).valueOf() - moment(a.lastChangeTime).valueOf()
        }))
      } else {
        resolve([])
      }
    } catch (error) {
      console.error('getHistoryOrderListOfflineApiHandler', error)
    }
  })
}

/**
 *
 * @param {*} historyAllList
 * @returns
 */
const isOutThreeDay = (orderData) => {
  // 今天 昨天 前天 这三天的数据
  let statrtTime = moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD').valueOf() - 2 * 24 * 3600 * 1000
  let curTime = moment().valueOf()
  let lastTime = moment(orderData.lastChangeTime, 'YYYY-MM-DD HH:mm:ss').valueOf()
  return !(lastTime > statrtTime && lastTime < curTime)
}

export {
  getHistoryOrderListOfflineApiHandler,
  setHistoryListDataHandler
}
