import Vue from 'vue'
import Vuex from 'vuex'
import order from './modules/order'
import user from './modules/user'
import system from './modules/system'
import historyRoute from './modules/historyRoute'
import pointStorage from './modules/pointStorage'
import pageFormCache from './modules/pageFormCache'
import dashBoardStore from './modules/dashBoardStore'
import dataCache from './modules/dataCache'
import getters from './getters'

Vue.use(Vuex)
export const store = Vue.observable({ // 利用Vue.observable实现通信
  homeProjectCodes: undefined
})

export default new Vuex.Store({
  getters,
  modules: {
    order,
    user,
    pointStorage,
    system,
    historyRoute,
    pageFormCache,
    dashBoardStore,
    dataCache
  }
})
