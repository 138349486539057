import { render, staticRenderFns } from "./NavHeader.vue?vue&type=template&id=6e1afce0&scoped=true"
import script from "./NavHeader.vue?vue&type=script&lang=js"
export * from "./NavHeader.vue?vue&type=script&lang=js"
import style0 from "./NavHeader.vue?vue&type=style&index=0&id=6e1afce0&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e1afce0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/app/jenkins/data/workspace/TEST测试环境/品控SIT环境/radish-mobile-ui-test/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6e1afce0')) {
      api.createRecord('6e1afce0', component.options)
    } else {
      api.reload('6e1afce0', component.options)
    }
    module.hot.accept("./NavHeader.vue?vue&type=template&id=6e1afce0&scoped=true", function () {
      api.rerender('6e1afce0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/NavHeader.vue"
export default component.exports