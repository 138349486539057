import axios from './axios'
import common from './common'
import { Notify, Dialog } from 'vant'
// const ERROR = "-1"
const SUCCESS = 200
const InitMethodsParam = {
  get: {
    headers: {
      // JKFGHSL;KDG:不连本地则删除，测试用
      // 'X-USER-ID': '123',
      'content-type': 'application/x-www-form-urlencoded'
    },
    responseType: 'json'
  },
  post: {
    headers: {
      // JKFGHSL;KDG:不连本地则删除，测试用
      // 'X-USER-ID': '123',
      'content-type': 'application/json'
    },
    responseType: 'json'
  },
  put: {
    headers: {
      // JKFGHSL;KDG:不连本地则删除，测试用
      // 'X-USER-ID': '123',
      'content-type': 'application/json'
    },
    responseType: 'json'
  },
  delete: {
    headers: {
      // JKFGHSL;KDG:不连本地则删除，测试用
      // 'X-USER-ID': '123',
      'content-type': 'application/x-www-form-urlencoded'
    },
    responseType: 'json'
  }
}
export default {
  /**
     * 接口配置
     *
     * @param {String} data.method  请求方式  required
     * @param {String} data.url  请求路径 required
     * @param {Object} data.params  请求入参
     * @param {Function} data.cb  成功回调 required
     * @param {Function} data.cbCodeFail  code异常回调
     * @param {Function} data.errorCb  失败回调
     * @param {Object} data.headers  header参数
     * @param {String} data.responseType  返回数据类型
     */
  api (data) {
    let {
      method = 'get',
      url,
      params = {},
      cb,
      cbCodeFail,
      errorCb,
      headers = InitMethodsParam[method].headers,
      responseType = InitMethodsParam[method].responseType,
      showLoading = true
    } = data
    // axios 入参
    let initParam = {
      method,
      url,
      responseType,
      headers,
      data: params
    }
    if (method === 'get' || method === 'delete') {
      delete initParam.data
      initParam.params = params
    }
    showLoading && common.showLoading()
    axios(initParam).then((response) => {
      console.log('axios response: ', response)
      showLoading && common.hideLoading()
      if (response && response.code === SUCCESS) {
        /*
          扫码登记，二维码重复登记时的特殊处理
          和后端（赵成川）商量修改返回的数据格式，他不干，只有这样特殊处理了，没法
        */
        if (response.result === 500008) {
          cb(response)
        } else {
          cb(response.result)
        }
      } else {
        if (response.code === 1047) {
          Dialog.alert({
            title: '提示',
            message: response.message,
            confirmButtonText: '知道了'
          })
        } else if (response.code === 1042) { // 离线包为空时code为1042
          cb(response.result)
          Notify({ type: 'warning', message: response ? response.message : '离线数据包为空' })
        } else if (response.code === 1053) { //
          cb(response)
        } else {
          Notify({ type: 'warning', message: response ? response.message : '网络异常' })
        }
        if (cbCodeFail) {
          cbCodeFail(response ? response.result : '网络异常')
        }
      }
    }).catch((err) => {
      showLoading && common.hideLoading()
      // 单独处理网络异常
      if (errorCb) {
        errorCb(err)
      }
    })
  }
}
