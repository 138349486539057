import Vue from 'vue'
import Tree from 'ant-design-vue/lib/tree'
import 'ant-design-vue/lib/tree/style'
import 'vant/lib/index.less'
import { List,
  Badge,
  Notify,
  Tag,
  PullRefresh,
  NavBar,
  Form,
  Button,
  Field,
  Picker,
  Checkbox,
  CheckboxGroup,
  RadioGroup,
  Radio,
  Switch,
  DatetimePicker,
  Icon,
  Cell,
  CellGroup,
  Image as VanImage,
  Col,
  Row,
  Popup,
  Toast,
  Uploader,
  Dialog,
  Loading,
  Search,
  Tab,
  Tabs,
  Divider,
  Overlay,
  ImagePreview,
  Step,
  Steps,
  Collapse,
  CollapseItem,
  ActionSheet,
  Stepper,
  Rate,
  Circle,
  Sticky,
  Calendar,
  Popover } from 'vant'

// 组件注册-请自行添加需要使用的vant组件
Vue.use(Tree)
Vue.use(Badge)
Vue.use(Notify)
Vue.use(List)
Vue.use(Tag)
Vue.use(PullRefresh)
Vue.use(NavBar)
Vue.use(Form)
Vue.use(Button)
Vue.use(Field)
Vue.use(Picker)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Calendar)
Vue.use(Switch)
Vue.use(DatetimePicker)
Vue.use(Icon)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(VanImage)
Vue.use(Col)
Vue.use(Row)
Vue.use(Popup)
Vue.use(Toast)
Vue.use(Uploader)
Vue.use(Dialog)
Vue.use(Loading)
Vue.use(Search)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(Divider)
Vue.use(Overlay)
Vue.use(ImagePreview)
Vue.use(Step)
Vue.use(Steps)
Vue.use(ActionSheet)
Vue.use(Stepper)
Vue.use(Rate)
Vue.use(Circle)
Vue.use(Sticky)
Vue.use(Popover)
