import mobile from '@/utils/mobile'

const qrCodeCacheSaveKey = 'cacheCode'

const getCacheCodeHandler = async () => {
  return new Promise(async resolve => {
    try {
      const result = await mobile.getSaveData(qrCodeCacheSaveKey)
      resolve((result && result.value) ? result.value : null)
    } catch (error) {
      console.error('getCacheCodeHandler', error)
    }
  })
}

// 设置扫码的code码缓存到手机中
const setCacheCodeHandler = async (params) => {
  return new Promise(async (resolve) => {
    try {
      console.log('params: ', params)
      const { code, time } = params
      const cacheCode = await getCacheCodeHandler() || {}
      cacheCode[code] = time
      let saveObj = {
        key: qrCodeCacheSaveKey,
        value: cacheCode
      }
      await mobile.saveData(saveObj)
      resolve()
    } catch (error) {
      console.error('setCacheCodeHandler error: ', error)
    }
  })
}
// 设置扫码的code码缓存到手机中
const delCacheCodeHandler = async ({ params }) => {
  return new Promise(async resolve => {
    mobile.deleteSaveData(qrCodeCacheSaveKey)
    resolve('ok')
  })
}

export {
  getCacheCodeHandler,
  setCacheCodeHandler,
  delCacheCodeHandler
}
