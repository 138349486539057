import { cloneDeep, isArray } from 'lodash'
import moment from 'moment'
import store from '@/store'
// import { Notify, Toast } from 'vant'
import { Toast } from 'vant'
import {
  getOssParams,
  isJsonString,
  tryUploadImgByAppOrH5,
  uploadPhotoByImgKeys
} from '@/plugins/offlineMixin/utils/offlineImgTool'
import { apiOffline } from '@/plugins/offlineMixin/apiOfflineGetway'
import { getOrderDetailOfflineApiHandler } from '@/plugins/offlineMixin/offlineApiHandlers/OrderInfoOfflineApiHandlers'

// 待上传中全部提交 ，整理上传参数
const AllOfflineOrdersSubmitWrapper = async (params) => {
  return new Promise(async resolve => {
    let copyList = cloneDeep(params)
    let paramsList = []
    for (let orderDetail of copyList) {
      let { order_type = '' } = orderDetail.model
      let { form = {} } = orderDetail
      if (store.getters.getOrderTypeConf.taskOrderTypes.includes(order_type)) {
        paramsList.push(_setParamsData(await _setSubmitData(orderDetail), orderDetail.buttonInfo))
      } else {
        if (form.attachments && form.attachments.length > 0) {
          form.attachments = await uploadPhotoByImgKeys(form.attachments)
        }
        paramsList.push(form)
      }
    }
    resolve(paramsList)
  })
}
const getAllOfflineImgKeysInPatroItem = (orderDetail, patroItemId) => {
  // 拿出所有的离线图片key（任务里的）
  const tatolTaskItemOfflineImgKeys = (orderDetail.orderTaskItemExtModelList || []).reduce((patro_imgs, item) => {
    if (item.itemId !== patroItemId) return patro_imgs
    console.log('item: ', item)
    const new_patro_imgs = (item.options || []).reduce((option_imgs, ite) => {
      const new_option_imgs = (ite.typeOptions || []).reduce((typeOption_imgs, typeItem) => {
        if ((typeItem.type === 7 || typeItem.type === 8 || typeItem.type === 9) && typeItem.val && isArray(typeItem.val) && typeItem.val.length > 0) {
          typeOption_imgs = typeOption_imgs.concat(typeItem.val.filter(photo => !photo.url))
        }
        return typeOption_imgs
      }, [])
      option_imgs = option_imgs.concat(new_option_imgs)
      return option_imgs
    }, [])
    patro_imgs = patro_imgs.concat(new_patro_imgs)
    return patro_imgs
  }, [])
  console.log('任务中的tatolTaskItemOfflineImgs: ', tatolTaskItemOfflineImgKeys, tatolTaskItemOfflineImgKeys.length)

  // 拿出所有的离线图片key（记录的问题里的）
  let tatolProblemPhotoOfflineImgKeys = []
  if (orderDetail.pinkongProblemReq && orderDetail.pinkongProblemReq.problemRecordList && orderDetail.pinkongProblemReq.problemRecordList.length > 0) {
    tatolProblemPhotoOfflineImgKeys = orderDetail.pinkongProblemReq.problemRecordList.reduce((imgKeys, item) => {
      if (item.itemId !== patroItemId) return imgKeys
      if (item.attachmentList && isArray(item.attachmentList) && item.attachmentList.length > 0) {
        imgKeys = imgKeys.concat(item.attachmentList.filter(i => !i.url))
      }
      return imgKeys
    }, [])
  }
  console.log('问题记录里的tatolProblemPhotoOfflineImgKeys: ', tatolProblemPhotoOfflineImgKeys)
  const totalOfflineImgKeys = tatolTaskItemOfflineImgKeys.concat(tatolProblemPhotoOfflineImgKeys)
  console.log('totalOfflineImgKeys: ', totalOfflineImgKeys)
  console.log('该工单所有图片数量totalPhotoCount: ', totalOfflineImgKeys.length)
  return totalOfflineImgKeys
}

/**
 * @param params  在线提交任务项
 * @constructor
 */

const TaskItemSubmitWrapper = async (params) => {
  console.log('params: ', params)
  return new Promise(async (resolve, reject) => {
    try {
      const { patroItem, orderDetail } = params
      console.log('orderDetail: ', orderDetail)
      console.log('patroItem: ', patroItem)
      // 需要统计所有需要的图片数量
      console.log('>>>>>_TaskItemSubmitWrapper：', orderDetail)
      let orderDetailCopy = cloneDeep(orderDetail)
      let patroItemCopy = cloneDeep(patroItem)
      const ossParams = await getOssParams()
      const totalOfflineImgKeys = getAllOfflineImgKeysInPatroItem(orderDetailCopy, patroItemCopy.itemId)
      console.log('totalOfflineImgKeys: ', totalOfflineImgKeys)
      let totalPhotoCount = totalOfflineImgKeys.length
      if (totalPhotoCount > 0) {
        var uploadPhotoToast = Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: `上传图片0/${totalPhotoCount}`
        })
      }
      let uploadSuccess = 0
      for (let list of patroItemCopy.options) {
        for (let typeItem of list.typeOptions) {
          // 如果type为7则为照相并且有值得情况下需要先将base64转成File文件并且上传到oss获取oss地址
          if ((typeItem.type === 7 || typeItem.type === 8 || typeItem.type === 9) && typeItem.val && typeItem.val.length > 0 && isArray(typeItem.val)) {
            let imgList = []
            for (let photoItem of typeItem.val) {
              if (photoItem.url) {
                imgList.push(photoItem)
              } else {
                // 不需要try..catch，因为图片上传失败后不能提交工单
                try {
                  const res = await tryUploadImgByAppOrH5(ossParams, photoItem)
                  imgList.push(res)
                } catch (error) {
                  console.error('error: ', error)
                  uploadPhotoToast && uploadPhotoToast.clear()
                  throw error
                }
                ++uploadSuccess
                uploadPhotoToast && (uploadPhotoToast.message = `上传图片${uploadSuccess}/${totalPhotoCount}`)
              }
            }
            // 并且吧val的值转成字符串
            if (typeItem.val && typeItem.val.length > 0 && !isJsonString(typeItem.val)) {
              typeItem.val = JSON.stringify(imgList)
              typeItem.optionAttachments = imgList
            }
          }
          // 如果type为7并且val是空数组，按照后端要求转换为null
          if ((typeItem.type === 7 || typeItem.type === 8 || typeItem.type === 9) && typeItem.val && typeItem.val.length === 0) {
            typeItem.val = null
          }
          // type为4则为多选，把值用,相连
          if (typeItem.type === 4 && typeItem.val && typeItem.val.length > 0 && typeof typeItem.val === 'object') {
            typeItem.val = typeItem.val.join(',')
          }
          if (typeItem.type === 10) {
            // 后端要求把finalScore也设置上
            typeItem.finalScore = typeItem.val
            typeItem.val = `${typeItem.val} | ${typeItem.singleScore}`
          }
        }
      }
      // 一些特定的数据根据后端要求重置
      if (orderDetailCopy.pinkongProblemReq && orderDetailCopy.pinkongProblemReq.problemRecordList && orderDetailCopy.pinkongProblemReq.problemRecordList.length > 0) {
        // 只处理itemId一样的
        orderDetailCopy.pinkongProblemReq.problemRecordList = orderDetailCopy.pinkongProblemReq.problemRecordList.filter(item => item.itemId === patroItemCopy.itemId)
        for (let problemRecord of orderDetailCopy.pinkongProblemReq.problemRecordList) {
          if (problemRecord.itemId !== patroItemCopy.itemId) break
          console.log(patroItemCopy.itemId)
          if (problemRecord.attachmentList && problemRecord.attachmentList.length > 0) {
            let imgList = []
            for (let imgCacheKey of problemRecord.attachmentList) {
              try {
                const imgUrl = await tryUploadImgByAppOrH5(ossParams, imgCacheKey)
                imgList.push(imgUrl)
              } catch (error) {
                console.error('error: ', error)
                uploadPhotoToast && uploadPhotoToast.clear()
                throw error
              }
              ++uploadSuccess
              uploadPhotoToast && (uploadPhotoToast.message = `上传图片${uploadSuccess}/${totalPhotoCount}`)
            }
            problemRecord.attachmentList = imgList
          }
          for (let list of problemRecord.problemList) {
            if (list.questLabel && list.questLabel.length > 0 && !isJsonString(list.questLabel)) {
              list.questLabel = JSON.stringify(list.questLabel)
            } else {
              list.questLabel = null
            }
            if (list.rectifyPeopls && list.rectifyPeopls.code && !isJsonString(list.rectifyPeopls)) {
              list.rectifyPeopls = JSON.stringify(list.rectifyPeopls)
            } else {
              list.rectifyPeopls = null
            }
          }
        }
        // if (store.getters.getIsCJ) listData.pinkongProblemReq.orderNature = 6 // 承接查验模块创建问题工单，需要带上orderNature = 6
      }
      uploadPhotoToast && uploadPhotoToast.clear()
      patroItemCopy.device = 'APP'
      // const submitForm = [{
      //   orderNo: orderDetailCopy.model.order_no,
      //   taskOrderItemReqs: [patroItemCopy],
      //   pinkongProblemReq: orderDetailCopy.pinkongProblemReq
      // }]
      const submitForm = [{
        orderNo: orderDetailCopy.model.order_no,
        taskOrderItemReqs: [patroItemCopy],
        pinkongProblemReq: orderDetailCopy.pinkongProblemReq
      }]
      // 组装任务项提交的参数
      console.log('>>>>>提交前封装的数据：', cloneDeep(submitForm))
      resolve(submitForm)
    } catch (error) {
      console.error('错误', error)
      reject(error)
    }
  })
}

/**
 * @param params  参数结构： { params: detail}
 * @constructor
 */
const OrderDetailSubmitWrapper = async (params) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log('>>>>params in wrapper: ', params)
      const { orderId, buttonInfo } = params
      const orderDetail = await apiOffline({
        params: { id: orderId },
        offlineApiHandlers: getOrderDetailOfflineApiHandler,
        forceOffline: true
      })
      console.log('>>>>>获取的orderDetail：', cloneDeep(orderDetail))
      // 根据order-type判断工单类型
      let wrapperParams
      let { order_type = '' } = orderDetail.model
      let { form = {} } = orderDetail
      // 任务工单
      if (store.getters.getOrderTypeConf.taskOrderTypes.includes(order_type)) {
        wrapperParams = _setParamsData(await _setSubmitData(orderDetail), buttonInfo)
      } else {
        // 问题工单
        if (form.attachments && form.attachments.length > 0) {
          form.attachments = await uploadPhotoByImgKeys(form.attachments)
        }
        wrapperParams = form
      }
      console.log('>>>>>提交前封装的数据：', cloneDeep(wrapperParams))
      resolve(wrapperParams)
    } catch (error) {
      console.error('错误', error)
      reject(error)
    }
  })
}
const getAllOfflineImgKeysInOrderDetail = (orderDetail) => {
  // 拿出所有的离线图片key（任务里的）
  const tatolTaskItemOfflineImgKeys = (orderDetail.orderTaskItemExtModelList || []).reduce((patro_imgs, item) => {
    console.log('item: ', item)
    const new_patro_imgs = (item.options || []).reduce((option_imgs, ite) => {
      const new_option_imgs = (ite.typeOptions || []).reduce((typeOption_imgs, typeItem) => {
        if ((typeItem.type === 7 || typeItem.type === 8 || typeItem.type === 9) && typeItem.val && isArray(typeItem.val) && typeItem.val.length > 0) {
          typeOption_imgs = typeOption_imgs.concat(typeItem.val.filter(photo => !photo.url))
        }
        return typeOption_imgs
      }, [])
      option_imgs = option_imgs.concat(new_option_imgs)
      return option_imgs
    }, [])
    patro_imgs = patro_imgs.concat(new_patro_imgs)
    return patro_imgs
  }, [])
  console.log('任务中的tatolTaskItemOfflineImgs: ', tatolTaskItemOfflineImgKeys, tatolTaskItemOfflineImgKeys.length)

  // 拿出所有的离线图片key（记录的问题里的）
  let tatolProblemPhotoOfflineImgKeys = []
  if (orderDetail.pinkongProblemReq && orderDetail.pinkongProblemReq.problemRecordList && orderDetail.pinkongProblemReq.problemRecordList.length > 0) {
    tatolProblemPhotoOfflineImgKeys = orderDetail.pinkongProblemReq.problemRecordList.reduce((imgKeys, item) => {
      if (item.attachmentList && isArray(item.attachmentList) && item.attachmentList.length > 0) {
        imgKeys = imgKeys.concat(item.attachmentList.filter(i => !i.url))
      }
      return imgKeys
    }, [])
  }
  console.log('问题记录里的tatolProblemPhotoOfflineImgKeys: ', tatolProblemPhotoOfflineImgKeys)
  const totalOfflineImgKeys = tatolTaskItemOfflineImgKeys.concat(tatolProblemPhotoOfflineImgKeys)
  console.log('totalOfflineImgKeys: ', totalOfflineImgKeys)
  console.log('该工单所有图片数量totalPhotoCount: ', totalOfflineImgKeys.length)
  return totalOfflineImgKeys
}

// 设置上传数据 图片先上传pss
const _setSubmitData = async (orderDetail) => {
  // 需要统计所有需要的图片数量
  console.log('>>>>>_setSubmitData：', cloneDeep(orderDetail))
  const ossParams = await getOssParams()
  let listData = cloneDeep(orderDetail)
  // 过滤掉不是离线提交的任务项
  listData.orderTaskItemExtModelList = listData.orderTaskItemExtModelList.filter(i => i.offlineSubmit === 1)
  if (listData.pinkongProblemReq && listData.pinkongProblemReq.problemRecordList && listData.pinkongProblemReq.problemRecordList.length > 0) {
    listData.pinkongProblemReq.problemRecordList = listData.pinkongProblemReq.problemRecordList.filter(i => {
      const hasPatro = !!listData.orderTaskItemExtModelList.find((patroItem) => patroItem.itemId === i.itemId)
      console.log('是否在任务项中hasPatro: ', hasPatro)
      return hasPatro
    })
  }
  const totalOfflineImgKeys = getAllOfflineImgKeysInOrderDetail(listData)
  let totalPhotoCount = totalOfflineImgKeys.length
  if (totalPhotoCount > 0) {
    var uploadPhotoToast = Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
      message: `上传图片0/${totalPhotoCount}`
    })
  }
  let uploadSuccess = 0
  /*
    打分需要
  */
  let hasGradeOption = false // 整个工单的任务项中是否有打分操作
  let totalScore = 0 // 总分合计
  let finalScore = 0 // 得分合计
  for (let item of listData.orderTaskItemExtModelList) {
    for (let list of item.options) {
      for (let typeItem of list.typeOptions) {
        // 如果type为7则为照相并且有值得情况下需要先将base64转成File文件并且上传到oss获取oss地址
        if ((typeItem.type === 7 || typeItem.type === 8 || typeItem.type === 9) && typeItem.val && typeItem.val.length > 0 && isArray(typeItem.val)) {
          let imgList = []
          for (let photoItem of typeItem.val) {
            if (photoItem.url) {
              imgList.push(photoItem)
            } else {
              // 不需要try..catch，因为图片上传失败后不能提交工单
              try {
                const res = await tryUploadImgByAppOrH5(ossParams, photoItem)
                imgList.push(res)
              } catch (error) {
                console.error('error: ', error)
                uploadPhotoToast && uploadPhotoToast.clear()
                throw error
              }
              ++uploadSuccess
              uploadPhotoToast && (uploadPhotoToast.message = `上传图片${uploadSuccess}/${totalPhotoCount}`)
            }
          }
          // 并且吧val的值转成字符串
          if (typeItem.val && typeItem.val.length > 0 && !isJsonString(typeItem.val)) {
            typeItem.val = JSON.stringify(imgList)
            typeItem.optionAttachments = imgList
          }
        }
        // 如果type为7并且val是空数组，按照后端要求转换为null
        if ((typeItem.type === 7 || typeItem.type === 8 || typeItem.type === 9) && typeItem.val && typeItem.val.length === 0) {
          typeItem.val = null
        }
        // type为4则为多选，把值用,相连
        if (typeItem.type === 4 && typeItem.val && typeItem.val.length > 0 && typeof typeItem.val === 'object') {
          typeItem.val = typeItem.val.join(',')
        }
        if (typeItem.type === 10) {
          // 后端要求把finalScore也设置上
          typeItem.finalScore = typeItem.val
          typeItem.val = `${typeItem.val} | ${typeItem.singleScore}`
          hasGradeOption = true
        }
      }
    }
    // 不含【不适用】任务项的相关得分
    if (item.gradeStatus !== 2) {
      totalScore += item.totalScore * 10
      finalScore += item.finalScore * 10
    }
  }
  console.log('hasGradeOption: ', hasGradeOption)
  // 如果整个工单的任务项中有打分操作
  if (hasGradeOption) {
    // 设置总分
    listData.totalScore = totalScore / 10
    listData.finalScore = finalScore / 10
    console.log('finalScore: ', finalScore)
    console.log('totalScore: ', totalScore)
  }
  console.log('加入分数listData: ', listData)
  // 一些特定的数据根据后端要求重置
  if (listData.pinkongProblemReq && listData.pinkongProblemReq.problemRecordList && listData.pinkongProblemReq.problemRecordList.length > 0) {
    for (let item of listData.pinkongProblemReq.problemRecordList) {
      if (item.attachmentList && item.attachmentList.length > 0) {
        let imgList = []
        for (let imgCacheKey of item.attachmentList) {
          try {
            const imgUrl = await tryUploadImgByAppOrH5(ossParams, imgCacheKey)
            imgList.push(imgUrl)
          } catch (error) {
            console.error('error: ', error)
            uploadPhotoToast && uploadPhotoToast.clear()
            throw error
          }
          ++uploadSuccess
          uploadPhotoToast && (uploadPhotoToast.message = `上传图片${uploadSuccess}/${totalPhotoCount}`)
        }
        item.attachmentList = imgList
      }
      for (let list of item.problemList) {
        if (list.questLabel && list.questLabel.length > 0 && !isJsonString(list.questLabel)) {
          list.questLabel = JSON.stringify(list.questLabel)
        } else {
          list.questLabel = null
        }
        if (list.rectifyPeopls && list.rectifyPeopls.code && !isJsonString(list.rectifyPeopls)) {
          list.rectifyPeopls = JSON.stringify(list.rectifyPeopls)
        } else {
          list.rectifyPeopls = null
        }
      }
    }
    // if (store.getters.getIsCJ) listData.pinkongProblemReq.orderNature = 6 // 承接查验模块创建问题工单，需要带上orderNature = 6
  }
  uploadPhotoToast && uploadPhotoToast.clear()
  return listData
}

const _setParamsData = (data, buttonInfo) => {
  console.log('按钮buttonInfo: ', buttonInfo)
  // 按照后端要求修改上传的字段
  let obj = cloneDeep(data)
  let params = {}
  params.orderNo = obj.model.order_no
  params.isattention = obj.model.isattention
  params.buttonName = buttonInfo.buttonName
  params.curNodeId = obj.model.order_status
  params.bigCategoryCode = obj.model.big_category_code
  params.smallCategoryCode = obj.model.small_category_code
  params.handlerId = obj.model.login_user_id
  params.handlerName = obj.model.handler_name
  params.taskOrderItemReqs = obj.orderTaskItemExtModelList.map(item => {
    item.device = 'APP'
    return item
  })
  params.handleEnpoint = 'APP'
  /* 打分 */
  if (obj.finalScore || obj.finalScore === 0) {
    params.finalScore = obj.finalScore
  }
  if (obj.totalScore || obj.totalScore === 0) {
    params.totalScore = obj.totalScore
  }
  params.pinkongProblemReq = obj.pinkongProblemReq
  params.commitTime = moment().format('YYYY-MM-DD HH:mm:ss')
  return params
}

export {
  OrderDetailSubmitWrapper,
  AllOfflineOrdersSubmitWrapper,
  getAllOfflineImgKeysInOrderDetail,
  TaskItemSubmitWrapper,
  getAllOfflineImgKeysInPatroItem
}
