import http from '@/api/base/index'
import store from '../../store'
import { ONLINE_REQUEST_SUCCESS } from '@/plugins/offlineMixin/utils/StaticConstantCode'
/**
 * 注：离线返回为空会再次请求在线数据
 * @param api api请求地址
 * @param params 请求参数
 * @param offlineApiHandlers 离线模式的处理方法。为传入则强制走在线。传参格式: {params: params}
 * @param methods 请求方式 post get
 * @param forceOffline 强制走离线标志，可以不传api参数
 * @param onlineSubmitDataWrapper 在线的params处理方法。传参格式: params
 * @param showLoading http是否显示loading
 * @returns {Promise<unknown>}
 */
const apiOffline = async ({ api, params, offlineApiHandlers, methods, forceOffline, onlineSubmitDataWrapper, showLoading = true }) => {
  try {
    const isOffline = store.getters?.getIsOffline
    const offlineStatus = store.getters?.getOfflineStatus
    if ((!isOffline || forceOffline) && offlineApiHandlers) {
      const result = await offlineApiHandlers({ params })
      console.log('offline getway result:', result)
      if (!result && !forceOffline) {
        if (offlineStatus === 1) { // 弱网无离线包仍然请求服务器数据
          console.log('apiOffline 弱网请求')
          const paramsData = onlineSubmitDataWrapper ? await onlineSubmitDataWrapper(params) : params
          console.log('paramsData: ', paramsData)
          const result = await http[methods](api, paramsData, showLoading)
          return Promise.resolve(result || { code: ONLINE_REQUEST_SUCCESS })
        }
      }
      return Promise.resolve(result || [])
    } else {
      console.log('>>>>>params in apiOffline', params)
      const paramsData = onlineSubmitDataWrapper ? await onlineSubmitDataWrapper(params) : params
      console.log('paramsData: ', paramsData)
      const result = await http[methods](api, paramsData, showLoading)
      return Promise.resolve(result || { code: ONLINE_REQUEST_SUCCESS })
    }
  } catch (e) {
    const error = new Error('apiOfflineGetway请求异常, error: ', e)
    e.code && (error.code = e.code)
    throw error
  }
}

export {
  apiOffline
}
