import { cloneDeep } from 'lodash'
import { TEMPORARY_STORAGE_FIELDS } from '@/plugins/offlineMixin/utils/StaticConstantCode'
import moment from 'moment'

/**
 * 根据id从list中获取详情
 * @param id
 * @param offlineOrderList
 * @param isStandardFormat 注意：返回和detail同级的所有数据
 * @returns {*}
 */
const getOrderDetailFromOfflineList = (id, offlineOrderList, isStandardFormat) => {
  console.log('id, offlineOrderList, isStandardFormat: ', id, cloneDeep(offlineOrderList), isStandardFormat)
  let detail
  offlineOrderList.forEach(standard => {
    if (standard.id === parseInt(id)) { // 问题工单
      detail = isStandardFormat ? standard : standard.detail
    } else { // 任务工单
      standard.orders && standard.orders.forEach((orderItem) => {
        if (orderItem.detail.model.id === parseInt(id)) {
          detail = isStandardFormat ? orderItem : orderItem.detail
        }
      })
    }
  })
  return detail
}

// 过滤掉待上传离线列表里面的工单
const offlineOrderListFilterWaitingUploadList = (offlineOrderList, waitingUploadList) => {
  if (!offlineOrderList) return []
  let list = cloneDeep(offlineOrderList)
  for (let i = 0; i < list.length; i++) {
    const dataItem = list[i]
    if (dataItem.orderNo) { // 问题工单也走离线，过滤
      for (let k = 0; k < waitingUploadList.length; k++) {
        let offlineItem = waitingUploadList[k]
        if (offlineItem === dataItem.id) {
          list.splice(i, 1)
          continue
        }
      }
      continue
    }
    if (dataItem.orders && dataItem.orders.length === 0) { // 任务工单为空的直接跳过
      continue
    }
    for (let j = 0; j < dataItem.orders.length; j++) {
      const orderItem = dataItem.orders[j]
      for (let k = 0; k < waitingUploadList.length; k++) {
        let offlineItem = waitingUploadList[k]
        if (offlineItem === orderItem.id) {
          if (orderItem.ismature) {
            dataItem.matureNum -= 1
          }
          dataItem.taskNum -= 1
          dataItem.orders.splice(j, 1)
          j--
        }
      }
    }
  }
  return list
}

/*
  合并newOfflineList 和 cacheOfflineList
*/
const mergeOfflineOrderList = (newOfflineList, cacheOfflineList) => {
  console.log('mergeOfflineOrderList', cloneDeep(newOfflineList), cloneDeep(cacheOfflineList))
  if (newOfflineList && newOfflineList.length > 0) {
    for (const list of newOfflineList) {
      if (list.id) { // 问题工单/升级工单的离线数据状态更新
        // 新下载的离线问题工单是最新状态，所以不需要更新基础状态信息，但是会更新离线提交等操作信息
        const cacheOrderDetail = getOrderDetailFromOfflineList(list.id, cacheOfflineList)
        cacheOrderDetail && mergeServerOrderAndCacheOrder(cacheOrderDetail, list.detail)
      } else if (list.orders) { // 任务工单的离线数据状态更新
        list.orders.forEach((orderItem) => {
          // 新下载的离线问题工单是最新状态，所以不需要更新基础状态信息，但是会更新离线提交等操作信息
          const cacheOrderDetail = getOrderDetailFromOfflineList(orderItem.id, cacheOfflineList)
          cacheOrderDetail && mergeServerOrderAndCacheOrder(cacheOrderDetail, orderItem.detail)
        })
      }
    }
  }
  return newOfflineList
}

/**
 * 将cacheOrder, serverOrder相互赋值成最新的cacheOrder, serverOrder
 * cacheParams数组是本地执行工单的离线数据，只存在于本地。其中form是问题/升级工单离线提交的数据
 * @param {*} serverOrder 引用变量
 * @param {*} cacheOrder
 */
const mergeServerOrderAndCacheOrder = (cacheOrder, serverOrder) => {
  // console.log('合并前 cacheOrder, serverOrder: ', cloneDeep(cacheOrder), cloneDeep(serverOrder))
  const problemItemDataResps = cacheOrder.problemItemDataResps ? cacheOrder.problemItemDataResps : serverOrder.problemItemDataResps
  for (const key in cacheOrder) {
    if (TEMPORARY_STORAGE_FIELDS.includes(key)) {
      // 任务项：扫码签到会完成任务项，所以合并时只合并在线数据里未完成的任务项
      if (key === 'orderTaskItemExtModelList') {
        // 循环在线工单的orderTaskItemExtModelList
        for (let index = 0; index < serverOrder[key].length; index++) {
          const taskItem = serverOrder[key][index]
          if (taskItem.status === 0) { // 在线任务没有完成
            // 如果当前任务项有离线完成的数据，保留
            if (cacheOrder[key][index].offlineSubmit === 1 || cacheOrder[key][index].temporaryStorage === 1) {
              serverOrder[key][index] = cacheOrder[key][index]
            } else {
              cacheOrder[key][index] = serverOrder[key][index]
            }
          } else {
            // 如果在线工单的当前任务项已完成，更新缓存工单的对应任务项
            // 20231205新需求 如果在线完成时间晚于离线完成时间，需要保留离线工单
            if (cacheOrder[key][index].status === 1 && cacheOrder[key][index].offlineSubmit === 1 && moment(cacheOrder[key][index].finishTime).isBefore(serverOrder[key][index].finishTime)) {
              // do nothing
              serverOrder[key][index] = cacheOrder[key][index]
            } else {
              cacheOrder[key][index] = serverOrder[key][index]
            }
          }
        }
      } else {
        serverOrder[key] = cacheOrder[key]
      }
    } else {
      cacheOrder[key] = serverOrder[key]
      // todo 因为只会同步detail字段下的数据，detail同级的工单状态需要更新，或者在首页显示model的状态
    }
  }
  cacheOrder['problemItemDataResps'] = serverOrder['problemItemDataResps'] = problemItemDataResps
  // console.log('合并后 cacheOrder, serverOrder: ', cloneDeep(cacheOrder), cloneDeep(serverOrder))
}

const delOrderDetailFromOfflineList = (id, offlineOrderList) => {
  console.log('id, offlineOrderList: ', id, offlineOrderList)
  offlineOrderList.forEach((standard, index) => {
    if (standard.id === parseInt(id)) { // 问题工单
      offlineOrderList.splice(index, 1)
    } else { // 任务工单
      standard.orders && standard.orders.forEach((orderItem, idx) => {
        if (orderItem.detail.model.id === parseInt(id)) {
          standard.orders.splice(idx, 1)
        }
      })
    }
  })
}

export {
  getOrderDetailFromOfflineList,
  mergeOfflineOrderList,
  offlineOrderListFilterWaitingUploadList,
  mergeServerOrderAndCacheOrder,
  delOrderDetailFromOfflineList
}
