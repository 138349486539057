import mobile from '@/utils/mobile'
import store from '@/store'
import api from '@/api/index'
import http from '@/api/base/index'
import tools from '@/utils/tools.js'
import { Toast, Notify } from 'vant'
import { cloneDeep } from 'lodash'

let _initOfflineData = {
  delayLoadBase64ImgList: [], // 获取App图片base64队列
  delayInterval: undefined // 获取App图片base64定时器,
}
const tryUploadImgByAppOrH5 = async (ossParams, imgKey) => {
  try {
    const res = await mobile.uploadBase64ByAppRequestWithFixFileName({ ...ossParams, imgKey })
    return Promise.resolve(res)
  } catch (error) {
    if (error.message && (error.message.includes('base64_error') || error.message.includes('base64_empty'))) {
      console.log('----==------==----app base64_error------==----->>>>', error)
      throw error
    }
  }
  // console.log('----==------==----tryUploadImgByAppOrH5------==----->>>>', error)
  // let result = await mobile.getSaveDataBase64(imgKey)
  // let res = await submitPhoto(dataURLtoBlobtoFile(result), ossParams)
  // console.log('tryUploadImgByAppOrH5 res: ', res)
  // delete res.file
  // return Promise.resolve(res)
}

const uploadPhotoByImgKeys = async (imgkeysListParams) => {
  return new Promise(async (resolve, reject) => {
    if (imgkeysListParams && imgkeysListParams.length > 0) {
      const imgkeysList = cloneDeep(imgkeysListParams)
      console.log('imgkeysList: ', imgkeysList)
      const uploadPhotoToast = Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: `上传图片0/${imgkeysList.length}`
      })
      try {
        const imgkeysListResult = []
        let ossParams = await getOssParams()
        let totalPhotoCount = imgkeysList.length
        console.log('totalPhotoCount: ', totalPhotoCount)
        let uploadSuccess = 0
        for await (let imgKey of imgkeysList) {
          const imgUrl = await tryUploadImgByAppOrH5(ossParams, imgKey)
          imgkeysListResult.push(imgUrl)
          ++uploadSuccess
          uploadPhotoToast && (uploadPhotoToast.message = `上传图片${uploadSuccess}/${totalPhotoCount}`)
        }
        resolve(imgkeysListResult)
      } catch (error) {
        Notify({ type: 'warning', message: '图片上传失败，请重试' })
        reject(error)
      } finally {
        uploadPhotoToast && uploadPhotoToast.clear()
      }
    } else {
      resolve()
    }
  })
}

// oss图片地址转成base64地址
const OssConversionBase64 = (url) => {
  return new Promise((resolve) => {
    let changeUrl = url
    if (!store.getters.getIsOffline) {
      mobile.getOssConversionBase64(url, res => {
        if (res) {
          changeUrl = res
          resolve(changeUrl)
        }
      })
    } else {
      resolve(changeUrl)
    }
  })
}

// 获取ali-oss信息
const getOssParams = async () => {
  let res = await http.get(api.getOssParams)
  return res
}

// 判断是否是JSON字符串
const isJsonString = (str) => {
  try {
    if (typeof JSON.parse(str) === 'object') {
      return true
    }
  } catch (e) {
  }
  return false
}

// 加载本地图片队列
const loadAsyncImgByQueue = (id, cb) => {
  _initOfflineData.delayLoadBase64ImgList.push({ id, cb })
  if (!_initOfflineData.delayInterval) {
    _initOfflineData.delayInterval = setInterval(async () => {
      const { id: id1, cb: cb1 } = _initOfflineData.delayLoadBase64ImgList.shift()
      if (_initOfflineData.delayLoadBase64ImgList.length === 0) {
        clearInterval(_initOfflineData.delayInterval)
        _initOfflineData.delayInterval = undefined
      }
      const imgData = await mobile.getSaveDataBase64(id1)
      cb1(imgData)
    }, 100)
  }
}

// BASE64 转成file文件
const dataURLtoBlobtoFile = (dataurl) => {
  let arr = dataurl.split(',')
  let mime = arr[0].match(/:(.*?);/)[1]
  let bstr = atob(arr[1])
  let n = bstr.length
  let u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  let theBlob = new Blob([u8arr], { type: mime })
  theBlob.lastModifiedDate = new Date()
  let file = new window.File([theBlob], 'test.jpeg', { type: mime })
  return file
}
const submitPhoto = async (file, ossParam) => {
  if (!ossParam) return
  let e = await tools.offlineUpload(ossParam, { file: file })
  let { url, name, fileSize, fileType } = e
  return {
    file,
    url,
    name,
    fileSize,
    fileType,
    content: url,
    status: '',
    datetime: new Date()
  }
}

export {
  OssConversionBase64,
  getOssParams,
  dataURLtoBlobtoFile,
  isJsonString,
  submitPhoto,
  loadAsyncImgByQueue,
  tryUploadImgByAppOrH5,
  uploadPhotoByImgKeys
}
