<template v-cloak>
  <div class="upload-wrapper">
    <van-field name="attachmentList" class="form-upload" :required="required"
      :label="`${text}(可上传${maxCount}张)`" :disabled="disabled">
      <template #input>
        <div class="upload-list-box">
          <div class="uplaod-list-wrapper">
            <div class="upload-list" v-for="(item, index) in imgList" :key="index" >
              <img class="upload-img" :src="item" @click="imgPreview(index)"/>
              <i class="delete-icon" @click.prevent.stop="deleteFile(index,item)"></i>
            </div>
            <p class="upload-bg" @click="uploadApp" v-if=" !file || file.length < maxCount">
              <van-icon name="plus" />
            </p>
          </div>
        </div>
      </template>
    </van-field>
  </div>
</template>

<script type='text/ecmascript-6'>
import mobile from '@/utils/mobile'
import { loadAsyncImgByQueue } from '@/plugins/offlineMixin/utils/offlineImgTool'
import { ImagePreview } from 'vant'
export default {
  name: 'CommonUpload',
  components: {},
  props: {
    file: { // 本来就有的图片
      type: [Array, String],
      require: true,
      default: () => []
    },
    maxCount: { // 最大上传图片数量
      type: Number,
      default: 10
    },
    required: { // 必须上传
      type: Boolean,
      default: false
    },
    text: { // 可上传前面的文字
      type: String,
      default: '图片'
    },
    projectName: {
      type: String,
      default: ''
    },
    disabled: { // 判断是否禁用 详情时禁用 除处理中状态外均为详情
      type: Boolean,
      default: false
    },
    enableEdit: { // 是否允许编辑
      type: Boolean,
      default: true
    },
    enableAlbum: { // 能否选择相册
      type: Boolean,
      default: false
    },
    enableWatermark: { // 是否有水印
      type: Boolean,
      default: true
    },
    selfUpdateKeyField: {
      type: String,
      default: ''
    },
    ossParams: {
      type: Object,
      default: () => null
    },
    orderId: {
      type: Number,
      default: () => null
    }
  },
  data () {
    return {
      fileList: [],
      imgList: []
    }
  },
  computed: {
    isOffline () {
      return this.$store.getters.getIsOffline
    }
  },
  created () { },
  mounted () {
    this._setFileList()
  },
  methods: {
    async _setFileList () {
      this.fileList = this.file || []
      if (this.fileList.length > 0) {
        for (const item of this.fileList) {
          console.log('item: ', item)
          loadAsyncImgByQueue(item, res => {
            this.imgList.push(res)
          })
        }
      }
    },
    async uploadApp () {
      console.log('公共上传组件内ossParams', this.ossParams)
      console.log('公共上传组件内orderId', this.orderId)
      console.log('offline upload this.projectName', this.projectName)
      if (this.disabled) return
      // 如果需要水印，又没有项目名称
      if (this.enableWatermark && !this.projectName) {
        this.$toast('请先选择所在项目')
        return
      }
      if (this.ossParams && !this.isOffline) { /// 需要在线上传，但是没有网络，做无网提示
        this.$EB.$emit(this.$EBT.NO_NETWORK_WARN)
        return
      }
      let maxAlbums = 1
      if (this.enableAlbum) {
        maxAlbums = this.maxCount - this.imgList.length
      }
      console.log('maxAlbums: ', maxAlbums)
      console.log('this.enableWatermark : ', this.enableWatermark)
      // const picPostBody = {
      //   cmdType: 'takePhoto', // APP指定值
      //   params: {
      //     projectName: this.projectName,
      //     enableEdit: this.enableEdit ? 'YES' : 'NO',
      //     enableAlbum: this.enableAlbum ? 'YES' : 'NO',
      //     enableWatermark: this.enableWatermark ? 'YES' : 'NO',
      //     maxAlbums
      //   },
      //   callbackMethod: 'getPicDatas'
      // }
      const params = {
        projectName: this.projectName,
        enableEdit: this.enableEdit ? 'YES' : 'NO',
        enableAlbum: this.enableAlbum ? 'YES' : 'NO',
        enableWatermark: this.enableWatermark ? 'YES' : 'NO',
        maxAlbums
      }
      if (this.ossParams) { // ⽴即上传则需要ossParams
        params.ossParams = this.ossParams
      }
      if (this.orderId) { // 离线上传 和工单有关的带上orderId
        params.orderId = this.orderId
      }
      console.log('拍照params: ', this.lodash.cloneDeep(params))
      try {
        const picResult = await mobile.openCommonPic(params)
        console.log('picResult: ', picResult)
        console.log('picResult: ', typeof picResult)
        for (let i = 0; i < picResult.length; i++) {
          const key = picResult[i]
          console.log('拍照完后的key: ', key)
          if (key.indexOf('http') > -1) {
            console.log('是url')
            this.imgList.push(key)
            this.fileList.push({ name: 'test.jpeg', url: key, fileType: 'jpg', content: key })
          } else {
            console.log('是key')
            const picBase64Data = await mobile.getSaveDataBase64(key)
            console.log('get upload img success:')
            this.imgList.push(picBase64Data)
            this.fileList.push(key)
          }
        }
        console.log('this.fileList: ', this.fileList)
        this.$emit('update:file', this.fileList)
        this.$emit('change')
      } catch (error) {
        this.$toast(error)
      }
    },
    imgPreview (index) {
      if (this.disabled) return
      this.imagesGroup = []
      this.imgList && this.imgList.forEach((item) => {
        this.imagesGroup.push(item)
      })
      ImagePreview({
        images: this.imagesGroup,
        startPosition: index
      })
    },
    async deleteFile (index, img) {
      if (this.disabled) return
      this.fileList.splice(index, 1)
      this.imgList.splice(index, 1)
      this.$emit('update:file', this.fileList)
      this.$emit('change')
    },
    clearAllFile () {
      this.fileList = []
      this.imgList = []
      this.$emit('update:file', this.fileList)
    },
    resetData () {
      Object.assign(this.$data, this.$options.data())
    }
  }
}
</script>
<style  lang='less' scoped>
.upload-wrapper {
  .form-upload {
    &.van-cell {
      /deep/ .van-cell__title {
        font-size: 14px;
        color: #8b8f9a;
        margin-bottom: 6px;
      }
      padding: 0;
      &:after {
        border-bottom: none;
      }
    }
    .upload-list-box {
      width: 100%;
      overflow: hidden;
    }
    .uplaod-list-wrapper {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      width: calc(100% + 15px);
      .upload-list {
        width: 48px;
        height: 48px;
        margin-right: 15px;
        margin-bottom: 10px;
        position: relative;
        border-radius: 4px;
        .upload-img {
          width: 100%;
          height: 100%;
        }
        .delete-icon {
          display: block;
          width: 14px;
          height: 14px;
          position: absolute;
          right: -7px;
          top: -7px;
          background: url("../asset/icon_minus_blue@2x.png") no-repeat;
          background-size: 100% 100%;
        }
      }
      .upload-bg {
        width: 48px;
        height: 48px;
        border-radius: 4px;
        background-color: #f7f8fa;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        margin-bottom: 10px;
        .van-icon-plus {
          font-size: 24px;
          color: #dcdee0;
        }
      }
    }
  }
}
</style>
