
import http from '@/api/base/index'
import api from '@/api/index'
import watermark from 'watermark-dom'
import moment from 'moment'

const user = {
  state: {
    userInfo: {},
    twUserCode: undefined // 天问userCode
  },
  mutations: {
    setUserInfo (state, payload) {
      state.userInfo = payload.val
      console.log('state.userInfo: ', state.userInfo)
    },
    setTwUserCode (state, payload) {
      state.twUserCode = payload
      console.log('state.twUserCode: ', state.twUserCode)
    }
  },
  actions: {
    GetUserInfo ({ commit, state }) {
      console.log('GetUserInfo: ')
      return new Promise(async (resolve, reject) => {
        const res = await http.get(api.getUserInfo)
        console.log('GetUserInfo res: ', res)
        commit('setUserInfo', { val: res })
        // 加水印
        if (res.name) {
          watermark.load({
            watermark_txt: `「${res.name}」
            ${moment().format('YYYY-MM-DD')}`,
            watermark_x: 0,
            watermark_y: 0,
            watermark_rows: 2,
            watermark_angle: 35,
            watermark_alpha: 0.1, // 水印透明度，要求设置在大于等于0.005
            watermark_width: 80,
            watermark_height: 60, // 水印长度
            watermark_fontsize: '12px',
            watermark_y_space: 10,
            watermark_x_space: 10,
            watermark_parent_node: 'watermark_container'
          })
        }
        resolve()
      })
    },
    GetTwUserCode ({ commit, state }) {
      return new Promise(async (resolve, reject) => {
        const result = await http.get(api.getTwUserCode)
        console.log('getTwUserCode result: ', result)
        commit('setTwUserCode', result.userCode)
        resolve(result.userCode)
      })
    }
  }

}
export default user
