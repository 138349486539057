import storage from 'store'

const getStorageVal = (key) => {
  return storage.get(key)
}

const setStorageVal = (key, val) => {
  return storage.set(key, val)
}

export default {
  getStorageVal,
  setStorageVal
}
