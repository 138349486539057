<template>
  <span class="microIcon" :style="styleProp" :class="{'black':black}" @click="iconClick"></span>
</template>

<script>
import mobile from '@/utils/mobile'

export default {
  name: 'micro-text',
  props: {
    ptt: { // 识别结果是否去掉最后的中文符号
      type: Boolean,
      default: false
    },
    selfClick: {
      type: Boolean,
      default: false
    },
    black: {
      type: Boolean,
      default: false
    },
    styleProp: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
    }
  },
  methods: {
    iconClick () {
      if (this.selfClick) {
        this.$emit('click')
      }
      mobile.voiceInput((resultText) => {
        if (resultText) {
          // 判断识别结果是否以中文符号结尾
          if (this.ptt) {
            const reg = /(.+(?=[|\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]$))/
            if (reg.test(resultText)) {
              resultText = resultText.substring(0, resultText.length - 1)
            }
          }
          console.log('resultText: ', resultText)
          this.$emit('speakRes', resultText)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.round {
  @apply w-160px h-160px bg-opacity-20 bg-[#118EE9] m-auto rounded-full;
  .round1 {
    @apply w-140px h-140px bg-opacity-40 relative top-10px bg-[#118EE9] m-auto rounded-full;
    .round2 {
      @apply w-120px h-120px bg-opacity-60 relative top-10px bg-[#118EE9] m-auto rounded-full;
      .round3 {
        @apply w-100px h-100px relative top-10px bg-[#118EE9] m-auto rounded-full;
      }
    }
  }
}
.microIcon {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url("~@/asset/micro.svg") no-repeat;
  background-size: 100% 100%;
  &.black{
    background: url("~@/asset/icon_voice_black.svg") no-repeat;
  }
}
.micro_text{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0px 24px;
  .confirm{
    width: 100%;
    padding-right: 24px;
    color: #118EE9;
  }
  .resultText{
    background-color: #118EE9;
    padding: 0px 14px;
    min-width: 100px;
    min-height: 24px;
    line-height: 24px;
    border-radius: 10px;
    position: relative;
    color: #FFFFFF;
    display: inline-block;
    margin-bottom: 24px;
    &::after{
      content: '';
      width: 1px;
      height: 1px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -20px;
      border: 12px solid transparent;
      border-top-color: #118EE9;
      display: inline-block;
    }
  }
}
</style>
