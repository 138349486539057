import mobile from '@/utils/mobile'
import { cloneDeep } from 'lodash'
import store from '@/store'

const getPointListSaveKey = () => {
  return 'pointList-' + store.getters.getPageType + '-' + store.getters.getUserInfo.username
}
const getPointListOfflineApiHandler = async (params) => {
  return new Promise(async resolve => {
    try {
      const saveDataKey = getPointListSaveKey()
      const result = await mobile.getSaveData(saveDataKey)
      if (result && result.value) {
        resolve(cloneDeep(result.value))
      } else {
        resolve()
      }
    } catch (error) {
      console.error('getPointListOfflineApiHandler', error)
    }
  })
}

const savePointListOfflineApiHandler = async (pointOfflineList) => {
  console.log('pointOfflineList: ', pointOfflineList)
  await mobile.saveData({
    key: getPointListSaveKey(),
    value: pointOfflineList.pointList
  })
}

export {
  getPointListOfflineApiHandler,
  savePointListOfflineApiHandler
}
